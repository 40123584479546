import React from 'react';
import { Row, Col } from 'antd';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import img_almex from '../../assets/images/premio_2024/empresas/almex.png';
import img_arcacontinnetal from '../../assets/images/premio_2024/empresas/arcacontinnetal.png';
import img_barcel from '../../assets/images/premio_2024/empresas/barcel.png';
import img_bat from '../../assets/images/premio_2024/empresas/bat.png';
import img_bepensa from '../../assets/images/premio_2024/empresas/bepensa.png';
import img_bimbo from '../../assets/images/premio_2024/empresas/bimbo.png';
import img_cemexconcretos from '../../assets/images/premio_2024/empresas/cemexconcretos.png';
import img_cocacolafemsa from '../../assets/images/premio_2024/empresas/cocacolafemsa.png';
import img_estafeta from '../../assets/images/premio_2024/empresas/estafeta.png';
import img_fabricalacorona from '../../assets/images/premio_2024/empresas/fabricalacorona.png';
import img_frioexpress from '../../assets/images/premio_2024/empresas/frioexpress.png';
import img_gruporica from '../../assets/images/premio_2024/empresas/gruporica.png';
import img_hesa from '../../assets/images/premio_2024/empresas/hesa.png';
import img_ilhuilcamina from '../../assets/images/premio_2024/empresas/ilhuilcamina.png';
import img_jumex from '../../assets/images/premio_2024/empresas/jumex.png';
import img_lala from '../../assets/images/premio_2024/empresas/lala.png';
import img_linde from '../../assets/images/premio_2024/empresas/linde.png';
import img_oxxo from '../../assets/images/premio_2024/empresas/oxxo.png';
import img_palaciohierro from '../../assets/images/premio_2024/empresas/palaciohierro.png';
import img_paqueteexpress from '../../assets/images/premio_2024/empresas/paqueteexpress.png';
import img_pilot from '../../assets/images/premio_2024/empresas/pilot.png';
import img_sigma from '../../assets/images/premio_2024/empresas/sigma.png';
import img_solistica from '../../assets/images/premio_2024/empresas/solistica.png';
import img_techinnovation from '../../assets/images/premio_2024/empresas/techinnovation.png';
import img_tm from '../../assets/images/premio_2024/empresas/tm.png';
import img_tmsmonroy from '../../assets/images/premio_2024/empresas/tms monroy.png';
import img_torres from '../../assets/images/premio_2024/empresas/torres.png';
import img_transcooler from '../../assets/images/premio_2024/empresas/transcooler.png';
import img_trate from '../../assets/images/premio_2024/empresas/trate.png';
import img_zinergia from '../../assets/images/premio_2024/empresas/zinergia.png';
import img_zonte from '../../assets/images/premio_2024/empresas/zonte.png';


let logos = [
  { img_src: img_almex, nombre: 'almex' },
  { img_src: img_arcacontinnetal, nombre: 'arcacontinnetal' },
  { img_src: img_barcel, nombre: 'barcel' },
  { img_src: img_bat, nombre: 'bat' },
  { img_src: img_bepensa, nombre: 'bepensa' },
  { img_src: img_bimbo, nombre: 'bimbo' },
  { img_src: img_cemexconcretos, nombre: 'cemexconcretos' },
  { img_src: img_cocacolafemsa, nombre: 'cocacolafemsa' },
  { img_src: img_estafeta, nombre: 'estafeta' },
  { img_src: img_fabricalacorona, nombre: 'fabricalacorona' },
  { img_src: img_frioexpress, nombre: 'frioexpress' },
  { img_src: img_gruporica, nombre: 'gruporica' },
  { img_src: img_hesa, nombre: 'hesa' },
  { img_src: img_ilhuilcamina, nombre: 'ilhuilcamina' },
  { img_src: img_jumex, nombre: 'jumex' },
  { img_src: img_lala, nombre: 'lala' },
  { img_src: img_linde, nombre: 'linde' },
  { img_src: img_oxxo, nombre: 'oxxo' },
  { img_src: img_palaciohierro, nombre: 'palaciohierro' },
  { img_src: img_paqueteexpress, nombre: 'paqueteexpress' },
  { img_src: img_pilot, nombre: 'pilot' },
  { img_src: img_sigma, nombre: 'sigma' },
  { img_src: img_solistica, nombre: 'solistica' },
  { img_src: img_techinnovation, nombre: 'techinnovation' },
  { img_src: img_tm, nombre: 'tm' },
  { img_src: img_tmsmonroy, nombre: 'tmsmonroy' },
  { img_src: img_torres, nombre: 'torres' },
  { img_src: img_transcooler, nombre: 'transcooler' },
  { img_src: img_trate, nombre: 'trate' },
  { img_src: img_zinergia, nombre: 'zinergia' },
  { img_src: img_zonte, nombre: 'zonte' },
]

const CarouselEmpresas = (params) => {

    const ver_ponentes = logos.map(logo => {
        return(
          <div>
            <img src={logo.img_src} alt={logo.nombre} className="img_logo" style={{ width:'100%'}}  />
          </div>
        )
    });
    var settings = {
        dots: true,
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      cssEase: "linear",
      arrow : true
    };

    return (
      <Row>
        <Col span={24} offset={0}><br />
          <p style={{color:'#097ff6', fontSize:'28px'}}>
            <strong>Empresas ganadoras</strong>
          </p>

          <Slider {...settings}>
          {ver_ponentes}
        </Slider>
        </Col>
      </Row>
    );

};

export default CarouselEmpresas;