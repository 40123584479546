import React, { useEffect, useContext, useState } from "react";
import { Row, Col, Modal, Spin, message, Button, notification } from "antd";
import { Auth } from "../../utils/AuthContext";
import { Redirect, withRouter, useLocation } from "react-router";
import { Link } from "react-router-dom";
import Firebase from "../../utils/firebaseConfig";
import Live from "../conferencias/Live";
import Menu from "../master/Menu";

import LiveSinComentarios from "../conferencias/LiveSinComentarios";
import LiveEmpresa from "../conferencias/LiveEmpresa";
//import Diplomas from "./Diplomas";

import img_primer_lugar from "../../assets/images/sitio/felicitaciones/pnsv_1erlugar.png";
import img_segundo_lugar from "../../assets/images/sitio/felicitaciones/pnsv_2dolugar.png";
import img_tercer_lugar from "../../assets/images/sitio/felicitaciones/pnsv_3erlugar.png";

import almex from '../../assets/images/premio_2023/empresa_diplomas/alemex.png';
import barcel from '../../assets/images/premio_2023/empresa_diplomas/barcel.png';
import bepensa from '../../assets/images/premio_2023/empresa_diplomas/bepensa.png';
import bimbo from '../../assets/images/premio_2023/empresa_diplomas/bimbo.png';
import cemex from '../../assets/images/premio_2023/empresa_diplomas/cemex.png';
import concretos_cemex from '../../assets/images/premio_2023/empresa_diplomas/concretos_cemex.png';
import fabricadejaboneslacorona from '../../assets/images/premio_2023/empresa_diplomas/fabricadejaboneslacorona.png';
import hesa from '../../assets/images/premio_2023/empresa_diplomas/hesa.png';
import jumex from '../../assets/images/premio_2023/empresa_diplomas/jumex.png';
import lala_1 from '../../assets/images/premio_2023/empresa_diplomas/lala_d_primaria.png';
import lala_2 from '../../assets/images/premio_2023/empresa_diplomas/lala_d_secundaria.png';
import linde from '../../assets/images/premio_2023/empresa_diplomas/linde.png';
import marva from '../../assets/images/premio_2023/empresa_diplomas/marva.png';
import palaciodehierro from '../../assets/images/premio_2023/empresa_diplomas/palaciodehierro.png';
import paquetexpress from '../../assets/images/premio_2023/empresa_diplomas/paquetexpress.png';
import pilot from '../../assets/images/premio_2023/empresa_diplomas/pilot.png';
import sigma from '../../assets/images/premio_2023/empresa_diplomas/sigma.png';
import solistica from '../../assets/images/premio_2023/empresa_diplomas/solistica.png';
import tdr from '../../assets/images/premio_2023/empresa_diplomas/tdr.png';
import ttt from '../../assets/images/premio_2023/empresa_diplomas/torres-transportes-terrestres.png';
import transcooler from '../../assets/images/premio_2023/empresa_diplomas/transcooler.png';
import trate from '../../assets/images/premio_2023/empresa_diplomas/trate.png';
import zinergia from '../../assets/images/premio_2023/empresa_diplomas/zinergia.png';
import zonte from '../../assets/images/premio_2023/empresa_diplomas/zonte.png';
import { LeftCircleOutlined } from "@ant-design/icons";
import Testimonios from "./Tetimonios";

const nombre_evento = "premio-2024";

const app = new Firebase();

let key = "";
const success = () => {
  mensaje("success", "Bienvenido", "");
};

const mensaje = (tipo, titulo, texto) => {
  notification[tipo]({
    message: titulo,
    description: texto,
  });
};

const VerVideos = ({
  history,
  empresa_elegida = undefined,
  onResponse,
  ocultarConin,
}) => {
  const location = useLocation();
  const { usuario, datos } = useContext(Auth);
  const [preguntas_ponente, setPreguntasPonente] = useState([]);
  const [empresa, setEmpresa] = useState("");
  const [activar_descarga, setActivarDescarga] = useState(true);


  const [ver_premiacion, setVerPremiacion] = useState("");
  const [ver_logo, setVerLogo] = useState("");


  useEffect(() => {
    console.log(usuario);

    //if (!usuario) {
      //mensaje('warning','Advertencia','Para entrar a esta sección necesitas haber iniciado sesión.');
      //setSinLogin(true);
      //window.location.href = "/registro";
    //} else {
      app.obtener_objetos(nombre_evento + "/activar_descarga", true, (data) => {
        setActivarDescarga(data[0].activar);
      });

      switch (
        empresa_elegida //datos.empresa
      ) {
        case '/testimonios/almex':
setVerLogo(almex);
setEmpresa("almex");
break;
case '/testimonios/barcel':
setVerLogo(barcel);
setEmpresa("barcel");
break;
case '/testimonios/bepensa':
setVerLogo(bepensa);
setEmpresa("bepensa");
break;
case '/testimonios/bimbo':
setVerLogo(bimbo);
setEmpresa("bimbo");
break;
case '/testimonios/cemex':
setVerLogo(cemex);
setEmpresa("cemex");
break;
case '/testimonios/cemex-concretos':
setVerLogo(concretos_cemex);
setEmpresa("cemex-concretos");
break;
case '/testimonios/fabrica-de-jabon':
setVerLogo(fabricadejaboneslacorona);
setEmpresa("fabrica-de-jabon");
break;
case '/testimonios/hesa':
setVerLogo(hesa);
setEmpresa("hesa");
break;
case '/testimonios/jumex':
setVerLogo(jumex);
setEmpresa("jumex");
break;
case '/testimonios/lala-distribuidora-primaria':
setVerLogo(lala_1);
setEmpresa("lala-distribuidora-primaria");
break;
case '/testimonios/lala-distribuidora-secundaria':
setVerLogo(lala_2);
setEmpresa("lala-distribuidora-secundaria");
break;
case '/testimonios/linde':
setVerLogo(linde);
setEmpresa("linde");
break;
case '/testimonios/marva':
setVerLogo(marva);
setEmpresa("marva");
break;
case '/testimonios/palacio-de-hierro':
  setEmpresa("palacio-de-hierro");
  setVerLogo(palaciodehierro);
break;
case '/testimonios/paquetexpress':
setVerLogo(paquetexpress);
setEmpresa("paquetexpress");
break;
case '/testimonios/pilot':
setVerLogo(pilot);
setEmpresa("pilot");
break;
case '/testimonios/sigma':
setVerLogo(sigma);
setEmpresa("sigma");
break;
case '/testimonios/solistica':
setVerLogo(solistica);
setEmpresa("solistica");
break;
case '/testimonios/tdr':
setVerLogo(tdr);
setEmpresa("tdr");
break;
case '/testimonios/ttt':
setVerLogo(ttt);
setEmpresa("ttt");
break;
case '/testimonios/transcooler':
setVerLogo(transcooler);
setEmpresa("transcooler");
break;
case '/testimonios/trate':
setVerLogo(trate);
setEmpresa("trate");
break;
case '/testimonios/cinergia':
setVerLogo(zinergia);
setEmpresa("zinergia");
break;
case '/testimonios/zonte':
setVerLogo(zonte);
setEmpresa("zonte");
break;

        default:
          if (empresa_elegida === undefined) {
            window.location.href = "/testimonios";
          } else {
            setEmpresa(empresa_elegida);
          }
          break;
      //}
    }

    switch (
      empresa_elegida //datos.empresa
    ) {
      case "almex":
setVerLogo(almex);
break;
case "barcel":
setVerLogo(barcel);
break;
case "bepensa":
setVerLogo(bepensa);
break;
case "bimbo":
setVerLogo(bimbo);
break;
case "cemex":
setVerLogo(cemex);
break;
case "cemex-concretos":
setVerLogo(concretos_cemex);
break;
case "fabrica-de-jabon":
setVerLogo(fabricadejaboneslacorona);
break;
case "hesa":
setVerLogo(hesa);
break;
case "jumex":
setVerLogo(jumex);
break;
case "lala-distribuidora-primaria":
setVerLogo(lala_1);
break;
case "lala-distribuidora-secundaria":
setVerLogo(lala_2);
break;
case "linde":
setVerLogo(linde);
break;
case "marva":
setVerLogo(marva);
break;
case "palacio-de-hierro":
setVerLogo(palaciodehierro);
break;
case "paquetexpress":
setVerLogo(paquetexpress);
break;
case "pilot":
setVerLogo(pilot);
break;
case "sigma":
setVerLogo(sigma);
break;
case "solistica":
setVerLogo(solistica);
break;
case "tdr":
setVerLogo(tdr);
break;
case "ttt":
setVerLogo(ttt);
break;
case "transcoolet":
setVerLogo(transcooler);
break;
case "trate":
setVerLogo(trate);
break;
case "zinergia":
setVerLogo(zinergia);
break;
case "zonte":
setVerLogo(zonte);
break;

      default:
        setVerLogo("");
        break;
    }
  }, [history, usuario]);

  useEffect(() => {});

  console.log("empresa", empresa);

  
  return (
    <div className="contenedor_back_diploma">
      <Row align="middle">
        
      <>
            <Col
              xs={{ span: 23, offset: 0 }}
              lg={{ span: 5, offset: 2 }}
              style={{ textAlign: "right", padding: "10px 30px 10px 0px" }}
              onClick={() => setVerPremiacion("")}
            >
              <span className="btn_regresar" onClick={onResponse}>
              <LeftCircleOutlined /> Regresar a empresas
              </span>
            </Col>
            <Col xs={{ offset: 2, span: 6 }}>
          <img src={ver_logo} className="img_100" />
        </Col>
            <Col span={22} offset={1}>
              <br />


              <Testimonios
                empresa={empresa}
                datos={datos}
              />
              <br />
            </Col>
          </>
      </Row>
    </div>
  );
};

export default VerVideos;
