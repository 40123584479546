import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Modal, notification, Table, Input, Button, Form } from 'antd';
import { Link } from 'react-router-dom';
import { Auth } from "../../utils/AuthContext";
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import Axios from 'axios';
import img_descargar from "../../assets/images/premio_2022/diplomas/pnsv_web_BotonDescargar.png"
import premios from './premios.json';
import '../../assets/css/diplomas.css';

function PadLeft(value, length) {
  return (value.toString().length < length) ? PadLeft("0" + value, length) : 
  value;
}

/*const mensaje = (tipo,titulo, texto) =>{
  notification[tipo]({
      message: titulo,
      description: texto,
  });

}*/

const Diplomas = (props) =>{
  const {empresa, setVerPremiacion, ver_premiacion, ver_segundo, ver_primero, ver_tercerp, activar_descarga, datos} = props;
  const [buscar, setBuscar] = useState('');
  const [verificar_rfc, setVerificarRfc] = useState(false);
  const [guardar_rfc, setGuardarRfc] = useState('')
  const [codigo_rfc,setCodigoRfc] = useState('');
  const [archivo_diploma, setArchivoDiploma] = useState('');

  const onFinish = async values  => {
    setCodigoRfc(values.rfc);
  };

  useEffect(() =>{
    
    if(codigo_rfc !== ''){
      if(guardar_rfc === codigo_rfc){

        /*Axios({
          url: ,
          method: 'GET',
          responseType: 'blob'
    })
          .then((response) => {
                const url = window.URL
                      .createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'deploma.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
          })*/
        window.open(archivo_diploma, 'Diploma', "top=100,left=500,width=600,height=600");
        setVerificarRfc(false);
      }else{
        alert('RFC incorrecto. Por privacidad de los operadores solo ellos estan autorizados para descargar su reconocimiento.');
      }
    }
  },[codigo_rfc])

let ver_empresa = premios.filter((item) =>
{
  if(item.empresaf === empresa){
    return true;
}else{
    return false;
}
})
  let listado = ver_empresa.filter((operador) => {
    if(operador.nombre.toLowerCase().includes(buscar.toLowerCase()) || operador.lugar.toLowerCase().includes(buscar.toLowerCase())){
      return true;
    }else{
      return false;
    }
  }).map((operador,index) => {
    return(
      <>
      <tr className="tr_select_operador buscador_operador">
        <td className="td_operador nombre_operador"><strong>{operador.nombre}</strong></td>
        <td className="td_operador datos_operador"><strong>{operador.lugar}</strong></td>
        <td className="td_operador ">
        {/*activar_descarga === 1 &&
          <>{operador.lugar === 'No acreditable' ||  ver_primero || ver_segundo || ver_tercerp ? 
          <span className="datos_operador pointer_ver" onClick={()=> setVerPremiacion(operador.lugar)}>VER FELICITACIÓN</span> 
          : 
          <></>}
        </>
    */}
          {datos.perfil === 'Operador' &&
          <>
          {activar_descarga === 1 &&
            <span className="datos_operador pointer_ver" onClick={()=> {setVerificarRfc(true);setGuardarRfc(operador.rfc); setArchivoDiploma(operador.diploma);}}><img src={img_descargar} width="180px" /></span>
          }
          </>}
        </td>
      </tr>
      </>
    );
  });


  return(
    <>
    <Row>
      <Col span={16} offset={4} style={{ overflowY: 'scroll'}}>
        
      <table style={{ width: '100%'}} className="">
      <thead>
        <tr>
          <td colSpan="4" >
            <input placeholder="Buscar por nombre" onChange={(valor) => {setBuscar(valor.currentTarget.value);}} className="input_buscar_premio" />
            <span className='search_icon'><SearchOutlined /></span>
            </td>
        </tr>
        <tr className="secciones_operados_azul">
        <td className="td_operador">Nombre del operador</td>
        <td className="td_operador">Lugar</td>
        
        <td className="td_operador">
        {datos.perfil === 'Operador' &&
        <>
        {activar_descarga === 1 && 'Diploma'}
        </>}
        </td>
        
      </tr>
      </thead>
      <tbody>
      
     {listado}
      </tbody>
    </table>
      </Col>
    </Row>

    <Modal
        title={false}
        visible={verificar_rfc}
        footer={null}
        onCancel={() => setVerificarRfc(false)}
      >
        <Row>
          <Col span={20}>
          <Form
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      <Row>
      <Col span={20}>
      <Form.Item
        label="RFC"
        name="rfc"
        rules={[{ required: true, message: 'Por favor ingrese su RFC' }]}
      >
        <Input />
      </Form.Item>
      </Col>
      <Col span={4}>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <button type="primary" htmlType="submit">
          Descargar
        </button>
      </Form.Item>
      </Col>
      </Row>
    </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
}
export default Diplomas;