import React, { useState, useContext, useEffect, useRef } from "react";
import Registrocompleto from "./RegistroCompleto";
import "../../assets/css/login.css";
import { Auth } from "../../utils/AuthContext";

import { grabar } from "../../utils/ConexionServidor";

import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  notification,
  Modal,
  Spin,
  InputNumber,
  Radio,
  Popover,
} from "antd";
import Firebase from "../../utils/firebaseConfig";
import {
  codigo_eistente,
  registro_confirmacion,
} from "../../utils/ConexionServidor";

import { QuestionCircleOutlined } from "@ant-design/icons";

import { withRouter } from "react-router";

import LoginExiste from "../registro/LoginExiste";

import logo_premio from "../../assets/images/premio_2023/logo_pnsv_horizontal_color.png";
import logo_bienvenidos from "../../assets/images/registro/bienvenidos_registro.png";
import img_siguiente from "../../assets/images/premio_2021/BTN siguiente.png";
import img_siguiente_hover from "../../assets/images/premio_2021/BTN siguiente_OV.png";
import img_entrar from "../../assets/images/premio_2021/BTN entrar.png";
import img_entrar_hover from "../../assets/images/premio_2021/BTN entrar_OV.png";

const openNotificationWithIcon = (type, titulo, texto) => {
  notification[type]({
    message: titulo,
    description: texto,
    duration: 10,
  });
};

const nom_evento = "premio-2024";

const { Option } = Select;

let correo_registrado = "",
  password_registrado = "",
  perfil_registrado = "";

const app = new Firebase();

const success = () => {
  openNotificationWithIcon("success", "Bienvenido", "");
};

const Registro = ({ setsignup, history }) => {
  const [otro_medio, setOtroMedio] = useState(false);
  const [confirmacion, setConfirmacion] = useState(false);
  const [cargando_regsitro, setCargandoRegistro] = useState(false);
  const { usuario } = useContext(Auth);
  const [mostrar_login, setMostrarLogin] = useState(false);
  const [reutilizar_datos, setReutilizarDatos] = useState(false);
  const [etapa, setEtapa] = useState(-1);
  const [showEmpresa, setShowEmpresa] = useState(false);
  const [datos_registro, setDatosRegistro] = useState({
    nombre: null,
    tipo: null,
    empresa: null,
  });
  const [mouse_enter, setMouseEnter] = useState(false);

  const [form] = Form.useForm();

  const ref_form = React.createRef();

  useEffect(() => {
    console.log(usuario);
    if (usuario) {
      window.location.href = "/#inicio";
    }
  }, [usuario]);

  const registrarLoginExiste = (medio) => {
    setReutilizarDatos(true);
  };

  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const onChangeTipo = (event) => {
    console.log(event.target.value);
    datos_registro["tipo_cuenta"] = event.target.value;
    setDatosRegistro(datos_registro);
    if (event.target.value == "persona") {
      setEtapa(0);
    } else {
      setEtapa(3);
    }
    setCargandoRegistro(false);
  };

  const onChangePerfil = (event) => {
    console.log("Change Perfil...");
    console.log(event.target.value);
    if (
      event.target.value === "Operador" ||
      event.target.value === "Invitado"
    ) {
      setShowEmpresa(true);
    } else {
      setShowEmpresa(false);
    }
    setCargandoRegistro(false);
  };

  let medio_enterado = '';


  console.log('el medio: ', medio_enterado);
  const onFinish = async (values) => {

    if(values.empresa === 'Otro'){
      medio_enterado = values.otro_medio;
    }else{
      medio_enterado = values.empresa;
    }

    setCargandoRegistro(true);
    values.empresa = medio_enterado;
    switch (etapa) {
      case -1:
        datos_registro["tipo_cuenta"] = values.tipo_cuenta;
        setDatosRegistro(datos_registro);
        setEtapa(0);
        setCargandoRegistro(false);
        break;
      case 0:
        datos_registro["nombre_normal"] = values.nombre;
        datos_registro["nombre"] = values.nombre + " " + values.apellidos;
        datos_registro["audio"] = removeAccents(values.nombre)
          .replace(/ /g, "")
          .replace(/[^a-zA-Z0-9]/g, "")
          .toLowerCase();
        setDatosRegistro(datos_registro);

        datos_registro["perfil"] = values.perfil;
        datos_registro["empresa_ir"] = false;
        datos_registro["empresa_url"] = "";

        if (values.perfil == "Operador" || values.perfil == "Invitado") {
          datos_registro["empresa"] = values.empresa;
          switch (datos_registro["empresa"]) {
            case "ALMEX":
              datos_registro["empresa_url"] = "almex";
              break;
            case "AUTO TRANSPORTES ILHUILCAMINA, S.A. DE C.V.":
              datos_registro["empresa_url"] = "ilhuilcamina";
              break;
            case "AUTOTRANSPORTES PILOT, S.A. DE C.V.":
              datos_registro["empresa_url"] = "pilot";
              break;
            case "AUTOTRANSPORTES ZONTE, S.A. DE C.V.":
              datos_registro["empresa_url"] = "zonte";
              break;
            case "BARCEL, S.A. DE C.V.":
              datos_registro["empresa_url"] = "barcel";
              break;
            case "BIMBO, S.A. DE C.V.":
              datos_registro["empresa_url"] = "bimbo";
              break;
            case "CEMEX CONCRETOS, S.A. DE C.V.":
              datos_registro["empresa_url"] = "cemex-concretos";
              break;
            case "CEMEX, S.A.B. DE C.V.":
              datos_registro["empresa_url"] = "cemex";
              break;
            case "COCA COLA FEMSA":
              datos_registro["empresa_url"] = "femsa";
              break;
            case "COMERCIALIZADORA DE LÁCTEOS Y DERIVADOS, S.A. DE C.V.":
              datos_registro["empresa_url"] = "lala";
              break;
            case "COMERCIALIZADORA DE LÁCTEOS Y DERIVADOS, S.A. DE C.V. (LALA TRANSPORTES)":
              datos_registro["empresa_url"] = "lala-transportes";
              break;
            case "COMERCIALIZADORA ELORO, S.A. (JUMEX)":
              datos_registro["empresa_url"] = "jumex";
              break;
            case "DUARTE MUNGUÍA TRANSPORTES Y SERVICIOS":
              datos_registro["empresa_url"] = "duarte";
              break;
            case "EL PALACIO DE HIERRO, S.A. DE C.V.":
              datos_registro["empresa_url"] = "palacio-de-hierro";
              break;
            case "ESGARI HOLDING":
              datos_registro["empresa_url"] = "esgari";
              break;
            case "ESTAFETA MEXICANA, S.A. DE C.V.":
              datos_registro["empresa_url"] = "estafeta";
              break;
            case "EMBOTELLADORA BEPENSA S.A. DE C.V.":
              datos_registro["empresa_url"] = "embotelladora-bepensa";
              break;
            case "PUNTO A PUNTO T2":
              datos_registro["empresa_url"] = "punto-a-punto";
              break;
            case "TRANSPORTES INDUSTRIALES UNIDOS, S.A. DE C.V.":
              datos_registro["empresa_url"] = "transportes-industriales";
              break;
            case "TRANSPORTADORA ZEMOG S. DE R.L. DE C.V.":
              datos_registro["empresa_url"] = "transportadora-zemog";
              break;
            case "FÁBRICA DE JABÓN LA CORONA, S.A. DE C.V.":
              datos_registro["empresa_url"] = "fabrica-de-jabon";
              break;
            case "FLETES HESA, S.A. DE C.V.":
              datos_registro["empresa_url"] = "hesa";
              break;
            case "GRUPO NOR Y CARIBE":
              datos_registro["empresa_url"] = "nor-caribe";
              break;
            case "LAS CERVEZAS MODELO EN SONORA":
              datos_registro["empresa_url"] = "grupo-modelo";
              break;
            case "PAQUETEXPRESS":
              datos_registro["empresa_url"] = "paquetexpress";
              break;
            case "PRODUCTOS RICOLINO, S.A.P.I. DE C.V.":
              datos_registro["empresa_url"] = "ricolino";
              break;
            case "SIGMA ALIMENTOS":
              datos_registro["empresa_url"] = "sigma";
              break;
            case "SOLISTICA":
              datos_registro["empresa_url"] = "solistica";
              break;
            case "TORRES TRANSPORTES TERRESTRES, S.A. DE C.V.":
              datos_registro["empresa_url"] = "torres";
              break;
            case "TRANSPORTADORA ALPURA":
              datos_registro["empresa_url"] = "alpura";
              break;
            case "TRANSPORTES MARVA, S.A. DE C.V.":
              datos_registro["empresa_url"] = "marca";
              break;
            case "TRANSPORTES MONROY Y SHIAVON":
              datos_registro["empresa_url"] = "transportes-monrroy-y-shiavon";
              break;
            case "TRATE DE OCCIDENTE, S.A. DE C.V.":
              datos_registro["empresa_url"] = "trate";
              break;
            default:
              datos_registro["empresa_url"] = "";
              break;
          }
          datos_registro["empresa_ir"] = true;

          localStorage.setItem("datos", JSON.stringify(datos_registro));

          app.escribir(
            nom_evento + "/usuarios",
            undefined,
            datos_registro,
            () => {
              setCargandoRegistro(false);
              app.app
                .auth()
                .signInAnonymously()
                .catch(function (error) {
                  // Handle Errors here.
                  var errorCode = error.code;
                  var errorMessage = error.message;
                  // ...
                });
            }
          );
        }

        switch (values.perfil) {
          case "Operador":
            //setEtapa(2);
            setDatosRegistro(datos_registro);
            break;
          case "Invitado":
            //setEtapa(2);
            setDatosRegistro(datos_registro);
            break;
          case "Prensa":
            localStorage.setItem("datos", JSON.stringify(datos_registro));

            app.escribir(
              nom_evento + "/usuarios",
              undefined,
              datos_registro,
              () => {
                setCargandoRegistro(false);
                app.app
                  .auth()
                  .signInAnonymously()
                  .catch(function (error) {
                    // Handle Errors here.
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    // ...
                  });
              }
            );

            break;
          default:
            break;
        }
        //setEtapa(1);
        setCargandoRegistro(false);
        break;
      case 3:
        datos_registro["nombre_normal"] = values.nombre;
        datos_registro["nombre"] = values.nombre;
        datos_registro["perfil"] = "Invitado";
        datos_registro["empresa_ir"] = false;
        datos_registro["empresa_url"] = "";

        datos_registro["empresa"] = values.empresa;
        switch (datos_registro["empresa"]) {
          case "ALMEX":
            datos_registro["empresa_url"] = "almex";
            break;
          case "AUTO TRANSPORTES ILHUILCAMINA, S.A. DE C.V.":
            datos_registro["empresa_url"] = "ilhuilcamina";
            break;
          case "AUTOTRANSPORTES PILOT, S.A. DE C.V.":
            datos_registro["empresa_url"] = "pilot";
            break;
          case "AUTOTRANSPORTES ZONTE, S.A. DE C.V.":
            datos_registro["empresa_url"] = "zonte";
            break;
          case "BARCEL, S.A. DE C.V.":
            datos_registro["empresa_url"] = "barcel";
            break;
          case "BIMBO, S.A. DE C.V.":
            datos_registro["empresa_url"] = "bimbo";
            break;
          case "CEMEX CONCRETOS, S.A. DE C.V.":
            datos_registro["empresa_url"] = "cemex-concretos";
            break;
          case "CEMEX, S.A.B. DE C.V.":
            datos_registro["empresa_url"] = "cemex";
            break;
          case "COCA COLA FEMSA":
            datos_registro["empresa_url"] = "femsa";
            break;
          case "COMERCIALIZADORA DE LÁCTEOS Y DERIVADOS, S.A. DE C.V.":
            datos_registro["empresa_url"] = "lala";
            break;
          case "COMERCIALIZADORA DE LÁCTEOS Y DERIVADOS, S.A. DE C.V. (LALA TRANSPORTES)":
            datos_registro["empresa_url"] = "lala-transportes";
            break;
          case "COMERCIALIZADORA ELORO, S.A. (JUMEX)":
            datos_registro["empresa_url"] = "jumex";
            break;
          case "DUARTE MUNGUÍA TRANSPORTES Y SERVICIOS":
            datos_registro["empresa_url"] = "duarte";
            break;
          case "EL PALACIO DE HIERRO, S.A. DE C.V.":
            datos_registro["empresa_url"] = "palacio-de-hierro";
            break;
          case "ESGARI HOLDING":
            datos_registro["empresa_url"] = "esgari";
            break;
          case "ESTAFETA MEXICANA, S.A. DE C.V.":
            datos_registro["empresa_url"] = "estafeta";
            break;
          case "FÁBRICA DE JABÓN LA CORONA, S.A. DE C.V.":
            datos_registro["empresa_url"] = "fabrica-de-jabon";
            break;
          case "FLETES HESA, S.A. DE C.V.":
            datos_registro["empresa_url"] = "hesa";
            break;
          case "GRUPO NOR Y CARIBE":
            datos_registro["empresa_url"] = "nor-caribe";
            break;
          case "LAS CERVEZAS MODELO EN SONORA":
            datos_registro["empresa_url"] = "grupo-modelo";
            break;
          case "PAQUETEXPRESS":
            datos_registro["empresa_url"] = "paquetexpress";
            break;
          case "PRODUCTOS RICOLINO, S.A.P.I. DE C.V.":
            datos_registro["empresa_url"] = "ricolino";
            break;
          case "SIGMA ALIMENTOS":
            datos_registro["empresa_url"] = "sigma";
            break;
          case "SOLISTICA":
            datos_registro["empresa_url"] = "solistica";
            break;
          case "TORRES TRANSPORTES TERRESTRES, S.A. DE C.V.":
            datos_registro["empresa_url"] = "torres";
            break;
          case "TRANSPORTADORA ALPURA":
            datos_registro["empresa_url"] = "alpura";
            break;
          case "TRANSPORTES MARVA, S.A. DE C.V.":
            datos_registro["empresa_url"] = "marca";
            break;
          case "TRATE DE OCCIDENTE, S.A. DE C.V.":
            datos_registro["empresa_url"] = "trate";
            break;
          default:
            datos_registro["empresa_url"] = "";
            break;
        }
        datos_registro["empresa_ir"] = true;

        localStorage.setItem("datos", JSON.stringify(datos_registro));

        app.escribir(
          nom_evento + "/usuarios",
          undefined,
          datos_registro,
          () => {
            setCargandoRegistro(false);
            app.app
              .auth()
              .signInAnonymously()
              .catch(function (error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // ...
              });
          }
        );
        break;
      case 1:
        // datos_registro["perfil"] = values.perfil;
        // datos_registro["empresa_ir"] = false;
        // datos_registro["empresa_url"] = "";

        /*grabar({
          nombre: datos_registro["audio"],
          texto:
            values.perfil === "Operador"
              ? `Hola ${datos_registro["nombre_normal"]}, soy Conín y me da gusto saludarte. Hoy estaré contigo para darte algunas indicaciones. Antes de iniciar, te cuento que el Premio Nacional de Seguridad Vial es un evento que durante 22 años ha permitido reconocer la labor tan importante que desempeñas todos los días. Por ello, vamos a iniciar con este reconocimiento que es para ti. A continuación Alex Taizen, presidente de la ANTP, dará un mensaje importante para ti.`
              : `Hola ${datos_registro["nombre_normal"]}, soy Conín y me da gusto saludarte. Antes de iniciar, te cuento que el Premio Nacional de Seguridad Vial es un evento que durante 22 años ha permitido reconocer la labor tan importante que desempeñan los operadores y las empresas comprometidas con la seguridad vial. Por ello, vamos a iniciar con este reconocimiento para ellos. A continuación Alex Taizen, presidente de la ANTP, dará un mensaje importante para ti.`,
          tipo: "bienvenida",
          perfil: `${values.perfil === "Operador" ? "operador" : "invitado"}`,
        })
          .then((data) => {})
          .catch((err) => {});*/

        /*switch (values.perfil) {
          case "Operador":
            setEtapa(2);
            setDatosRegistro(datos_registro);
            break;
          case "Invitado":
            setEtapa(2);
            setDatosRegistro(datos_registro);
            break;
          case "Prensa":
            localStorage.setItem("datos", JSON.stringify(datos_registro));

            app.escribir(
              nom_evento + "/usuarios",
              undefined,
              datos_registro,
              () => {
                setCargandoRegistro(false);
                app.app
                  .auth()
                  .signInAnonymously()
                  .catch(function (error) {
                    // Handle Errors here.
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    // ...
                  });
              }
            );

            break;
          default:
            break;
        }
        setCargandoRegistro(false);*/
        break;
      case 2:
        // datos_registro["empresa"] = values.empresa;
        // switch (datos_registro["empresa"]) {
        //   case "ALMEX":
        //     datos_registro["empresa_url"] = "almex";
        //     break;
        //   case "AUTO TRANSPORTES ILHUILCAMINA, S.A. DE C.V.":
        //     datos_registro["empresa_url"] = "ilhuilcamina";
        //     break;
        //   case "AUTOTRANSPORTES PILOT, S.A. DE C.V.":
        //     datos_registro["empresa_url"] = "pilot";
        //     break;
        //   case "AUTOTRANSPORTES ZONTE, S.A. DE C.V.":
        //     datos_registro["empresa_url"] = "zonte";
        //     break;
        //   case "BARCEL, S.A. DE C.V.":
        //     datos_registro["empresa_url"] = "barcel";
        //     break;
        //   case "BIMBO, S.A. DE C.V.":
        //     datos_registro["empresa_url"] = "bimbo";
        //     break;
        //   case "CEMEX CONCRETOS, S.A. DE C.V.":
        //     datos_registro["empresa_url"] = "cemex-concretos";
        //     break;
        //   case "CEMEX, S.A.B. DE C.V.":
        //     datos_registro["empresa_url"] = "cemex";
        //     break;
        //   case "COCA COLA FEMSA":
        //     datos_registro["empresa_url"] = "femsa";
        //     break;
        //   case "COMERCIALIZADORA DE LÁCTEOS Y DERIVADOS, S.A. DE C.V.":
        //     datos_registro["empresa_url"] = "lala";
        //     break;
        //   case "COMERCIALIZADORA DE LÁCTEOS Y DERIVADOS, S.A. DE C.V. (LALA TRANSPORTES)":
        //     datos_registro["empresa_url"] = "lala-transportes";
        //     break;
        //   case "COMERCIALIZADORA ELORO, S.A. (JUMEX)":
        //     datos_registro["empresa_url"] = "jumex";
        //     break;
        //   case "DUARTE MUNGUÍA TRANSPORTES Y SERVICIOS":
        //     datos_registro["empresa_url"] = "duarte";
        //     break;
        //   case "EL PALACIO DE HIERRO, S.A. DE C.V.":
        //     datos_registro["empresa_url"] = "palacio-de-hierro";
        //     break;
        //   case "ESGARI HOLDING":
        //     datos_registro["empresa_url"] = "esgari";
        //     break;
        //   case "ESTAFETA MEXICANA, S.A. DE C.V.":
        //     datos_registro["empresa_url"] = "estafeta";
        //     break;
        //   case "FÁBRICA DE JABÓN LA CORONA, S.A. DE C.V.":
        //     datos_registro["empresa_url"] = "fabrica-de-jabon";
        //     break;
        //   case "FLETES HESA, S.A. DE C.V.":
        //     datos_registro["empresa_url"] = "hesa";
        //     break;
        //   case "GRUPO NOR Y CARIBE":
        //     datos_registro["empresa_url"] = "nor-caribe";
        //     break;
        //   case "LAS CERVEZAS MODELO EN SONORA":
        //     datos_registro["empresa_url"] = "grupo-modelo";
        //     break;
        //   case "PAQUETEXPRESS":
        //     datos_registro["empresa_url"] = "paquetexpress";
        //     break;
        //   case "PRODUCTOS RICOLINO, S.A.P.I. DE C.V.":
        //     datos_registro["empresa_url"] = "ricolino";
        //     break;
        //   case "SIGMA ALIMENTOS":
        //     datos_registro["empresa_url"] = "sigma";
        //     break;
        //   case "SOLISTICA":
        //     datos_registro["empresa_url"] = "solistica";
        //     break;
        //   case "TORRES TRANSPORTES TERRESTRES, S.A. DE C.V.":
        //     datos_registro["empresa_url"] = "torres";
        //     break;
        //   case "TRANSPORTADORA ALPURA":
        //     datos_registro["empresa_url"] = "alpura";
        //     break;
        //   case "TRANSPORTES MARVA, S.A. DE C.V.":
        //     datos_registro["empresa_url"] = "marca";
        //     break;
        //   case "TRATE DE OCCIDENTE, S.A. DE C.V.":
        //     datos_registro["empresa_url"] = "trate";
        //     break;
        //   default:
        //     datos_registro["empresa_url"] = "";
        //     break;
        // }
        // datos_registro["empresa_ir"] = true;

        // localStorage.setItem("datos", JSON.stringify(datos_registro));

        // app.escribir(
        //   nom_evento + "/usuarios",
        //   undefined,
        //   datos_registro,
        //   () => {
        //     setCargandoRegistro(false);
        //     app.app
        //       .auth()
        //       .signInAnonymously()
        //       .catch(function (error) {
        //         // Handle Errors here.
        //         var errorCode = error.code;
        //         var errorMessage = error.message;
        //         // ...
        //       });
        //   }
        // );

        setCargandoRegistro(false);
        break;
      default:
        setCargandoRegistro(false);
        break;
    }
  };
  const elegirMedioo = value => {
    console.log('medio',value);

    if(value === 'Otro'){
      setOtroMedio(true);
    }else{
      setOtroMedio(false);
    }

  }

  const elegirMedio = (event) => {
    console.log("Change Perfil...");
    console.log('evento medio',event.target.value);
    if(event.target.value === 'Otro'){
      setOtroMedio(true);
    }else{
      setOtroMedio(false);
    }
  };

  return (
    <>
      <Spin tip="Cargando..." spinning={cargando_regsitro}>
        <Row className="fondo_registro">
          <Col span={12}></Col>
          <Col span={12} className="fondo_form">
            <Col xs={{ span: 24 }}>
              <img src={logo_premio} className="img_premio" />
            </Col>
            <Form
              className="login-form"
              ref={ref_form}
              form={form}
              layout="vertical"
              name="basic"
              initialValues={{
                empresa: "",
              }}
              onFinish={onFinish}
            >
              <Row style={{ marginLeft: "20px", marginRight: "20px" }}>
                {etapa === -1 ? (
                  <>
                    <Col
                      xs={{ span: 24, offset: 0 }}
                      md={{ span: 12, offset: 6 }}
                      style={{ textAlign: "center" }}
                      className="subtitle"
                    >
                      Bienvenidos
                    </Col>

                    <Col
                      xs={{ span: 24, offset: 0 }}
                      md={{ span: 24, offset: 0 }}
                      style={{
                        paddingTop: "20px",
                        paddingBottom: "20px",
                        textAlign: "left",
                      }}
                    >
                      <Form.Item
                        name="tipo_cuenta"
                        label={
                          <span className="nom-label-input">
                            Selecciona como deseas entrar:
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message: (
                              <span style={{ color: "#e74c3c" }}>
                                Selecciona como deseas entrar
                              </span>
                            ),
                          },
                        ]}
                      >
                        <Radio.Group onChange={onChangeTipo}>
                          <Radio
                            className="texto_radios"
                            style={{ color: "#003367" }}
                            value="grupo"
                          >
                            Como Grupo
                          </Radio>
                          <br />
                          <Radio
                            className="texto_radios"
                            style={{ color: "#003367" }}
                            value="persona"
                          >
                            Como Persona
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </>
                ) : etapa === 0 ? (
                  <>
                    <Col
                      xs={{ span: 24, offset: 0 }}
                      md={{ span: 12, offset: 6 }}
                      style={{ textAlign: "center" }}
                      className="subtitle"
                    >
                      Bienvenidos
                    </Col>

                    <Col
                      xs={{ span: 12, offset: 6 }}
                      md={{ span: 12, offset: 6 }}
                      style={{ paddingTop: "20px", paddingBottom: "20px" }}
                    >
                      <Form.Item
                        name="nombre"
                        rules={[
                          {
                            required: true,
                            message: (
                              <span style={{ color: "#e74c3c" }}>
                                Ingresa tu nombre
                              </span>
                            ),
                          },
                        ]}
                        style={{
                          paddingTop: "10px",
                          paddingBottom: "5px",
                          textAlign: "left",
                        }}
                      >
                        <input className="entrada_input" placeholder="Nombre" />
                      </Form.Item>
                      <Form.Item
                        name="apellidos"
                        rules={[
                          {
                            required: true,
                            message: (
                              <span
                                style={{
                                  color: "#e74c3c",
                                }}
                              >
                                Ingresa sus apellidos
                              </span>
                            ),
                          },
                        ]}
                        style={{
                          paddingTop: "5px",
                          paddingBottom: "10px",
                          textAlign: "left",
                        }}
                      >
                        <input
                          className="entrada_input"
                          placeholder="Apellido(s)"
                        />
                      </Form.Item>

                      <Form.Item
                        name="perfil"
                        label={<span className="nom-label-input">Perfil:</span>}
                        style={{ textAlign: "left" }}
                        rules={[
                          {
                            required: true,
                            message: (
                              <span style={{ color: "#e74c3c" }}>
                                Selecciona un perfil
                              </span>
                            ),
                          },
                        ]}
                      >
                        <Radio.Group onChange={onChangePerfil}>
                          <Radio
                            className="texto_radios"
                            style={{ color: "#003367" }}
                            value="Operador"
                          >
                            Soy operador
                          </Radio>
                          <br />
                          <Radio
                            className="texto_radios"
                            style={{ color: "#003367" }}
                            value="Invitado"
                          >
                            Soy invitado{" "}
                            <Popover
                              content={
                                <div>
                                  <p>
                                    Se considera invitado a: familiares, amigos,
                                    directivos, autoridades, etc.
                                  </p>
                                </div>
                              }
                              title=""
                            >
                              {" "}
                              <QuestionCircleOutlined />{" "}
                            </Popover>{" "}
                          </Radio>
                          <br />
                          <Radio
                            className="texto_radios"
                            style={{ color: "#003367" }}
                            value="Prensa"
                          >
                            Soy prensa
                          </Radio>
                        </Radio.Group>
                      </Form.Item>

                      {showEmpresa ? (
                        <>
                        <Form.Item
                          name="empresa"
                          label={
                            <span className="nom-label-input">Empresa:</span>
                          }
                          style={{ marginTop: "10px", textAlign: "left" }}
                          rules={[
                            {
                              required: true,
                              message: (
                                <span style={{ color: "#e74c3c" }}>
                                  Selecciona una empresa
                                </span>
                              ),
                            },
                          ]}
                        >
                          <select className="select_entrada" onChange={elegirMedio}>
                            <option value="" selected>
                              Selecciona una empresa
                            </option>
                            <option value="ANTP">ANTP</option>
                            <option value="ALMEX">ALMEX</option>
<option value="AUTO TRANSPORTES ILHUILCAMINA S.A. DE C.V.">AUTO TRANSPORTES ILHUILCAMINA S.A. DE C.V.</option>
<option value="AUTOTRANSPORTES PILOT S.A. DE C.V.">AUTOTRANSPORTES PILOT S.A. DE C.V.</option>
<option value="AUTOTRANSPORTES ZONTE S.A. DE C.V.">AUTOTRANSPORTES ZONTE S.A. DE C.V.</option>
<option value="BARCEL, S.A. DE C.V. ">BARCEL, S.A. DE C.V. </option>
<option value="BIMBO, S.A. DE C.V. ">BIMBO, S.A. DE C.V. </option>
<option value="CEMEX S.A.B. DE C.V.">CEMEX S.A.B. DE C.V.</option>
<option value="COMERCIALIZADORA DE LÁCTEOS Y DERIVADOS, S.A. DE C.V.">COMERCIALIZADORA DE LÁCTEOS Y DERIVADOS, S.A. DE C.V.</option>
<option value="EL PALACIO DE HIERRO S.A. DE C.V.">EL PALACIO DE HIERRO S.A. DE C.V.</option>
<option value="EMBOTELLADORAS BEPENSA S.A. DE C.V.">EMBOTELLADORAS BEPENSA S.A. DE C.V.</option>
<option value="ESTAFETA MEXICANA S.A. DE C.V.">ESTAFETA MEXICANA S.A. DE C.V.</option>
<option value="FÁBRICA DE JABÓN LA CORONA S.A. DE C.V.">FÁBRICA DE JABÓN LA CORONA S.A. DE C.V.</option>
<option value="FÁBRICA DE JABÓN LA CORONA S.A. DE C.V.">FÁBRICA DE JABÓN LA CORONA S.A. DE C.V.</option>
<option value="FLETES HESA, S.A. DE C.V.">FLETES HESA, S.A. DE C.V.</option>
<option value="FRIO EXPESS, S.A. DE C.V.">FRIO EXPESS, S.A. DE C.V.</option>
<option value="LINDE">LINDE</option>
<option value="SIGMA ALIMENTOS">SIGMA ALIMENTOS</option>
<option value="SOLISTICA ">SOLISTICA </option>
<option value="TORRES TRANSPORTES TERRESTRES S.A. DE C.V.">TORRES TRANSPORTES TERRESTRES S.A. DE C.V.</option>
<option value="TRANSCOOLER S.A. DE C.V.">TRANSCOOLER S.A. DE C.V.</option>
<option value="TRANSPORTES MONROY SCHIAVON SA DE CV">TRANSPORTES MONROY SCHIAVON SA DE CV</option>
<option value="TRASPORTES MARVA S.A. DE C.V.">TRASPORTES MARVA S.A. DE C.V.</option>
<option value="ZINERGIA">ZINERGIA</option>
<option value="Otro">Otro</option>
                          </select>
                        </Form.Item>
                        {otro_medio && 
      (
        <Form.Item className="nom-label"
        name="otro_medio"
        label={<span style={{color:'#606060'}}>¿Cuál?</span>}
        rules={[
          
          {
            required: true,
            message: <span style={{color:'red'}}>Ingresa el medio por el que te enteraste</span>,
          },
        ]}
      >
        <Input />
      </Form.Item>
      )
      }
                        </>
                      ) : (
                        <></>
                      )}

                      <Form.Item
                        className="nom-label-input"
                        name="aceptar_terminos"
                        valuePropName="checked"
                        style={{ textAlign: "left" }}
                        rules={[
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject(
                                    <span
                                      style={{
                                        color: "#e74c3c",
                                        fontWeight: "normal",
                                      }}
                                    >
                                      Acepta el aviso de privacidad
                                    </span>
                                  ),
                          },
                        ]}
                      >
                        <Checkbox className="nom-label-input">
                          <span
                            className="nom-label-input"
                            style={{
                              fontSize: "12px",
                              fontWeight: "normal",
                            }}
                          >
                            He leido y acepto el{" "}
                            <span
                              onClick={() => {
                                window.open(
                                  "https://www.antp.org.mx/aviso-de-privacidad",
                                  "Aviso de privacidad",
                                  "top=100,left=500,width=600,height=600"
                                );
                              }}
                            >
                              Aviso de Privacidad
                            </span>
                          </span>
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </>
                ) : etapa === 1 ? (
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    md={{ span: 11, offset: 0 }}
                    style={{ textAlign: "left" }}
                  >
                    <Form.Item
                      name="perfil"
                      label={<span className="nom-label-input">Perfil:</span>}
                      rules={[
                        {
                          required: true,
                          message: (
                            <span style={{ color: "white" }}>
                              Selecciona un perfil
                            </span>
                          ),
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio
                          className="texto_radios"
                          style={{ color: "white" }}
                          value="Operador"
                        >
                          Soy operador premiado del Vigésimo Segundo Premio
                          Nacional de Segurdad Vial.
                        </Radio>
                        <br />
                        <Radio
                          className="texto_radios"
                          style={{ color: "white" }}
                          value="Invitado"
                        >
                          Soy invitado{" "}
                          <Popover
                            content={
                              <div>
                                <p>
                                  Se considera invitado a: familiares, amigos,
                                  directivos, autoridades, prensa, etc.
                                </p>
                              </div>
                            }
                            title=""
                          >
                            {" "}
                            <QuestionCircleOutlined />{" "}
                          </Popover>{" "}
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                ) : (
                  etapa === 3 && (
                    <Col
                      xs={{ span: 12, offset: 6 }}
                      md={{ span: 12, offset: 6 }}
                      style={{
                        textAlign: "left",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <Form.Item
                        name="nombre"
                        rules={[
                          {
                            required: true,
                            message: (
                              <span style={{ color: "#e74c3c" }}>
                                Ingresa el nombre del grupo
                              </span>
                            ),
                          },
                        ]}
                        style={{
                          paddingTop: "10px",
                          paddingBottom: "5px",
                          textAlign: "left",
                        }}
                      >
                        <input
                          className="entrada_input"
                          placeholder="Nombre del grupo"
                        />
                      </Form.Item>
                      <Form.Item
                        name="empresa"
                        label={
                          <span className="nom-label-input">Empresa:</span>
                        }
                        style={{ marginTop: "10px", textAlign: "left" }}
                        rules={[
                          {
                            required: true,
                            message: (
                              <span style={{ color: "#e74c3c" }}>
                                Selecciona una empresa
                              </span>
                            ),
                          },
                        ]}
                      >
                        <select className="select_entrada"  onChange={elegirMedio}>
                          <option value="" selected>
                            Selecciona una empresa
                          </option>
                          <option value="ANTP">ANTP</option>
                          <option value="ALMEX">ALMEX</option>
<option value="AUTO TRANSPORTES ILHUILCAMINA S.A. DE C.V.">AUTO TRANSPORTES ILHUILCAMINA S.A. DE C.V.</option>
<option value="AUTOTRANSPORTES PILOT S.A. DE C.V.">AUTOTRANSPORTES PILOT S.A. DE C.V.</option>
<option value="AUTOTRANSPORTES ZONTE S.A. DE C.V.">AUTOTRANSPORTES ZONTE S.A. DE C.V.</option>
<option value="BARCEL, S.A. DE C.V. ">BARCEL, S.A. DE C.V. </option>
<option value="BIMBO, S.A. DE C.V. ">BIMBO, S.A. DE C.V. </option>
<option value="CEMEX S.A.B. DE C.V.">CEMEX S.A.B. DE C.V.</option>
<option value="COMERCIALIZADORA DE LÁCTEOS Y DERIVADOS, S.A. DE C.V.">COMERCIALIZADORA DE LÁCTEOS Y DERIVADOS, S.A. DE C.V.</option>
<option value="EL PALACIO DE HIERRO S.A. DE C.V.">EL PALACIO DE HIERRO S.A. DE C.V.</option>
<option value="EMBOTELLADORAS BEPENSA S.A. DE C.V.">EMBOTELLADORAS BEPENSA S.A. DE C.V.</option>
<option value="ESTAFETA MEXICANA S.A. DE C.V.">ESTAFETA MEXICANA S.A. DE C.V.</option>
<option value="FÁBRICA DE JABÓN LA CORONA S.A. DE C.V.">FÁBRICA DE JABÓN LA CORONA S.A. DE C.V.</option>
<option value="FÁBRICA DE JABÓN LA CORONA S.A. DE C.V.">FÁBRICA DE JABÓN LA CORONA S.A. DE C.V.</option>
<option value="FLETES HESA, S.A. DE C.V.">FLETES HESA, S.A. DE C.V.</option>
<option value="FRIO EXPESS, S.A. DE C.V.">FRIO EXPESS, S.A. DE C.V.</option>
<option value="LINDE">LINDE</option>
<option value="SIGMA ALIMENTOS">SIGMA ALIMENTOS</option>
<option value="SOLISTICA ">SOLISTICA </option>
<option value="TORRES TRANSPORTES TERRESTRES S.A. DE C.V.">TORRES TRANSPORTES TERRESTRES S.A. DE C.V.</option>
<option value="TRANSCOOLER S.A. DE C.V.">TRANSCOOLER S.A. DE C.V.</option>
<option value="TRANSPORTES MONROY SCHIAVON SA DE CV">TRANSPORTES MONROY SCHIAVON SA DE CV</option>
<option value="TRASPORTES MARVA S.A. DE C.V.">TRASPORTES MARVA S.A. DE C.V.</option>
<option value="ZINERGIA">ZINERGIA</option>
<option value="Otro">Otro</option>
                        </select>
                      </Form.Item>
                      {otro_medio && 
      (
        <Form.Item className="nom-label"
        name="otro_medio"
        label={<span style={{color:'#606060'}}>¿Cuál?</span>}
        rules={[
          
          {
            required: true,
            message: <span style={{color:'red'}}>Ingresa el medio por el que te enteraste</span>,
          },
        ]}
      >
        <Input />
      </Form.Item>
      )
      }
                    </Col>
                  )
                )}
                <Col span={24} style={{ textAlign: "center" }}>
                  <Form.Item>
                    {/* <img
                      style={{
                        width: "200px",
                        padding: "0px",
                        cursor: "pointer",
                      }}
                      src={mouse_enter ? img_siguiente_hover : img_siguiente}
                      alt="Entrar"
                      onClick={() => {
                        ref_form.current.submit();
                      }}
                      onMouseEnter={() => setMouseEnter(true)}
                      onMouseLeave={() => setMouseEnter(false)}
                    /> */}
                    <button
                      className="button-login"
                      onClick={() => {
                        ref_form.current.submit();
                      }}
                      disabled={etapa >= 0 ? false : true}
                    >
                      Siguiente
                    </button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>

        <Row>
          {confirmacion ? (
            <Col span={24}>
              <Registrocompleto
                correo={correo_registrado}
                password={password_registrado}
              />
            </Col>
          ) : (
            <></>
          )}
        </Row>
      </Spin>

      <Modal
        title="INICIA SESIÓN"
        visible={mostrar_login}
        onOk={() => {
          setMostrarLogin(false);
        }}
        onCancel={() => {
          setMostrarLogin(false);
        }}
        footer={null}
      >
        <LoginExiste
          onResponse={() => {
            registrarLoginExiste();
          }}
          onClick={success}
        />
      </Modal>
    </>
  );
};

export default withRouter(Registro);
