import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router";
import { Row, Col } from "antd";
import Firebase from "../../utils/firebaseConfig";
import { Auth } from "../../utils/AuthContext";

import Scania from "./paginas/Scania";

import stand01 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Kenworth.png";
import stand02 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Freightliner.png";
import stand03 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Navistar.png";
import stand04 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Isuzu.png";
import stand05 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Hino.png";
import stand06 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_CircuitoExterior.png";

import stand07 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Almex.png";
import stand08 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Alpura.png";
import stand09 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Bepensa.png";
import stand10 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Bimbo.png";
import stand11 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Cemex.png";
import stand12 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_CocaCola.png";
import stand13 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Estafeta.png";
import stand14 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_GrupoModelo.png";
import stand15 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Hesa.png";

import stand16 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Holcim.png";
import stand17 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Jumex.png";
import stand18 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_LaCorona.png";
import stand19 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Lala.png";
import stand20 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Marva.png";
import stand21 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_NorCaribe.png";
import stand22 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_PalacioDeHierro.png";
import stand23 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_PaqueteExpress.png";
import stand24 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_pilot.png";

import stand25 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Sigma.png";
import stand26 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Solistica.png";
import stand27 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Tem.png";
import stand28 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Tiusa.png";
import stand29 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Tms.png";
import stand30 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Transcooler.png";
import stand31 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Trate.png";
import stand32 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Zemog.png";
import stand33 from "../../assets/images/premio_2022/expo/stands/pnsv_stand_Zonte.png";

const app = new Firebase();
const nombre_evento = "premio-2024";

const Stand = (props) => {
  const nombre = props.match.params.nombre;
  const url = decodeURIComponent(props.match.params.url);
  const imageUrl = decodeURIComponent(props.match.params.image);

  const standsArray = {
    stand01: {
      image: stand01,
      url: "https://www.kenworth.com.mx/",
    },
    stand02: {
      image: stand02,
      url: "https://www.freightliner.com.mx/",
    },
    stand03: {
      image: stand03,
      url: "https://mexico.internationaltrucks.com/",
    },
    stand04: {
      image: stand04,
      url: "https://www.isuzumex.com.mx/",
    },
    stand05: {
      image: stand05,
      url: "https://hino.com.mx/",
    },
    stand06: {
      image: stand06,
      url: "https://www.circuitoexterior.mx/",
    },
    stand07: {
      image: stand07,
      url: "https://almex.com.mx/",
    },
    stand08: {
      image: stand08,
      url: "https://alpura.com/",
    },
    stand09: {
      image: stand09,
      url: "https://www.bepensa.com/",
    },
    stand10: {
      image: stand10,
      url: "https://www.grupobimbo.com/es/nuestras-marcas",
    },
    stand11: {
      image: stand11,
      url: "https://www.cemexmexico.com/",
    },
    stand12: {
      image: stand12,
      url: "https://www.femsa.com/es/",
    },
    stand13: {
      image: stand13,
      url: "https://www.estafeta.com/",
    },
    stand14: {
      image: stand14,
      url: "https://portal.grupomodelo.com/login",
    },
    stand15: {
      image: stand15,
      url: "http://www.hesa.com.mx/",
    },
    stand16: {
      image: stand16,
      url: "https://www.holcim.com.mx/",
    },
    stand17: {
      image: stand17,
      url: "http://grupojumex.mx/",
    },
    stand18: {
      image: stand18,
      url: "https://www.lacorona.com.mx/",
    },
    stand19: {
      image: stand19,
      url: "https://www.lala.com.mx/",
    },
    stand20: {
      image: stand20,
      url: "https://www.marva.com.mx/",
    },
    stand21: {
      image: stand21,
      url: "http://gruponorycaribe.com/",
    },
    stand22: {
      image: stand22,
      url: "https://www.elpalaciodehierro.com/",
    },
    stand23: {
      image: stand23,
      url: "https://www.paquetexpress.com.mx/",
    },
    stand24: {
      image: stand24,
      url: "https://atpilot.mx/",
    },
    stand25: {
      image: stand25,
      url: "https://www.sigma-alimentos.com/",
    },
    stand26: {
      image: stand26,
      url: "https://solistica.com/",
    },
    stand27: {
      image: stand27,
      url: "https://temlogistica.com.mx/",
    },
    stand28: {
      image: stand28,
      url: "https://www.tiusa.com.mx/site/www.tiusa.com.mx/index.html",
    },
    stand29: {
      image: stand29,
      url: "https://tms.com.mx/",
    },
    stand30: {
      image: stand30,
      url: "http://www.transcoolermexico.com/",
    },
    stand31: {
      image: stand31,
      url: "https://www.tratelogistica.com/PortalTrate/",
    },
    stand32: {
      image: stand32,
      url: "https://www.zemog.com.mx/",
    },
    stand33: {
      image: stand33,
      url: "https://zonte.com.mx/",
    },
  };

  const [verWhastsapp, setVerWhatsapp] = useState(true);
  const { usuario, datos } = useContext(Auth);
  const [dia, setDia] = useState(1);

  useEffect(() => {
    app.obtener_objetos(nombre_evento + `/configuracion`, true, (data) => {
      //setVerWhatsapp(data[0].whats);
      setDia(data[0].dia_metricas);
    });
  }, []);

  const metricas = (nombre_stand) => {
    let metrica = { dia: dia, ...datos };
    if (usuario) {
      app.verificar_objeto_dia(
        nombre_evento + `/stands/${nombre_stand}`,
        "correo",
        datos.correo,
        dia,
        (data) => {
          if (!data) {
            app.escribir(
              nombre_evento + `/stands/${nombre_stand}`,
              undefined,
              metrica,
              () => {}
            );
          }
        }
      );
    } else {
      app.escribir(
        nombre_evento + `/stands/${nombre_stand}`,
        undefined,
        { clic: 1, dia: dia },
        () => {}
      );
    }
  };

  const stands = [
    {
      url: "/expo/scania",
      nombre: "scania",
      js: (
        <Scania
          verWhastsapp={verWhastsapp}
          onMetrica={metricas}
          nombre="scania"
        />
      ),
    },
  ];

  const location = useLocation();
  return (
    <Row>
      <Col span={24}>
        <img src={standsArray[nombre]["image"]} style={{ width: "100%" }} />
        <div style={{ marginTop: "35px", marginBottom: "15px" }}>
          <a
            href={standsArray[nombre]["url"]}
            className="button-login"
            target="_blank"
          >
            CONTACTO
          </a>
        </div>
        {/* {stands
          .filter((stand) => stand.url === location.pathname)
          .map((stand) => {
            return stand.js;
          })} */}
      </Col>
    </Row>
  );
};

export default Stand;
