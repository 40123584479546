import React from 'react';
import Firebase from '../../utils/firebaseConfig';
import { Redirect } from 'react-router-dom'; 

const app = new Firebase();

const CerrarSesion = () => {

    app.app.auth().signOut().then(function() {
        
      }).catch(function(error) {
        // An error happened.
      });

    return(
        <Redirect to="/" />
    );

}

export default CerrarSesion;
