import React, { useEffect, useState } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { Element, scroller } from "react-scroll";
import Firebase from "../../utils/firebaseConfig";

import Error from "../auth/Error";
import Registro from "../registro/Registro";
import Diplomas from "../en-vivo/Diplomas";

import { AuthContext } from "../../utils/AuthContext";
import Admin from "../admin/Admin";
import VisualizarPreguntas from "../admin/VisualizarPreguntas";
import CerrarSesion from "../sesion/CerrarSesion";
import Header from "./Header2";
import Footer from "./Footer";
import Menu from "./Menu";

import "../../assets/css/general.css";
import ConferenciaEmpresas from "../en-vivo/ConferenciaEmpresas";
import Agenda from "../pantallas/Agenda";
import MandarMensaje from "./MandarMensaje";
import Lobby from "../pantallas/Lobby";
import Expo from "../expo/Expo";
import Stand from "../expo/Stands";
import Galeria from "../galeria/Galeria";
import ImagesGaleria from "../galeria/ImagesGaleria";

import Conin from "../pantallas/Conin";
import Felicitacion from "../galeria/Felicitacion";
import ExpoWeb from "../expo/ExpoWeb";
import VerEmpresas from "../testimonios/VerEmpresas";
import Ganadores from "../conferencias/Ganadores";

const firebase = new Firebase();

const secciones_auth = [
  { componente: Felicitacion, url: "/felicitacion" },
  { componente: Lobby, url: "/" },
  { componente: Ganadores, url: "/ganadores" },
  { componente: ConferenciaEmpresas, url: "/felicitacion-conductor" },
  { componente: ConferenciaEmpresas, url: "/felicitacion-conductor/:empresa" },


  //{ componente: ExpoWeb, url: "/expo-web" },
  //{ componente: Home, url: "/" }, proximamanete
  //{ componente: Registro, url: "/bienvenida" },
  //{ componente: Registro, url: "/registro" },
  
  
  //{ componente: VerEmpresas, url: "/testimonios" },
  //{ componente: VerEmpresas, url: "/testimonios:empresa" },
  //{ componente: ImagesGaleria, url: "/galeria/:empresa" },
  //{ componente: Agenda, url: "/agenda" },
  //{componente:Agenda, url:'/'},
  //{ componente: Expo, url: "/exposicion-y-actividades" },
  //{ componente: Diplomas, url: "/ver-diplomas" },
  //{ componente: Expo, url: "/expo" },
  //{ componente: Stand, url: "/expo/:nombre" },
  { componente: Error, utl: "/*" },
];

const demos = [
  {
    componente: Lobby,
    url: "/en-vivo",
    youtube: "https://www.youtube.com/embed/mtO0R8TbwVQ",
    comentarios: true,
    nombre_video: "binevenida",
  }
];

const secciones = [
  { componente: Admin, url: "/admin-preguntas" },
  { componente: VisualizarPreguntas, url: "/visualizar-preguntas" },
  { componente: CerrarSesion, url: "/cerrar-sesion" },
];

export const Master = () => {
  const location = useLocation();

  const [etapa, setEtapa] = useState(3);
  useEffect(() => {
    firebase.obtener_objetos(
      "premio-2024/configuracion_flujo",
      true,
      (data) => {
        setEtapa(data[0].etapa);

        switch (data[0].etapa) {
          case 0:
            if (
              location.pathname !== "/" &&
              location.pathname !== "/bienvenida" &&
              location.pathname !== "/admin-preguntas" &&
              location.pathname !== "/agenda" &&
              location.pathname !== "/bienvenida" &&
              location.pathname !== "/registro"
            ) {
              window.location.href = "/";
            }
            break;
          case 1:
            if (
              location.pathname !== "/auditorio" &&
              location.pathname !== "/admin-preguntas" &&
              location.pathname !== "/agenda" &&
              location.pathname !== "/bienvenida" &&
              location.pathname !== "/registro"
            ) {
              window.location.href = "/auditorio";
            }
            break;
          case 2:
            if (
              !location.pathname.includes("felicitacion-conductor") &&
              location.pathname !== "/admin-preguntas" &&
              location.pathname !== "/agenda" &&
              location.pathname !== "/bienvenida" &&
              location.pathname !== "/registro"
            ) {
              window.location.href = "/felicitacion-conductor";
            }
            break;
          case 3:
            if (
              location.pathname !== "/exposicion-y-actividades" &&
              location.pathname !== "/admin-preguntas" &&
              location.pathname !== "/agenda" &&
              location.pathname !== "/bienvenida" &&
              location.pathname !== "/registro"
            ) {
              window.location.href = "/exposicion-y-actividades";
            }
            break;
          case 4:
            if (
              location.pathname !== "/felicitacion" &&
              location.pathname !== "/admin-preguntas" &&
              location.pathname !== "/agenda" &&
              location.pathname !== "/bienvenida" &&
              location.pathname !== "/registro"
            ) {
              window.location.href = "/felicitacion";
            }
            break;
            case 6:
              if (
                location.pathname !== "/auditorio" &&
                location.pathname !== "/admin-preguntas" &&
                location.pathname !== "/agenda" &&
                location.pathname !== "/bienvenida" &&
                location.pathname !== "/registro"
              ) {
                window.location.href = "/auditorio";
              }
              break;
              case 7:
              if (
                location.pathname !== "/testimonios" &&
                location.pathname !== "/admin-preguntas" &&
                location.pathname !== "/agenda" &&
                location.pathname !== "/bienvenida" &&
                location.pathname !== "/registro"
              ) {
                window.location.href = "/testimonios";
              }
              break;
          default:
            break;
        }
      }
    );
  }, []);

  let visualizar_secciones_auth = secciones_auth.map((seccion) => {
    return <Route path={seccion.url} exact component={seccion.componente} />;
  });

  let visualizar_secciones = secciones.map((seccion) => {
    return <Route path={seccion.url} exact component={seccion.componente} />;
  });

  let visualizar_demos = demos.map((seccion) => {
    return (
      <Route path={seccion.url} exact>
        <Lobby
          youtube={seccion.youtube}
          comentarios={seccion.comentarios}
          nombre_video={seccion.nombre_video}
        />
      </Route>
    );
  });

  return (
    <AuthContext>
      <MandarMensaje />
      <Header />
      <Switch>
        {visualizar_demos}
        {visualizar_secciones}
        {visualizar_secciones_auth}
      </Switch>
      <Footer />
    </AuthContext>
  );
};
