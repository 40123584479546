import React, { useContext, useState, useEffect } from 'react';
import {Link, useLocation } from 'react-router-dom';
import { Row, Col, Modal, notification } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import '../../assets/css/evento.css';
import Firebase from '../../utils/firebaseConfig';

import logo_pnsv from '../../assets/images/premio_2023/logo_pnsv_horizontal.png';
import logo_usuarios from '../../assets/images/premio_2022/layout/pnsv_web_LogoUsuarios.png';
import { Auth } from '../../utils/AuthContext';
import Login from '../registro/Login';


const app = new Firebase();

const nombre_evento = 'premio-2024';

const secciones = [
  //{nombre:'BIENVENIDA', to:'/bienvenida', etapa:0},
  {nombre:'EN VIVO', to:'/en-vivo', etapa:1},
  {nombre:'GANADORES', to:'/ganadores', etapa:2},
  //{nombre:'EXPO', to:'/expo', etapa:3},
  {nombre:'FELICITACIONES', to:'/felicitacion', etapa:4},
  //{nombre:'TESTIMONIALES', to:'/testimonios', etapa:7},
];

const success = () => {
    mensaje('success','Bienvenido','');
};

const mensaje = (tipo,titulo, texto) =>{
    notification[tipo]({
        message: titulo,
        description: texto,
    });

}

const Header = ({history}) => {

  const { usuario } = useContext(Auth);
  const [modal_entrar, setModalEntrar] = useState(false);
  const [mostrar_login, setMostrarLogin] = useState(false);
  const [mostrar_submenu, setMostrarSubmenu] = useState(false);
  const [mostrar_navegar, setMostrarNavegar] = useState(false);
  const [etapa, setEtapa] = useState(0);
  const [imagen, setImagen] = useState(false);

  useEffect(() => {
    /*if (!usuario) {
      //setMostrarLogin(true);
      setImagen(true);
    }else{
        setMostrarLogin(false);
        setImagen(false);
    }*/
  },[usuario]);

  useEffect(()=>{
    app.obtener_objetos(nombre_evento+'/configuracion_flujo',true,(data) => {
      setEtapa(data[0].etapa);
      if(data[0].etapa === 5 || data[0].etapa === 6){
        setMostrarNavegar(true);
      }else{
        setMostrarNavegar(false);
      }
    });
  },[]);

  let location = useLocation();
  console.log(location.pathname);

    let visualizar_secciones = secciones.filter(seccion => {
      if(seccion.etapa !== -1 || etapa === 5 || etapa === 6) return true; else return false;
    }).map((seccion)=>{

      return(
        <Col>
        <Link to={mostrar_navegar ? seccion.to : ''} onClick={() => {setMostrarSubmenu(false)}}>
        <span className={mostrar_navegar ? location.pathname === seccion.to ? 'titles_habilitado_select' : 'titles_habilitado' : 'titles' }>
          {seccion.nombre}
        </span>
        </Link>
        </Col>
        
      );
    });

  return(
    <>

    
    <Row align="middle" className="header_contenedor" style={{paddingTop:'10px', paddingBottom:'10px'}}>
        
        <Col xs={{span:4, offset:2}} md={{span:3, offset:1}} style={{ padding: '0px' }} className="logo_header_dos">
            <img alt="logo" style={{width:'200px'}} src={imagen ? logo_pnsv : logo_usuarios} />
        </Col>

      <Col xs={{span:0, offset:0}} lg={{span:18, offset:0}}>
        
          <Row justify="end">
          
            <>
            {visualizar_secciones}
            
            {usuario &&
            <Col>
            <Link  to="/cerrar-sesion">
            <span className="titles" style={{color:'white'}}>
              SALIR
            </span>
          </Link>
          </Col>
          }
            
            </>
            
          </Row>
        
        
      </Col>

      <Col xs={{span:17, offset:0}} lg={{span:0, offset:0}} style={{textAlign:'right'}}>
        {usuario &&
        <MenuOutlined onClick={() => {setMostrarSubmenu(!mostrar_submenu)}} style={{paddingRight:'10px', fontSize:'20px', color:'white'}} />
        }
      </Col>

      <Col xs={{span:24}} lg={{span:0}}>
        { (mostrar_navegar && mostrar_submenu) &&
        <>
        {visualizar_secciones}

        <Col>
                  <Link  to="/cerrar-sesion" onClick={() => {setMostrarSubmenu(false)}}>
                  <span className="titles" onClick={() => {setMostrarSubmenu(false)}}>
                    Salir
                  </span>
                </Link>
                </Col>

       
        </>

        }


      
      </Col>
        <Modal
          visible={modal_entrar}
          title=""
          onCancel={ () => { setModalEntrar(false); }}
          footer={null}
        >
          { modal_entrar &&
            <div></div>
          }
        </Modal>
    </Row>

   

    <Modal
          title="INICIA SESIÓN"
          visible={mostrar_login}
          onOk={() => {setMostrarLogin(false)}}
          onCancel={() => {setMostrarLogin(false)}}
          footer={null}
        >
          <Login onResponse={() => {setMostrarLogin(false); window.location.href="/transmision"; } } onClick={success} />
        </Modal>
    
    </>
    );
}

export default Header;