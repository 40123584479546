import { useState } from 'react';
import React from 'react';
import { Row, Col, Input } from 'antd';
import '../../assets/css/diplomas.css';
import { SearchOutlined } from '@ant-design/icons';


const personas = [
    {nombre:'ERNESTO LOZA BAÑUELOS',empresa:'AUTOTRANSPORTES PILOT S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'FELIPE HERNANDEZ MUNGUIA',empresa:'AUTOTRANSPORTES PILOT S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE FEDERICO VILLAFAÑA RODRIGUEZ',empresa:'AUTOTRANSPORTES PILOT S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'FRANCISCO ARTURO OLGUIN MORENO',empresa:'AUTOTRANSPORTES PILOT S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'JUAN FRANCISCO SARABIA MENDOZA',empresa:'AUTOTRANSPORTES PILOT S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'JUAN DANIEL JIMENEZ CARRILLO',empresa:'AUTOTRANSPORTES PILOT S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'MARCO ALONSO CORNEJO CASTILLO',empresa:'AUTOTRANSPORTES PILOT S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'GERARDO RAMIREZ RAYMUNDO',empresa:'AUTOTRANSPORTES PILOT S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'OCTAVIO RENE MENDOZA MARTINEZ',empresa:'AUTOTRANSPORTES PILOT S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'CHRISTIAN ESTUARDO CAZARES RODRIGUEZ',empresa:'AUTOTRANSPORTES PILOT S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'JULIO CESAR LICONA VARGAS',empresa:'AUTOTRANSPORTES PILOT S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'VICTOR HUGO MUÑOZ HERNANDEZ',empresa:'AUTOTRANSPORTES PILOT S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'EDGAR EMMANUEL MARTINEZ RODRIGUEZ',empresa:'AUTOTRANSPORTES PILOT S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'JOSE JUAN HERNANDEZ LEON',empresa:'AUTOTRANSPORTES PILOT S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'CESAR IGOR ALVAREZ PULIDO',empresa:'AUTOTRANSPORTES PILOT S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'JUAN JOSE RUVALCABA CERVANTES',empresa:'AUTOTRANSPORTES PILOT S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'GABRIEL CIRO MORENO ALCANTARA',empresa:'AUTOTRANSPORTES PILOT S.A. DE C.V.',lugar:'Salón de la fama'},
    {nombre:'GABRIEL DIAZ DIAZ',empresa:'AUTOTRANSPORTES PILOT S.A. DE C.V.',lugar:'Salón de la fama'},
    {nombre:'ABELARDO CALLEJA RIVERA',empresa:'AUTOTRANSPORTES ZONTE S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JESUS LEYVA BUSTAMANTE',empresa:'TORRES TRANSPORTES TERRESTRES',lugar:'1er Lugar'},
    {nombre:'JOSE MANUEL CHAVEZ GONZALEZ',empresa:'AUTOTRANSPORTES ZONTE S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'ARTURO TOVAR FLORES',empresa:'AUTOTRANSPORTES ZONTE S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'EDWIN CUEVAS VAZQUEZ',empresa:'AUTOTRANSPORTES ZONTE S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'EMMANUEL ALEJANDRO LEON ALAVEZ',empresa:'AUTOTRANSPORTES ZONTE S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'JAVIER MELO FLORES',empresa:'TORRES TRANSPORTES TERRESTRES',lugar:'2do Lugar'},
    {nombre:'JOSE MIGUEL SEGOVIA AYALA',empresa:'AUTOTRANSPORTES ZONTE S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'ELVIS JOSE SANTIAGO APARICIO',empresa:'AUTOTRANSPORTES ILHUILCAMINA S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'JUAN RIVERA DOMINGUEZ',empresa:'TORRES TRANSPORTES TERRESTRES',lugar:'3er Lugar'},
    {nombre:'JUAN JESUS MIRANDA PIZANO',empresa:'TORRES TRANSPORTES TERRESTRES',lugar:'3er Lugar'},
    {nombre:'JUAN MANUEL MORA VARGAS',empresa:'AUTOTRANSPORTES ILHUILCAMINA S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'LUIS MIGUEL ZUÑIGA LOREDO',empresa:'AUTOTRANSPORTES ZONTE S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'OSCAR HERNANDEZ GONZAGA',empresa:'AUTOTRANSPORTES ZONTE S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'OMAR ALEJANDRO CASTELLON ORTIZ',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'SERGIO MARTINEZ VENTURA',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'FERNANDO FABIAN AGUILAR',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE MANUEL NOLASCO ARELLANO',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'VICTOR MANUEL DE LA TORRE BAUTISTA',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JUAN ANTONIO HUERTA PEÑA',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JORGE ERMINIO CASTREJON HERNANDEZ',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JUAN EDUARDO VEGA DE LA PEÑA',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JUAN LUIS PADILLA HIDALGO',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JUAN GUTIERREZ VAZQUEZ',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JUAN JESUS HERNANDEZ MENDEZ',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'FRANCISCO INDALECIO LOPEZ PEREZ',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JULIAN HUMBERTO OJEDA DORANTES',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JARED EDUARDO ARELLANO FELICIANO',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'ERNESTO JIMENEZ ISLAS',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JUAN CARLOS SANTIAGO VASQUEZ',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JUAN CARLOS LOPEZ RIVERA',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JUAN EDUARDO DIAZ ALVARADO',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JORGE MEDINA JUAREZ',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'CESAR LORENZO MERAZ TREVIZO',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'LUIS OMAR LADRON DE GUEVARA HERNANDEZ',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JUAN VALTIERRA LUCAS',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JULIO ENCARNACION HERRERA PEREZ',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JUAN CARLOS DELGADO ROSAS',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JULIO JARDINEZ GAYOSSO',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'RICARDO CARREON ALVAREZ',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'MARCOS JASSO CELEDONIO',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'CECILIO ORTIZ CABRERA',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE LUIS GARMA CAMARA',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JOEL SALAZAR GONZALEZ',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'CLEMENTE MOISES HERRERA PLATA',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JESUS MORENO ESTRADA',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'CESAR MUNGUIA PALAFOX',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JUAN MANUEL REYES',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'SILVERIO SUSTAITA MORALES',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'VICTOR SEGUNDO ANDRADE',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'RICARDO GUZMAN SALDAÑA',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'OMAR EDILBERTO VELASCO CABALLERO',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE LUIS GARCIA RIOS',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JUAN JOSE MARTINEZ ACIANO',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'SALUD MIJAIL GUTIERREZ SORIA',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'GILBERTO REYES BECERRA',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'IRIS FERNANDA MONTEJO TORRES',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE LUIS BAUTISTA MORALES',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'MARCO ANTONIO LOPEZ MEDINA',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'LORENZO MORENO GARCIA',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'ABRAHAM MARTINEZ VACA',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'ALEJANDRO ALARCON MUÑOZ',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'MIGUEL ANGEL HUERTA NAVARRO',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JAVIER VILLASEÑOR HERRERA',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'GUILLERMO GOMEZ SUAREZ',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'EDGAR AMEZCUA PEREZ',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'FRANK WILLIAMS IRIGOYEN MORA',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE LUIS ORTA ZAVALA',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'IVAN CASTRO VASQUEZ',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'MARTIN ALFONSO HERRERA MEDINA',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'EYTER XAVIER VEGA CRESPO',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JUAN MANUEL MORENO FRANCO',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'OBED ISAAC CANO HERNANDEZ',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'FERNANDO RAUL HERNANDEZ RODRIGUEZ',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'SALVADOR BAÑALES SUAREZ',empresa:'BARCEL, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JESUS JAIR NUÑES LUGO',empresa:'BARCEL, S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'OSCAR SERVIN GUTIERREZ',empresa:'BARCEL, S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'MARCO ANTONIO ESPINOZA ESCUTIA',empresa:'BARCEL, S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'JOSE ANTONIO ARENAS RIOS',empresa:'BARCEL, S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'ALAN BRANDON URBANO ARREOLA',empresa:'BARCEL, S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'LUIS ROBERTO REYES VELAZQUEZ',empresa:'BARCEL, S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'NOEMI ESTEVEZ LAZARO',empresa:'BARCEL, S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'NALLELY MATILDE GARCIA GARCIA',empresa:'BARCEL, S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'JUAN DAVID TEC ZUÑIGA',empresa:'BARCEL, S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'DAVID ALEJANDRO CIME ARCEO',empresa:'BARCEL, S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'JOSE LUIS GUADALUPE SANTILLAN ZEPEDA',empresa:'BARCEL, S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'PABLO ESTRADA GONZALEZ',empresa:'BARCEL, S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'JOSE FRANCISCO NAVARRETE ESTRELLA',empresa:'BARCEL, S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'JORGE ALEJANDRO SALAS REYES',empresa:'BARCEL, S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'FELIX QUESADA LOZANO',empresa:'BARCEL, S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'JUAN SALVADOR GONZALEZ GOMEZ',empresa:'BARCEL, S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'EDUARDO GONZALEZ GOMEZ',empresa:'BARCEL, S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'JONATHAN GUERRA ROJAS',empresa:'BARCEL, S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'RODRIGO IVAN LOPEZ PUENTE',empresa:'BARCEL, S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'MARCOS ALBERTO VACA LOPEZ',empresa:'BARCEL, S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'CLAUDIO NOE RAZO ARMENTA',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'SAMUEL SANTA MARIA CORTES',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'SERGIO NOEL HUERTA ARELLANO',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JUAN GUILLERMO GARCIA PARRA',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'FRANCISCO JAVIER RODRIGUEZ SANDOVAL',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JAVIER CASILLAS GONZALEZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE CRUZ MEDINA ARREOLA',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JOSUE ANTONIO GUERRERO CORTINA',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'VICTOR GABRIEL CASTRO SILVA',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'NAPOLEON VALENZUELA GARCIA',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'RUBEN AURELIO NAVIDAD BUSTAMANTE',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JAIME DOMINGUEZ GARCIA',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'OMAR GUSTAVO AGUEROS GOMEZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'ANGEL SALVADOR VALDOVINO DURAN',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'LUIS JOAQUIN MORALES LOPEZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE ANTONIO RODRIGUEZ ARRIAGA',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'ANGEL GOMEZ LARIOS',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'SILVINO ANTONIO MARTINEZ LUNA',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE LUIS CRUZ TORRES',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'MIGUEL ANGEL CASCO DE JESUS',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'LUIS MANUEL LOPEZ MEDINA',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE INOCENCIO CASTRO LOPEZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'MARCO ANTONIO CASTRO CASTRO',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'ROSEMBERG BELTRAN ALVARADO',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JESUS FAUSTO DIAZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JORGE ANTONIO INFANTE CORNEJO',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'EDGAR VENTURA AVILA',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'RAFAEL CONTRERAS BECERRA',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'EDGAR GERARDO ESCOBEDO RODRIGUEZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'FRANCESCOLE SARMIENTO PEREZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'VALENTIN AMEZQUITA SANCHEZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'OSCAR CARMONA CENOBIO',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE ALFREDO BECERRIL MENDOZA',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE MANUEL ECHEVERRIA ESTRADA',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JOSUE DANIEL VAZQUEZ ORTIZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JUAN CARLOS GUTIERREZ INZUNZA',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'ANDRES AGUILAR RAMIREZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JUAN CARLOS CONTRERAS VIELMAS',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JUAN CARLOS PINEDA VARGAS',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'DIEGO ROMERO JUAREZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'GERARDO AVELAR GOMEZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'ISMAEL ACOSTA MARTINEZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'MARIA LOURDES MARTINEZ RAMIREZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'EVER ALFREDO CHAVEZ CAZARES',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'DAVID SILVA CORONA',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'ALEJANDRO DOTTOR GALLEGOS',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE LUIS NUÑEZ ARRIAGA',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'IGNACIO ENRIQUEZ MORENO',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'EDGAR GERARDO GUTIERREZ EVANS',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JUAN FRANCISCO MENDOZA INFANTE',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'MARIO ALBERTO OVALLE MAIZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JORGE ALBERTO SOLANO LOPEZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'FELIPE ISRAEL CARBAJAL GUADARRAMA',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'ENRIQUE DELGADO ULLOA',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JORGE OSCAR HERNANDEZ FRANCO',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'CASIMIRO SALINAS AC',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'RODRIGO ZARATE ESQUIVEL',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'ELOY SUAREZ SANCHEZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'EDGAR ROMERO LOPEZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'MARIO CESAR PEREZ SANCHEZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'VICTOR MARTIN LOPEZ SOLARES',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'GUSTAVO MORALES RIZO',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'EDUARDO CARRIZALES CASTILLO',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE LUIS ALONSO MORALES',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'FRANCISCO JAVIER ALCARAZ MUÑOZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JUAN PABLO MORALES MARIN',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE LUIS BADILLO CORTES',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'GABRIEL DOMINGUEZ SANCHEZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE ALEJANDRO GUERRERO VEGA',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'MANUEL JESUS ROBLES CRUZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JERONIMO JESUS GAMAS CALIX',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE FERNANDO RENOVATO CARDENAS',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JESUS GUADALUPE LOZANO ALVARADO',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'FRANCISCO RAMIREZ TORIJA',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE MIGUEL LUCERO PACHECO',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'SERGIO JAVIER BACA TELLEZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE LORENZO CANTO MARTIN',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JESUS ALVARO CASTILLO MUÑOZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'OSCAR GONZALEZ ROMERO',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'MARCO ANTONIO ADOÑO MASCORRO',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'MARIO MARCELINO GUERRERO ORTIZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'CESAR EMILIO ZARZA DIAZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JUAN DAVID GOMEZ PEREZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JUAN CARLOS BRIONES FUENTES',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JUAN MANUEL ARIZPE FLORES',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'MARTIN GARCIA AVILES',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'MARIO ALBERTO BERMUDEZ JUAREZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'ANDRES GUSTAVO LOMELI HERMOSILLO',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'ANTONIO ALCARAZ JUAN',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'RUBEN ENRIQUE LUGO SANCHEZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JULIO CESAR MENDOZA GAONA',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'ALBERTO HUERTA CUAUTLE',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE ALBERTO MEDRANO HERNANDEZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'LAURA JAZMIN PEREZ RODRIGUEZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'RICARDO NAVARRETE BAUTISTA',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'PABLO MENDOZA MARTINEZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'SERGIO FIGUEROA TORRES',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'VICTOR MANUEL NOH MARQUEZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'MARCO ANTONIO RAMIREZ GUDIÑO',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'ANGEL DE JESUS RIOS PIÑA',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE ANTONIO HERNANDEZ ENRIQUEZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'EDUARDO SALAS OCEGUEDA',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JAVIER DIAZ CONTRERAS',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'MARCOS ANTONIO AREVALO LEYVA',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'OSCAR ISIDRO GARCIA DAVALOS',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'RICARDO SOLARES RODRIGUEZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'RUBEN EDUARDO ORTIZ CHAVEZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE ANTONIO ROMERO RODRIGUEZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JESUS ANTONIO LOPEZ HERNANDEZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'SEBASTIAN ALMEIDA CASTILLO',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'PEDRO RAMIREZ CATALAN',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'ALBERTO PEREZ LOPEZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'GABRIEL ISLAS MIRANDA',empresa:'BIMBO, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'ELIMAR SANCHEZ DE DIOS',empresa:'BIMBO, S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'JUAN MARTIN DE LEON CARBAJAL',empresa:'BIMBO, S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'MANUEL ANTONIO JIMENEZ MONTAÑO',empresa:'BIMBO, S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'JUAN RODRIGUEZ DOMINGUEZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'RIGOBERTO MARTINEZ REYNA',empresa:'BIMBO, S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'JOSE GUADALUPE ZAPATA BLANCO',empresa:'BIMBO, S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'FEDERICO SOLORZANO SANCHEZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'FABIAN MARTAGON HURTAULT',empresa:'BIMBO, S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'VICTOR MANUEL SARMIENTO CERRILLO',empresa:'BIMBO, S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'ARMANDO CHI SUAREZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'FELIPE ESPINOZA ALVAREZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'GUILLERMO ISRAEL HERNANDEZ DIAZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'VICTOR HUGO ORTIZ CARMONA',empresa:'BIMBO, S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'JORGE ALBERTO MARTINEZ TOLEDO',empresa:'BIMBO, S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'BERNABE BARRIOS SANCHEZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'HECTOR DE JESUS CASTILLO SANCHEZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'CRISTIAN ROSARIO TORRES GUTIERREZ',empresa:'BIMBO, S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'LUIS ANTONIO LORANCA GUZMAN',empresa:'BIMBO, S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'CRISTELL AGUILAR AGUILAR',empresa:'BIMBO, S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'ALFREDO SANCHEZ GABRIEL',empresa:'BIMBO, S.A. DE C.V.',lugar:'Salón de la fama'},
    {nombre:'ADAN VAZQUEZ FLORES',empresa:'BIMBO, S.A. DE C.V.',lugar:'Salón de la fama'},
    {nombre:'ALEJANDRO IVAN PORTILLO SANTOYO',empresa:'BRITISH AMERICAN TOBACCO',lugar:'1er Lugar'},
    {nombre:'JOSE LUIS ALTAMIRANO MURRIETA',empresa:'BRITISH AMERICAN TOBACCO',lugar:'1er Lugar'},
    {nombre:'CARLOS IVAN SALDAÑA POMPA',empresa:'BRITISH AMERICAN TOBACCO',lugar:'1er Lugar'},
    {nombre:'ALEJANDRO BETANZOS ALARCON',empresa:'BRITISH AMERICAN TOBACCO',lugar:'1er Lugar'},
    {nombre:'MILTON GERARDO OCAMPO GONZALEZ',empresa:'BRITISH AMERICAN TOBACCO',lugar:'2do Lugar'},
    {nombre:'ERICK ARMANDO LOZA MARTINEZ',empresa:'BRITISH AMERICAN TOBACCO',lugar:'2do Lugar'},
    {nombre:'SERGIO MORENO MARTELL',empresa:'BRITISH AMERICAN TOBACCO',lugar:'2do Lugar'},
    {nombre:'FRANCO MORAN MIXTEGA',empresa:'BRITISH AMERICAN TOBACCO',lugar:'3er Lugar'},
    {nombre:'ALFONSO LOPEZ REYES',empresa:'BRITISH AMERICAN TOBACCO',lugar:'3er Lugar'},
    {nombre:'VICTOR ADOLFO HERRERA GALDEANO',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'ALDO CAMACHO ESPINOZA',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'NIVARDO DE JESUS GONZALEZ MEDINA',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'JOSE DE JESUS TORRES VASQUEZ',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'ADAN PUGA FREGOSO',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'MARTIN MARQUEZ FLORES',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'JOSE MANUEL MADRID MORENO',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'RAYMUNDO RUIZ SOSA',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'CESAR BARRAGAN TLAZALO',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'JOSE ANTONIO AGUILAR DAVILA',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'LEONARDO GABRIEL PADILLA ULLOA',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'JOSE ABELARDO DUARTE MARES',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'MARIO ALBERTO DIAZ ENRIQUEZ',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'JULIO CESAR VENEGAS',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'JOSE MANUEL PEREZ NARVAEZ',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'JOSE ANTONIO RIVERA FLORES',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'RAUL ANDRADE LLAMAS',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'JOSE MANUEL TORRES SALDIVAR',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'EDGAR TADEO MONTOYA LUGO',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'MARTIN GARCIA ALMAZAN',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'ERNESTO ARTURO HERNANDEZ SOTO',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'BENJAMIN DEL CARMEN CAN NARVAES',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'JULIO CESAR MUÑOZ MENDOZA',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'ALFONSO BERMUDES AGUILAR',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'GERARDO REYES ESPARZA',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'RICARDO RIVERA RUBIO',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'JAIME GARCIA MARTINEZ',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'JASIEL MACRINO RUBIO RUBIO',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'NESTOR GONZALEZ VILLEGAS',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'CRISTIAN CORREA GOMEZ',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'JUAN CARLOS PABLO GOMEZ',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'CARLOS EMIDIO HERNANDEZ BARAJAS',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'FERMIN MEZA PEREYRA',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'ALDO AMEZCUA MERAS',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'LUCIO PLACENCIA JACOBO',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'JULIO CESAR MORALES SANCHEZ',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'JOSE JUAN LOYOLA GARCIA',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'ALBERTO URIEL SALAZAR HERNANDEZ',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'SERGIO ENRIQUE ASTORGA SANCHEZ',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'JOSE MIGUEL ROMERO SANCHEZ',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'OMAR TRIANA CRIOLLA',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'JUAN ALONSO FERREYRA RANGEL',empresa:'CEMEX CONCRETOS',lugar:'1er Lugar'},
    {nombre:'OSCAR DANIEL CONTRERAS LARIOS',empresa:'CEMEX CONCRETOS',lugar:'2do Lugar'},
    {nombre:'CESAR RUIZ BAUTISTA',empresa:'CEMEX CONCRETOS',lugar:'2do Lugar'},
    {nombre:'JORGE AGUSTIN DUQUE CARDENAS',empresa:'CEMEX CONCRETOS',lugar:'2do Lugar'},
    {nombre:'JOSE GABRIEL MARTINEZ SOLIS',empresa:'CEMEX CONCRETOS',lugar:'2do Lugar'},
    {nombre:'FRANCISCO JUAREZ ALVAREZ',empresa:'CEMEX CONCRETOS',lugar:'2do Lugar'},
    {nombre:'ABNER ELIEL RAMOS PATRICIO',empresa:'CEMEX CONCRETOS',lugar:'2do Lugar'},
    {nombre:'JAIME HERNANDEZ BARCENAS',empresa:'CEMEX CONCRETOS',lugar:'2do Lugar'},
    {nombre:'RAUL LUIS ARTURO PEREZ HURTADO',empresa:'CEMEX CONCRETOS',lugar:'2do Lugar'},
    {nombre:'ROLANDO ZAVALA PALOMINO',empresa:'CEMEX CONCRETOS',lugar:'2do Lugar'},
    {nombre:'ROBERTO CARLOS DIAZ HERNANDEZ',empresa:'CEMEX CONCRETOS',lugar:'2do Lugar'},
    {nombre:'DAVID MISAEL AGUILAR SANCHEZ',empresa:'CEMEX CONCRETOS',lugar:'2do Lugar'},
    {nombre:'ABELARDO MARTINEZ GALLARDO',empresa:'CEMEX CONCRETOS',lugar:'2do Lugar'},
    {nombre:'J. JESUS PATRICIO LUCIANO',empresa:'CEMEX CONCRETOS',lugar:'2do Lugar'},
    {nombre:'LUIS ANGEL MARTINEZ VILLAGRANA',empresa:'CEMEX CONCRETOS',lugar:'3er Lugar'},
    {nombre:'CIRO GALICIA RODRIGUEZ',empresa:'CEMEX CONCRETOS',lugar:'3er Lugar'},
    {nombre:'ANTONIO TULAIS EQUIHUA',empresa:'CEMEX CONCRETOS',lugar:'3er Lugar'},
    {nombre:'DANIEL VALDERAS RUIZ',empresa:'CEMEX CONCRETOS',lugar:'3er Lugar'},
    {nombre:'JONATHAN SABRAIN VARGAS GOMEZ',empresa:'CEMEX CONCRETOS',lugar:'3er Lugar'},
    {nombre:'JUAN CARLOS DE JESUS DE LA CRUZ',empresa:'CEMEX CONCRETOS',lugar:'3er Lugar'},
    {nombre:'JUAN FRANCISCO JIMENEZ ZAMBRANO',empresa:'CEMEX CONCRETOS',lugar:'3er Lugar'},
    {nombre:'JOSE MONTAÑO CARLOS',empresa:'CEMEX CONCRETOS',lugar:'3er Lugar'},
    {nombre:'FERNANDO LEÓN AGUILAR',empresa:'COCA-COLA FEMSA',lugar:'1er Lugar'},
    {nombre:'JUAN BERNARDO TRUJILLO GONZALEZ',empresa:'COCA-COLA FEMSA',lugar:'1er Lugar'},
    {nombre:'EUSTIQUIO DE JESUS GARCIA SOBERANO',empresa:'COCA-COLA FEMSA',lugar:'1er Lugar'},
    {nombre:'JESUS ALFONSO COMONFORT SANCHEZ',empresa:'COCA-COLA FEMSA',lugar:'1er Lugar'},
    {nombre:'JOSE MARTIN MAYOR RAMIREZ',empresa:'COCA-COLA FEMSA',lugar:'1er Lugar'},
    {nombre:'AARON GONZALEZ LOPEZ',empresa:'COCA-COLA FEMSA',lugar:'1er Lugar'},
    {nombre:'MIGUEL ANGEL CASTILLO HERNANDEZ',empresa:'COCA-COLA FEMSA',lugar:'1er Lugar'},
    {nombre:'NAHUM CASTILLO CAZARES',empresa:'COCA-COLA FEMSA',lugar:'1er Lugar'},
    {nombre:'DARÍO COYOTE MEDINA',empresa:'COCA-COLA FEMSA',lugar:'1er Lugar'},
    {nombre:'EDUARDO MACHIN LEIVA',empresa:'COCA-COLA FEMSA',lugar:'1er Lugar'},
    {nombre:'DANIEL GUZMAN ZAMORA',empresa:'COCA-COLA FEMSA',lugar:'1er Lugar'},
    {nombre:'DAVID CHORA CARDENAS',empresa:'COCA-COLA FEMSA',lugar:'1er Lugar'},
    {nombre:'CARLOS ALBERTO DOMINGUEZ CASTILLO',empresa:'COCA-COLA FEMSA',lugar:'1er Lugar'},
    {nombre:'LUIS ENRIQUE DE LA TORRE CAMPOS',empresa:'COCA-COLA FEMSA',lugar:'1er Lugar'},
    {nombre:'PAUL IVÁN GARCÍA PALOMARES',empresa:'COCA-COLA FEMSA',lugar:'1er Lugar'},
    {nombre:'JOSÉ FRANCISCO LÓPEZ CAMPOS',empresa:'COCA-COLA FEMSA',lugar:'1er Lugar'},
    {nombre:'MANUEL ANTONIO MENDEZ GOMEZ',empresa:'COCA-COLA FEMSA',lugar:'1er Lugar'},
    {nombre:'JAVIER ALEJANDRO LOPEZ GONZALEZ',empresa:'COCA-COLA FEMSA',lugar:'1er Lugar'},
    {nombre:'ADRIAN ALEJANDRO LARA VILLA',empresa:'COCA-COLA FEMSA',lugar:'1er Lugar'},
    {nombre:'DANTE CRISTIAN VAZQUEZ MARTINEZ',empresa:'COCA-COLA FEMSA',lugar:'1er Lugar'},
    {nombre:'FELIPE GUZMÁN RODRÍGUEZ',empresa:'COCA-COLA FEMSA',lugar:'1er Lugar'},
    {nombre:'CARLOS EDUARDO RAMOS GUTIERREZ',empresa:'COCA-COLA FEMSA',lugar:'1er Lugar'},
    {nombre:'EFRAIN BECERRA GARCIA',empresa:'COCA-COLA FEMSA',lugar:'1er Lugar'},
    {nombre:'MARCIAL RIVERA SANCHEZ',empresa:'COCA-COLA FEMSA',lugar:'1er Lugar'},
    {nombre:'JOSE OMAR CARREON GARAVITO',empresa:'COCA-COLA FEMSA',lugar:'2do Lugar'},
    {nombre:'MANUEL ANTONIO PEREZ MAZA',empresa:'COCA-COLA FEMSA',lugar:'2do Lugar'},
    {nombre:'JOSE ANGEL NUCAMENDI GOMES',empresa:'COCA-COLA FEMSA',lugar:'2do Lugar'},
    {nombre:'JESUS DEL CARMAN CASTELLANOS MARTINEZ',empresa:'COCA-COLA FEMSA',lugar:'2do Lugar'},
    {nombre:'DANIEL SANCHEZ CANDIDO',empresa:'COCA-COLA FEMSA',lugar:'2do Lugar'},
    {nombre:'VÍCTOR MANUEL FERNÁNDEZ HERNÁNDEZ',empresa:'COCA-COLA FEMSA',lugar:'2do Lugar'},
    {nombre:'IGNACIO OVANDO ESTRADA',empresa:'COCA-COLA FEMSA',lugar:'2do Lugar'},
    {nombre:'ULISES ALEJANDRO HERNANDEZ CORONA',empresa:'COCA-COLA FEMSA',lugar:'2do Lugar'},
    {nombre:'CARLOS LORENZO MARTINEZ RIOS',empresa:'COCA-COLA FEMSA',lugar:'2do Lugar'},
    {nombre:'ELEAZAR OMAR ROSAS MALDONADO',empresa:'COCA-COLA FEMSA',lugar:'2do Lugar'},
    {nombre:'ALFREDO IVAN LOPEZ GARCIA',empresa:'COCA-COLA FEMSA',lugar:'2do Lugar'},
    {nombre:'NOE BALBUENA SANTOS',empresa:'COCA-COLA FEMSA',lugar:'2do Lugar'},
    {nombre:'JOSE ANTONIO LOPEZ VIDAL',empresa:'COCA-COLA FEMSA',lugar:'2do Lugar'},
    {nombre:'EDUARDO CARDONA MOLINA',empresa:'COCA-COLA FEMSA',lugar:'2do Lugar'},
    {nombre:'ISRAEL JUAREZ VALENCIA',empresa:'COCA-COLA FEMSA',lugar:'2do Lugar'},
    {nombre:'LUIS ALBERTO GONZALEZ POPOMEYA',empresa:'COCA-COLA FEMSA',lugar:'2do Lugar'},
    {nombre:'SALVADOR HERNANDEZ SUASTEGUI',empresa:'COCA-COLA FEMSA',lugar:'2do Lugar'},
    {nombre:'TEODOLO GARCÍA MORALES',empresa:'COCA-COLA FEMSA',lugar:'2do Lugar'},
    {nombre:'ARMANDO MEJIA PEDRAZA',empresa:'COCA-COLA FEMSA',lugar:'3er Lugar'},
    {nombre:'MARIO ALBERTO VELASCO ALBORES',empresa:'COCA-COLA FEMSA',lugar:'3er Lugar'},
    {nombre:'GABRIEL IVAN MONCAYO GUTIERREZ',empresa:'COCA-COLA FEMSA',lugar:'3er Lugar'},
    {nombre:'FERNANDO ISRAEL AVILA CALIXTRO',empresa:'COCA-COLA FEMSA',lugar:'3er Lugar'},
    {nombre:'HUGO ENRIQUE MENDEZ GOMEZ',empresa:'COCA-COLA FEMSA',lugar:'3er Lugar'},
    {nombre:'RAYMUNDO ORTIZ SERRANO',empresa:'COCA-COLA FEMSA',lugar:'3er Lugar'},
    {nombre:'OMAR MEDEL RAYON',empresa:'COCA-COLA FEMSA',lugar:'3er Lugar'},
    {nombre:'DANIEL ÁVILA PÉREZ',empresa:'COCA-COLA FEMSA',lugar:'3er Lugar'},
    {nombre:'VLADIMIR ROQUE BARRERA',empresa:'COCA-COLA FEMSA',lugar:'3er Lugar'},
    {nombre:'MANUEL LORA RAMIREZ',empresa:'COCA-COLA FEMSA',lugar:'3er Lugar'},
    {nombre:'MARCOS RODRIGUEZ BLANCO',empresa:'COCA-COLA FEMSA',lugar:'3er Lugar'},
    {nombre:'CARLOS CASTRO MIDUEÑO',empresa:'COCA-COLA FEMSA',lugar:'3er Lugar'},
    {nombre:'JORGE ARTURO DOMINGUEZ GARCIA',empresa:'COCA-COLA FEMSA',lugar:'3er Lugar'},
    {nombre:'DOMINGO ROMERO TETZOYOLT',empresa:'COCA-COLA FEMSA',lugar:'3er Lugar'},
    {nombre:'EDUARDO LOPEZ SANCHEZ',empresa:'COCA-COLA FEMSA',lugar:'Salón de la fama'},
    {nombre:'ROBERTO LOPEZ GONZALEZ',empresa:'COCA-COLA FEMSA',lugar:'Salón de la fama'},
    {nombre:'OSCAR ENRIQUE PADILLA ORDOÑEZ',empresa:'COCA-COLA FEMSA',lugar:'Salón de la fama'},
    {nombre:'DAVID SOLIS TORRES',empresa:'COCA-COLA FEMSA',lugar:'Salón de la fama'},
    {nombre:'EFREN HERNANDEZ SILVAN',empresa:'COCA-COLA FEMSA',lugar:'Salón de la fama'},
    {nombre:'HERIBERTO GÓMEZ MARTINEZ',empresa:'COCA-COLA FEMSA',lugar:'Salón de la fama'},
    {nombre:'FRANCISCO JAVIER VIEYRA GALVEZ',empresa:'COCA-COLA FEMSA',lugar:'Salón de la fama'},
    {nombre:'RAFAEL ALARCÓN MARTINEZ',empresa:'COCA-COLA FEMSA',lugar:'Salón de la fama'},
    {nombre:'SERGIO EVERARDO GARCIA CANTU',empresa:'COCA-COLA FEMSA',lugar:'Salón de la fama'},
    {nombre:'JOSE EDUARDO REYES HERNANDEZ',empresa:'GRUPO LALA',lugar:'1er Lugar'},
    {nombre:'FABIAN PEDROZA RIVAS',empresa:'GRUPO LALA',lugar:'1er Lugar'},
    {nombre:'GERARDO DIAZ ARCEA',empresa:'GRUPO LALA',lugar:'1er Lugar'},
    {nombre:'LUIS ENRIQUE MAGALLANES REYES',empresa:'GRUPO LALA',lugar:'1er Lugar'},
    {nombre:'JOSE HOMERO CASTRO MIRELES',empresa:'GRUPO LALA',lugar:'1er Lugar'},
    {nombre:'JOSE LUIS RODRIGUEZ PICAZO',empresa:'GRUPO LALA',lugar:'1er Lugar'},
    {nombre:'ARMANDO MORENO ALVARADO',empresa:'GRUPO LALA',lugar:'1er Lugar'},
    {nombre:'JORGE EDUARDO VILLALOBOS VENTURA',empresa:'GRUPO LALA',lugar:'1er Lugar'},
    {nombre:'LORENZO CARRANZA VALENZUELA',empresa:'GRUPO LALA',lugar:'1er Lugar'},
    {nombre:'MARTIN TAPIA MARTINEZ',empresa:'GRUPO LALA',lugar:'1er Lugar'},
    {nombre:'FRANCISCO JAVIER ALVAREZ RAMIREZ',empresa:'GRUPO LALA',lugar:'2do Lugar'},
    {nombre:'MANUEL DE JESUS REYES ACOSTA',empresa:'GRUPO LALA',lugar:'2do Lugar'},
    {nombre:'ANSELMO GRANADOS GARCIA',empresa:'GRUPO LALA',lugar:'3er Lugar'},
    {nombre:'ALBINO BAJANDO RODRIGUEZ',empresa:'GRUPO LALA',lugar:'1er Lugar'},
    {nombre:'ALVARO ENRIQUE CANEPA JIMENEZ',empresa:'GRUPO LALA',lugar:'1er Lugar'},
    {nombre:'CARLOS JESUS MARIN CARRILLO',empresa:'GRUPO LALA',lugar:'1er Lugar'},
    {nombre:'FRANCISCO JAVIER FERRUSCA GOMEZ',empresa:'GRUPO LALA',lugar:'1er Lugar'},
    {nombre:'GAMALIEL CORTAZAR VARGAS',empresa:'GRUPO LALA',lugar:'1er Lugar'},
    {nombre:'JESUS ALFREDO MORALES LORENZO',empresa:'GRUPO LALA',lugar:'1er Lugar'},
    {nombre:'JOSE CARLOS CIGARROA PEREZ',empresa:'GRUPO LALA',lugar:'1er Lugar'},
    {nombre:'ROLI ZENTENO AVENDAÑO',empresa:'GRUPO LALA',lugar:'1er Lugar'},
    {nombre:'ELIUD ARELLANO AVALOS',empresa:'GRUPO LALA',lugar:'1er Lugar'},
    {nombre:'JOVANY DEL CARMEN SANCHEZ VERA',empresa:'GRUPO LALA',lugar:'1er Lugar'},
    {nombre:'ALEJANDRO CERVANTES PEREZ',empresa:'GRUPO LALA',lugar:'1er Lugar'},
    {nombre:'CARLOS CHAVEZ VARGAS',empresa:'GRUPO LALA',lugar:'1er Lugar'},
    {nombre:'CARLOS ALFREDO RODRIGUEZ RODRIGUEZ',empresa:'GRUPO LALA',lugar:'1er Lugar'},
    {nombre:'PARMENIDES GALVAN CASTILLO',empresa:'GRUPO LALA',lugar:'1er Lugar'},
    {nombre:'ALBERTO GUTIERREZ PALACIOS',empresa:'GRUPO LALA',lugar:'1er Lugar'},
    {nombre:'ANTONIO GENAREZ SANTIAGO',empresa:'GRUPO LALA',lugar:'1er Lugar'},
    {nombre:'DAGOBERTO RODRIGUEZ GONZALEZ',empresa:'GRUPO LALA',lugar:'1er Lugar'},
    {nombre:'GUILLERMO VILLEGAS SALDIVAR',empresa:'GRUPO LALA',lugar:'1er Lugar'},
    {nombre:'JESUS EMMANUEL MARTINEZ ALONSO',empresa:'GRUPO LALA',lugar:'1er Lugar'},
    {nombre:'JOSE ANGEL RAMIREZ DEL TORO',empresa:'GRUPO LALA',lugar:'1er Lugar'},
    {nombre:'JOSE LUIS BENITO ALVAREZ',empresa:'GRUPO LALA',lugar:'1er Lugar'},
    {nombre:'OMAR CARREON SANCHEZ',empresa:'GRUPO LALA',lugar:'1er Lugar'},
    {nombre:'ARMANDO BARRIOS RUIZ',empresa:'GRUPO LALA',lugar:'1er Lugar'},
    {nombre:'DAVID PANIAGUA ALVAREZ',empresa:'GRUPO LALA',lugar:'1er Lugar'},
    {nombre:'JUAN CARLOS VAZQUEZ FERNANDEZ',empresa:'GRUPO LALA',lugar:'1er Lugar'},
    {nombre:'ZEFERINO CIGARROA RODRIGUEZ',empresa:'GRUPO LALA',lugar:'1er Lugar'},
    {nombre:'ABEL MEZA LIZARRAGA',empresa:'GRUPO LALA',lugar:'2do Lugar'},
    {nombre:'GERARDO LIRA HERNANDEZ',empresa:'GRUPO LALA',lugar:'2do Lugar'},
    {nombre:'JOSE ALEJANDRO VILLEGAS ORTIGOZA',empresa:'GRUPO LALA',lugar:'2do Lugar'},
    {nombre:'ROLANDO MALDONADO GALLEGOS',empresa:'GRUPO LALA',lugar:'2do Lugar'},
    {nombre:'ARTURO TENORIO PAEZ',empresa:'GRUPO LALA',lugar:'2do Lugar'},
    {nombre:'HECTOR FRANCISCO ROA SANTOS',empresa:'GRUPO LALA',lugar:'2do Lugar'},
    {nombre:'MARIO CESAR BRISEÑO GUZMAN',empresa:'GRUPO LALA',lugar:'2do Lugar'},
    {nombre:'OSVALDO ENRIQUE SANTIAGO PEREZ',empresa:'GRUPO LALA',lugar:'2do Lugar'},
    {nombre:'DANIEL PEDROZA TORRES',empresa:'GRUPO LALA',lugar:'2do Lugar'},
    {nombre:'DANIEL MORAN IRETA',empresa:'GRUPO LALA',lugar:'2do Lugar'},
    {nombre:'JOSE ALBERTO MUÑOZ HERNANDEZ',empresa:'GRUPO LALA',lugar:'2do Lugar'},
    {nombre:'NICOLAS JAVIER SANCHEZ ROSANO',empresa:'GRUPO LALA',lugar:'2do Lugar'},
    {nombre:'EDUARDO ENRIQUE MENDOZA TRINIDAD',empresa:'GRUPO LALA',lugar:'2do Lugar'},
    {nombre:'JOSE LUIS SANCHEZ SOLIS',empresa:'GRUPO LALA',lugar:'2do Lugar'},
    {nombre:'MIGUEL ANGEL CASTOR MARTINEZ',empresa:'GRUPO LALA',lugar:'2do Lugar'},
    {nombre:'ROBERTO RUBEN DE LA CRUZ MARTINEZ',empresa:'GRUPO LALA',lugar:'2do Lugar'},
    {nombre:'DAVID GARZON ORTEGA',empresa:'GRUPO LALA',lugar:'2do Lugar'},
    {nombre:'GUILLERMO OCHOA PEREZ',empresa:'GRUPO LALA',lugar:'2do Lugar'},
    {nombre:'NESTOR NAFARRATE COTA',empresa:'GRUPO LALA',lugar:'2do Lugar'},
    {nombre:'RAMON HERNANDEZ ALVARADO',empresa:'GRUPO LALA',lugar:'2do Lugar'},
    {nombre:'ROGELIO GONZALEZ ROJAS',empresa:'GRUPO LALA',lugar:'2do Lugar'},
    {nombre:'EDUARDO ESPINOSA MENDEZ',empresa:'GRUPO LALA',lugar:'3er Lugar'},
    {nombre:'FRANCISCO JAVIER RODRIGUEZ DIAZ',empresa:'GRUPO LALA',lugar:'3er Lugar'},
    {nombre:'HUBER PEREZ MAYO',empresa:'GRUPO LALA',lugar:'3er Lugar'},
    {nombre:'NESTOR EDUARDO PICHARDO REYES',empresa:'GRUPO LALA',lugar:'3er Lugar'},
    {nombre:'OTHON CORONA ACOSTA',empresa:'GRUPO LALA',lugar:'3er Lugar'},
    {nombre:'JESUS ALBERTO TOVAR AGUIRRE',empresa:'GRUPO LALA',lugar:'3er Lugar'},
    {nombre:'JOSE GERARDO GONZALEZ TANORI',empresa:'GRUPO LALA',lugar:'3er Lugar'},
    {nombre:'MANUEL CARRION DE LA CRUZ',empresa:'GRUPO LALA',lugar:'3er Lugar'},
    {nombre:'OCTAVIO SAMPEDRO RAMIREZ',empresa:'GRUPO LALA',lugar:'3er Lugar'},
    {nombre:'ALONSO ISRAEL SERRANO GUZMAN',empresa:'GRUPO LALA',lugar:'3er Lugar'},
    {nombre:'ARMANDO FLORES VARA',empresa:'GRUPO LALA',lugar:'3er Lugar'},
    {nombre:'EDER OSVALDO HERNANDEZ TREJO',empresa:'GRUPO LALA',lugar:'3er Lugar'},
    {nombre:'EDUARDO HERNANDEZ CRUZ',empresa:'GRUPO LALA',lugar:'3er Lugar'},
    {nombre:'JESUS OSUNA REYES',empresa:'GRUPO LALA',lugar:'3er Lugar'},
    {nombre:'JESUS ANTONIO ALMAZAN ZUÑIGA',empresa:'GRUPO LALA',lugar:'3er Lugar'},
    {nombre:'JOSE ANGEL NAVA DIAZ',empresa:'GRUPO LALA',lugar:'3er Lugar'},
    {nombre:'NICOLAS DE LA CRUZ GUZMAN',empresa:'GRUPO LALA',lugar:'3er Lugar'},
    {nombre:'PATRICIO NIETO CARRASCO',empresa:'GRUPO LALA',lugar:'3er Lugar'},
    {nombre:'JORGE ESPARZA ROJAS',empresa:'GRUPO LALA',lugar:'3er Lugar'},
    {nombre:'LUIS FERNANDO MAGAÑA OLIVARES',empresa:'GRUPO LALA',lugar:'3er Lugar'},
    {nombre:'NANCY GARZA TORRES',empresa:'GRUPO LALA',lugar:'3er Lugar'},
    {nombre:'RAMIRO GUERRERO DIAZ',empresa:'GRUPO LALA',lugar:'3er Lugar'},
    {nombre:'RICARDO SALVADOR RODRIGUEZ ESTRADA',empresa:'GRUPO LALA',lugar:'3er Lugar'},
    {nombre:'ANGEL AGUILAR LOPEZ',empresa:'GRUPO LALA',lugar:'3er Lugar'},
    {nombre:'CHRISTIAN GEOVANNI HERNANDEZ FLORES',empresa:'GRUPO LALA',lugar:'3er Lugar'},
    {nombre:'RIGOBERTO LARA FERREYRA',empresa:'GRUPO LALA',lugar:'3er Lugar'},
    {nombre:'ALEXIS VALENCIA OSEGUERA',empresa:'GRUPO LALA',lugar:'3er Lugar'},
    {nombre:'JUAN SERRATO GUZMAN',empresa:'GRUPO LALA',lugar:'3er Lugar'},
    {nombre:'FRANCISCO JAVIER CISNEROS RODRIGUEZ',empresa:'COMERCIALIZADORA ELORO   (JUMEX)  ',lugar:'2do Lugar'},
    {nombre:'JOSE RAFAEL MAGALLANES ROSALES',empresa:'COMERCIALIZADORA ELORO   (JUMEX)  ',lugar:'3er Lugar'},
    {nombre:'CARLOS RODRIGUEZ JAVIER',empresa:'COMERCIALIZADORA ELORO   (JUMEX)  ',lugar:'3er Lugar'},
    {nombre:'JORGE ALBERTO KU PECH',empresa:'COMERCIALIZADORA ELORO   (JUMEX)  ',lugar:'3er Lugar'},
    {nombre:'HUGO SPENCER ZUÑIGA TORRES',empresa:'COMERCIALIZADORA ELORO   (JUMEX)  ',lugar:'3er Lugar'},
    {nombre:'HECTOR CID HERNANDEZ',empresa:'SOLISTICA',lugar:'1er Lugar'},
    {nombre:'DAMACIO TAVARES MENDOZA',empresa:'SOLISTICA',lugar:'1er Lugar'},
    {nombre:'CHARBEL GONZALEZ RIVERA',empresa:'SOLISTICA',lugar:'1er Lugar'},
    {nombre:'CESAR OMAR ANDRADE NAVA',empresa:'SOLISTICA',lugar:'1er Lugar'},
    {nombre:'HECTOR FERNANDO CABRERA BARRAGAN',empresa:'SOLISTICA',lugar:'1er Lugar'},
    {nombre:'LEOBARDO LEZAMA LEZAMA',empresa:'SOLISTICA',lugar:'1er Lugar'},
    {nombre:'NOE SANCHEZ FLORES',empresa:'SOLISTICA',lugar:'1er Lugar'},
    {nombre:'ALBERTO LAZCANO HERNANDEZ',empresa:'SOLISTICA',lugar:'1er Lugar'},
    {nombre:'MIGUEL ANGEL GONZALEZ CONTRERAS',empresa:'SOLISTICA',lugar:'1er Lugar'},
    {nombre:'ALEJANDRO JARAMILLO CAMACHO',empresa:'SOLISTICA',lugar:'1er Lugar'},
    {nombre:'JAIME MORGADO ESTRADA',empresa:'SOLISTICA',lugar:'1er Lugar'},
    {nombre:'HEBERTO SALOMON ZAZUETA',empresa:'SOLISTICA',lugar:'1er Lugar'},
    {nombre:'JOSUE ROSALIANO RODRIGUEZ TENCHIPE',empresa:'SOLISTICA',lugar:'1er Lugar'},
    {nombre:'RICARDO NABOR HUERTA PEREZ',empresa:'SOLISTICA',lugar:'1er Lugar'},
    {nombre:'JOSE NAZARIO RIOS CAÑEZ',empresa:'SOLISTICA',lugar:'1er Lugar'},
    {nombre:'SERGIO ELIUD ALVAREZ RODRIGUEZ',empresa:'SOLISTICA',lugar:'2do Lugar'},
    {nombre:'LUIS DANIEL QUEZADA HERNANDEZ',empresa:'SOLISTICA',lugar:'2do Lugar'},
    {nombre:'ULISES ALEJANDRO MARIN RODRIGUEZ',empresa:'SOLISTICA',lugar:'2do Lugar'},
    {nombre:'FRANCISCO GARCIA BARAJAS',empresa:'SOLISTICA',lugar:'2do Lugar'},
    {nombre:'JUAN FRANCISCO FABIAN SANCHEZ',empresa:'SOLISTICA',lugar:'2do Lugar'},
    {nombre:'ELISEO GARCIA HERRERA',empresa:'SOLISTICA',lugar:'2do Lugar'},
    {nombre:'MIGUEL ANGEL PALESTINO MARTINEZ',empresa:'SOLISTICA',lugar:'2do Lugar'},
    {nombre:'BERNARDO VERDUZCO ALARCON',empresa:'SOLISTICA',lugar:'2do Lugar'},
    {nombre:'JOSE MARTIN GOMEZ FLORES',empresa:'SOLISTICA',lugar:'2do Lugar'},
    {nombre:'MANUEL SAMANO NAVA',empresa:'SOLISTICA',lugar:'2do Lugar'},
    {nombre:'ABEL OCHOA RAMIREZ',empresa:'SOLISTICA',lugar:'2do Lugar'},
    {nombre:'J TRINIDAD OROZCO HERNANDEZ',empresa:'SOLISTICA',lugar:'2do Lugar'},
    {nombre:'JESUS BALTAZAR PACHECO MORALES',empresa:'SOLISTICA',lugar:'2do Lugar'},
    {nombre:'ALONSO CARRERA MARIN',empresa:'SOLISTICA',lugar:'2do Lugar'},
    {nombre:'JUANJOSE JIMENEZ ESCOBAR',empresa:'SOLISTICA',lugar:'3er Lugar'},
    {nombre:'JUAN FRANCISCO VILLA SANCHEZ',empresa:'SOLISTICA',lugar:'3er Lugar'},
    {nombre:'JORGE ANTONIO GOMEZ OSORIO',empresa:'SOLISTICA',lugar:'3er Lugar'},
    {nombre:'JOSE RODRIGO MARTINEZ ATZOMPA',empresa:'SOLISTICA',lugar:'Salón de la fama'},
    {nombre:'EDUARDO RAUL MARTINEZ HERNANDEZ',empresa:'SOLISTICA',lugar:'Salón de la fama'},
    {nombre:'ELOY MIGUEL CALDERON ESPINOZA',empresa:'SOLISTICA',lugar:'Salón de la fama'},
    {nombre:'JUAN MANUEL ESPINOZA NAVA',empresa:'SOLISTICA',lugar:'Salón de la fama'},
    {nombre:'JUAN CARLOS MARTINEZ GARCIA',empresa:'DISTRIBUIDORA ARCA CONTINENTAL',lugar:'2do Lugar'},
    {nombre:'ISMAEL ZERMEÑO REYES',empresa:'DISTRIBUIDORA ARCA CONTINENTAL',lugar:'2do Lugar'},
    {nombre:'JORGE LUIS CERVANTES .',empresa:'DISTRIBUIDORA ARCA CONTINENTAL',lugar:'3er Lugar'},
    {nombre:'RAFAEL MARQUEZ GUTIERREZ',empresa:'DISTRIBUIDORA ARCA CONTINENTAL',lugar:'3er Lugar'},
    {nombre:'JOSE ANGEL LOPEZ PADILLA',empresa:'DISTRIBUIDORA ARCA CONTINENTAL',lugar:'3er Lugar'},
    {nombre:'DIEGO ARMANDO SANCHEZ MARTINEZ',empresa:'GRUPO RICA',lugar:'2do Lugar'},
    {nombre:'TOMAS GEOVANNI RAMOS OSORIO',empresa:'GRUPO RICA',lugar:'3er Lugar'},
    {nombre:'JOSE CARMEN CHAVEZ HERNANDEZ',empresa:'GRUPO RICA',lugar:'3er Lugar'},
    {nombre:'MARTIN MORALES SANCHEZ',empresa:'GRUPO RICA',lugar:'3er Lugar'},
    {nombre:'MAURILIO MENDEZ MORALES',empresa:'EMBOTELLADORAS BEPENSA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'GUILLERMO KU COLLI',empresa:'EMBOTELLADORAS BEPENSA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'WILBERT CHAN AYALA',empresa:'EMBOTELLADORAS BEPENSA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'ANGEL EFRAIN MORALES ALARCON',empresa:'EMBOTELLADORAS BEPENSA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'FELIPE RAMOS PECH CHIN',empresa:'EMBOTELLADORAS BEPENSA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'AGUSTIN VAZQUEZ ARCOS',empresa:'EMBOTELLADORAS BEPENSA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'ALEJANDRO GUZMAN MORALES',empresa:'EMBOTELLADORAS BEPENSA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'ALBERTO EMETERIO KEB PECH',empresa:'EMBOTELLADORAS BEPENSA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'FRANCISCO JAVIER AHUMADA NAVARRETE',empresa:'EMBOTELLADORAS BEPENSA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'MIGUEL ANGEL CANTO SANCHEZ',empresa:'EMBOTELLADORAS BEPENSA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'REYES DZUL YEE',empresa:'EMBOTELLADORAS BEPENSA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'GASPAR FRANCISCO KANTUN CISNEROS',empresa:'EMBOTELLADORAS BEPENSA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'OMAR RAYMUNDO TORRES MARTIN',empresa:'EMBOTELLADORAS BEPENSA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'SANTOS MATA CHIMEO',empresa:'EMBOTELLADORAS BEPENSA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE LORENZO EK ITZA',empresa:'EMBOTELLADORAS BEPENSA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'LEONEL MORENO ZAPATA',empresa:'EMBOTELLADORAS BEPENSA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'FILIBERTO COHUO EK',empresa:'EMBOTELLADORAS BEPENSA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'DAN LEOBAD EK AGUILAR',empresa:'EMBOTELLADORAS BEPENSA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'DAVID RAMIREZ CERDA',empresa:'EMBOTELLADORAS BEPENSA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JORGE ANTONIO CANUL CHUC',empresa:'EMBOTELLADORAS BEPENSA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'AGUSTIN GABRIEL DZUL PUCH',empresa:'EMBOTELLADORAS BEPENSA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'HECTOR GARCIA SUAREZ',empresa:'EMBOTELLADORAS BEPENSA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'EZEQUIEL AGUILAR MACEDO',empresa:'EMBOTELLADORAS BEPENSA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'LUIS FERNANDO CEME CAN',empresa:'EMBOTELLADORAS BEPENSA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'GASPAR TUN CHAN',empresa:'EMBOTELLADORAS BEPENSA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'IVAN PEREZ BAÑOS',empresa:'EMBOTELLADORAS BEPENSA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'DAVID ISRAEL LOPEZ MOGUEL',empresa:'EMBOTELLADORAS BEPENSA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE ALBERTO HERNANDEZ COLLI',empresa:'EMBOTELLADORAS BEPENSA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'MANUEL ALFONSO DAPILLETA YERBES',empresa:'EMBOTELLADORAS BEPENSA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'RUDY CARLOS LOPEZ OXTE',empresa:'EMBOTELLADORAS BEPENSA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'HUGO AARON MANZANO PÉREZ',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'SALVADOR CRUZ HERNANDEZ',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JORGE HUGO FLORES PICHARDO',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE NORBERTO ESPERANZA CASTILLO',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'ANGEL EUGENIO DUARTE MARTINEZ',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'MARTIN NAVARRO GARCIA',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE LUIS HERNANDEZ -',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE ANTONIO HINOJOS MEJIA',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'BERNARDO CASTILLO MORALES',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'ALEJANDRO MEZA TREJO',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'JORGE ARMANDO VAZQUEZ AQUINO',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'MIGUEL ADRIAN APANCO ALCANTARA',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'ERNESTO PEÑAFIEL YAÑEZ',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'BULMARO SERRANO LOPEZ',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'JORGE SARRAZAGA CANDELERO',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'ENRIQUE BAUTISTA SANTOS',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'ANDRES AGAPITO AGUIRRE BLANCAS',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'CLAUDIA MARQUEZ MENDEZ',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'SAMUEL GOMEZ JUAREZ',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'RAUL AVITIA MARTINEZ',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'JOSE LUIS SALAZAR RAMIREZ',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'JULIAN GAMEZ ESQUIVEL',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'ANGEL EDUARDO MEJIA LOPEZ',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'JOSE ARTURO TORRES PÉREZ',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'JUAN DANIEL PACHECO JIMENEZ',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'JOSE RODRIGUEZ LOPEZ',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'CECILIO ENRIQUE NATARED CORDOVA',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'RICARDO LEON ZARATE',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'JUAN LUIS DORANTES CANO',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'DAVID CARRIZALEZ GAITAN',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'EMILIO REYES GOMEZ',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'JESUS ALBERTO ROBLES SAINZ',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'MARIO ALBERTO JIMENEZ GARCIA',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'MANUEL VAZQUEZ ESPARZA',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'GENARO PEREZ GOMEZ',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'LUIS ALBERTO AREVALO ORTIZ',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'HUGO CESAR VALENZUELA NAVARRO',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'MARCOS ABAD RODRIGUEZ SILVA',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'JAVIER LEOBARDO BEJINES ACEVES',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'JESUS ANTONIO BORUNDA BERRELEZA',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'ARMANDO SAAVEDRA HERNANDEZ',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'JUAN CARLOS OLGUIN HERNANDEZ',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'EDUARDO EMMANUEL IBARRA GONSALES',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'EDGAR GUADALUPE MIRANDA MANZANO',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'JORGE ALBINO BAUTISTA FLORES',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'JOSE FRANCISCO NORIEGA RAMOS',empresa:'ESTAFETA MEXICANA S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'RAFAEL ALBERTO CALDERA NUÑEZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'ANTONIO MERCADO GAMERO',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'RAFAEL PASCASIO HERNANDEZ LEON',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JUAN CARLOS ROMERO ANDONAEGUI',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JORGE NARVAEZ ALCANTARA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'NORBERTO GARFIAS GARCIA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'ERIC EMMANUEL TORRES CAMACHO',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'ANTONIO CORDERO YAÑEZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JORGE ARTURO HERNANDEZ PERIANES',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'EMMANUEL BARUC REYES GONZALEZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'ANDREZ ACOSTA RAMIREZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'MARTIN HERNANDEZ LEON',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE ANTONIO DIAZ MORALES',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'VICTOR MEJIA GALARZA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'OSCAR CRUZ LUBIANO',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'CARLOS LAGUNAS HUITRON',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'FERNANDO GAMALIEL CANALES GARCIA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'ADAN VILLASEÑOR HERNANDEZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'GABRIEL ANTONIO AMADOR ZARCO',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'FREDY MARTINEZ HERNANDEZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'MANUEL PINEDA HERNANDEZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE GUADALUPE TIRO ALVAREZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'ALEJANDRO RIVERO LOPEZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'LORENZO MORALES ROBLES',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'FERNANDO VILLAFAÑA PEREZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'ARTURO RANGEL HERNANDEZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'DAVID SANCHEZ CRUZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE MANUEL HERNANDEZ SIXTOS',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE CESAR ROLDAN BLANCAS',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'DANIEL EFRAIN BALDERAS RAMIREZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'DAVID ALBERTO GARCIA DURAN',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'HIFRI HERTZ HERNANDEZ SOTO',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'PABLO RIVERO DURAN',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'MAURICIO CIRO SANDOVAL IBARRA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'GEORGE HENRY LAWTON CORTEZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE ROBERTO HERNANDEZ PERIANES',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'VICTOR ALFONSO CALDERON CRUZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JUAN ARREOLA CASTAÑEDA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JORGE DIAZ LARA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'ALVARO LEMOINE VAZQUEZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JAIME JIMENEZ REYES',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JESUS ALBERTO ZARAGOZA GONZALEZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'RODRIGO ESPINOSA GALVAN',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'GUSTAVO MARTINEZ TELLO',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'LUIS ENRIQUE GARFIAS RAMIREZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JUAN CARLOS ROJAS HERNANDEZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE ALBERTO MENDEZ MORENO',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE ASUNCION DE LA CRUZ VIDAL',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'MISAEL MADRID GOMEZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'FRANCISCO HERNANDEZ GONZALEZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'IAN DAVID PAREDES GONZALEZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'RICARDO MARTINEZ GARCIA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'CESAR HERNANDEZ URIBE',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JUAN CARLOS AMADOR MARTINEZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'DAVID TELLEZ CALLEJAS',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'AGUSTIN ARREGUIN ALMANZA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JUAN PABLO HURTADO ANGULO',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'GERARDO PADILLA NOYA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'ALFONSO BARBA UGALDE',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'RAMON DAVID CAMPOY RUBALCAVA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'MARTIN ARAUJO HERRERA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JUAN PABLO ESPARZA VELASCO',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'FRANCISCO JAVIER LOPEZ PERALTA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'RAFAEL VELASCO VIZUET',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JUAN CARLOS FRIAS PANTOJA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JORGE RAMIREZ BUENROSTRO',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE LUIS MARTINEZ TELLO',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'FRANCISCO VAZQUEZ RAMIREZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'FRANCISCO ROMO ANGEL',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JACINTO ZUÑIGA ANDRADE',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'J GUADALUPE GARCIA CRUZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'ALFREDO LOPEZ TORRES',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE LUIS HERNANDEZ SORIA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JESUS GABRIEL HERNANDEZ GARCIA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE MANUEL HERNANDEZ LEON',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'EDGAR MOLINERO SERRANO',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'BRYAN BARAJAS HERNANDEZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'HERIBERTO BUENDIA SILVESTRE',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'EDUARDO DAMIAN OCHOA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE REFUGIO GARCIA MARTINEZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'OSCAR RAMON CASTRO TRIGUEROS',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'IVAN TIRO ALVARES',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'GABRIEL REYES BALDERAS',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE ALBERTO SERRANO CASAS',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'MAURILIO MALDONADO CASTILLO',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'RUBEN ANTONIO MARTINEZ MORENO',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'LUIS ENRIQUE RODRIGUEZ PEREZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'ERLING DE PAZ ESPINOSA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'ARMANDO EFREN TARANGO CHAVIRA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JOE ABRAHAM MADRID SANCHEZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE MARTIN GAMERO MARTINEZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'VICENTE GUZMAN FRANCISCO',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'BENITO BOLAÑOS ALCANTARA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'DANIEL LEONARDO MONTER PEREZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JENRIZ CELEDONIO GUTIERREZ RAMIREZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'NICOLAS EFRAIN GARCIA ARELLANO',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'VICTOR MANUEL HERNANDEZ MADRID',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'EDGAR MUÑOZ ARELLANO',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'J JESUS SEVILLA CUEVAS',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JUAN OLVERA GUERRERO',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'GUILLERMO MALDONADO CASTILLO',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'RICARDO JIMENEZ PALACIOS',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JUAN HERNANDEZ ALAMILLA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'ALBERTO PINEDA ZAVALA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JUAN PABLO HERNANDEZ ALVARADO',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'LEONARDO LOPEZ PADILLA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JUAN ERICK FLORES FIGUEROA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'SERGIO LOPEZ GAMERO',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'EDGAR MARTIN MARTINEZ NIEBLA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE ALBERTO MAYA CONTRERAS',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'MIGUEL ANGEL REJINO JIMENEZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JORGE JONATHAN ANGELES GUZMAN',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'LUIS CARLOS MORENO DE LA TORRE',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'LUIS ALBERTO BLAS SUAREZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JESUS MANUEL ARCE CASTRO',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'CARLOS ARMANDO MADERO LOPEZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'ANGEL AVILA AVILA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'FERNANDO GONZALEZ GOMEZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'ISMAEL GARCIA GONZALEZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JONATHAN SIERRA RODRIGUEZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'OSCAR BUENDIA SILVESTRE',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE DOLORES MARTINEZ PINEDA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'ALEJANDRO OJEDA SALINAS',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'ABRAHAM TREJO HERNANDEZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'MAXIMO ANDRES RODRIGUEZ CORTAZAR',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'EDGAR SANTILLAN MIJANGOS',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'JHONATAN RAMIREZ GARCIA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'ROBERTO EDGAR GUZMAN LOPEZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'HUMBERTO FERNANDEZ ARREOLA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'MARTIN JIMENEZ HERNANDEZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'JOSE LUIS SOLIS LUVIANO',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'FRANCISCO JOSE FLORES VALENCIA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'MARIO IVAN PLASCENCIA RAMIREZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'ARMANDO DANIEL RAMOS LEON',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'ADRIAN MORENO AGUILAR',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'ANGEL EDUARDO SEVILLA DIAZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'NELSON CONTRERAS MARTINEZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'ARTURO PLASCENCIA RAMIREZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'ANDRES SORIA MORA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'NOE ALBERTO ARCEGA MELENDEZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'ADRIAN NERI CASTRO',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'GUILLERMO BECERRIL RUELAS',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'ARMANDO CAMACHO SILVA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'VICENTE OROZCO SANCHEZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'JOSE LUIS VAZQUEZ CARDENAS',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'OMAR GALEOTE VALENCIA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'ERICK ARMANDO AGUILAR MATA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'JOSE DAVID ESTRELLA ESTRELLA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'ANTONIO IZQUIERDO SERRANO',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'HECTOR GERARDO LOPEZ TARANGO',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'KEVIN ORLANDO GONZALEZ VENTURA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'AARON ENRIQUE LOPEZ AYALA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'ANGEL CRUZ MATA GONZALEZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'YASSER ANGEL NAJERA RUIZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'ALFONSO BARRIENTOS PEREZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'MOISES EDUARDO CERVANTES SORIA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'ISRAEL ORTIZ MARTINEZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'EDGAR JOSUE RAMOS GARCIA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'MIGUEL GALICIA CANALES',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'ALEJANDRO GONZALEZ RUIZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'ANTONIO DE JESUS VILLEDA ALMARAZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'JUAN JOSE GARCIA CRUZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'LUIS CASTILLO MORENO',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'GUSTAVO HERNANDEZ ARTEAGA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'EUSTORGIO VITE LARA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'IGNACIO CABRERA BUENROSTRO',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'URIEL JIMENEZ CORTAZAR',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'VICTOR MANUEL REYNOSO DIAZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'LUIS AUDOCIO AVILA MALDONADO',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'SERGIO PEREZ SANCHEZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'MIGUEL ANGEL VITE GARCIA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'ADRIAN ESPINOZA FRANCO',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'SILVERIO VARGAS PEREZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'VICTOR PERETE VALENCIA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'HUGO ISRAEL DELGADO SORIA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'GILBERTO JIMENEZ GARCIA',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'JOSE ANTONIO DE JESUS MOGUEL TUZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'LUIS MANUEL MENDEZ PARAMO',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'JOSUE ALEJANDRO CORDOVA HERNANDEZ',empresa:'FÁBRICA DE JABÓN LA CORONA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'MIGUEL ANGEL EMETERIO NORIEGA',empresa:'FLETES HESA, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'LEONARDO ARCOS MARTINEZ',empresa:'FLETES HESA, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'BERNARDO HERRERA RODRIGUEZ',empresa:'FLETES HESA, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'BARUCH REYES RAMIREZ',empresa:'FLETES HESA, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'MIGUEL ANGEL GONZALEZ PAREDES',empresa:'FLETES HESA, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JOSÉ MISAEL MARIA VASQUEZ',empresa:'FLETES HESA, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'ISMAEL MARTINEZ LAZCANO',empresa:'FLETES HESA, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'AMADO HERNANDEZ MUÑOZ',empresa:'FLETES HESA, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'DONATO MARTINEZ CONTRERAS',empresa:'FLETES HESA, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'ALFREDO RIOS GONZALEZ',empresa:'FLETES HESA, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'ALEJANDRO CASTRO DE LOS SANTOS',empresa:'FLETES HESA, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'IRVING ALEJANDRO GUEVARA MARTINEZ',empresa:'FLETES HESA, S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'SAMUEL ALEJANDRO VALERIO SANCHEZ',empresa:'FLETES HESA, S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'JAVIER FLORES CASTILLO',empresa:'FLETES HESA, S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'JOSE ISRAEL CORTES MORALES',empresa:'FLETES HESA, S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'GABRIEL ANTONIO RODRIGUEZ COGORDAN',empresa:'ZINERGIA',lugar:'1er Lugar'},
    {nombre:'ALDO DE JESÚS BOTELLO MOYA',empresa:'ZINERGIA',lugar:'1er Lugar'},
    {nombre:'FELIX RUBEN GAMBOA PEDROZA',empresa:'ZINERGIA',lugar:'1er Lugar'},
    {nombre:'JORGE ARMANDO TREVIÑO VAZQUEZ',empresa:'ZINERGIA',lugar:'1er Lugar'},
    {nombre:'MILTON TONATIHU RODRIGUEZ COGORDAN',empresa:'ZINERGIA',lugar:'1er Lugar'},
    {nombre:'OCTAVIO PALACIOS RUIZ',empresa:'ZINERGIA',lugar:'2do Lugar'},
    {nombre:'JOVANY PALMA MARTÍNEZ',empresa:'ZINERGIA',lugar:'2do Lugar'},
    {nombre:'EDUARDO GONZALEZ ORTIZ',empresa:'ZINERGIA',lugar:'2do Lugar'},
    {nombre:'OSVALDO LEGARRA OCHOA',empresa:'ZINERGIA',lugar:'3er Lugar'},
    {nombre:'EZEQUIAS CALLES PEREZ',empresa:'ALMEX',lugar:'1er Lugar'},
    {nombre:'JOSE ASCARY GONGORA YAÑEZ',empresa:'ALMEX',lugar:'1er Lugar'},
    {nombre:'FREDY GARDUÑO RIVERA',empresa:'ALMEX',lugar:'1er Lugar'},
    {nombre:'HECTOR CANDELARIA CORONADO',empresa:'ALMEX',lugar:'1er Lugar'},
    {nombre:'JOSE ANGEL VANEGAS SIFUENTES',empresa:'ALMEX',lugar:'1er Lugar'},
    {nombre:'JOSE MANUEL VIELMAS MENA',empresa:'ALMEX',lugar:'2do Lugar'},
    {nombre:'RUBEN ALVA FLORES',empresa:'ALMEX',lugar:'2do Lugar'},
    {nombre:'JOSE MONTIEL GARCIA',empresa:'ALMEX',lugar:'3er Lugar'},
    {nombre:'GUADALUPE GARRIDO GONZALEZ',empresa:'ALMEX',lugar:'3er Lugar'},
    {nombre:'FRANCISCO JAVIER NOYOLA BRAVO',empresa:'ALMEX',lugar:'3er Lugar'},
    {nombre:'GERMAN DOMINGO MARTINEZ',empresa:'ALMEX',lugar:'3er Lugar'},
    {nombre:'MIGUEL ANGEL HERNANDEZ HERNANDEZ',empresa:'ALMEX',lugar:'3er Lugar'},
    {nombre:'JORGE URIEL AGUILAR RANGEL',empresa:'PAQUETEXPRESS',lugar:'1er Lugar'},
    {nombre:'JESUS ALBERTO ONTIVEROS DELGADO',empresa:'PAQUETEXPRESS',lugar:'1er Lugar'},
    {nombre:'HÉCTOR BENJAMÍN IBARRA SÁNCHEZ',empresa:'PAQUETEXPRESS',lugar:'1er Lugar'},
    {nombre:'RAYMUNDO RAFAELOS AMBROCIO',empresa:'PAQUETEXPRESS',lugar:'1er Lugar'},
    {nombre:'JOSE MANUEL HERNANDEZ SOTO',empresa:'PAQUETEXPRESS',lugar:'1er Lugar'},
    {nombre:'SAUL SANCHEZ RUIZ',empresa:'PAQUETEXPRESS',lugar:'1er Lugar'},
    {nombre:'IVÁN CHÁVEZ DÍAZ',empresa:'PAQUETEXPRESS',lugar:'1er Lugar'},
    {nombre:'EFRÉN BENÍTEZ JUÁREZ',empresa:'PAQUETEXPRESS',lugar:'1er Lugar'},
    {nombre:'ERICK NOE CARDENAS FELIX',empresa:'PAQUETEXPRESS',lugar:'1er Lugar'},
    {nombre:'CESAR ALAN LUGO LEYVA',empresa:'PAQUETEXPRESS',lugar:'1er Lugar'},
    {nombre:'MIGUEL ROA MIRANDA',empresa:'PAQUETEXPRESS',lugar:'1er Lugar'},
    {nombre:'FEDÉRICO ZAPATA CORREA',empresa:'PAQUETEXPRESS',lugar:'1er Lugar'},
    {nombre:'JOSE ANTONIO DE LA O DIAZ',empresa:'PAQUETEXPRESS',lugar:'1er Lugar'},
    {nombre:'SERGIO ALBERTO MONTOYA BARRERA',empresa:'PAQUETEXPRESS',lugar:'1er Lugar'},
    {nombre:'SERGIO IVÁN HERNÁNDEZ ROBLES',empresa:'PAQUETEXPRESS',lugar:'1er Lugar'},
    {nombre:'ERASMO ANTONIO CORDOVA RAMIREZ',empresa:'PAQUETEXPRESS',lugar:'2do Lugar'},
    {nombre:'EDGAR IVAN PERALTA GUERRERO',empresa:'PAQUETEXPRESS',lugar:'2do Lugar'},
    {nombre:'REYES ARTURO OTENTO ISLAS',empresa:'PAQUETEXPRESS',lugar:'2do Lugar'},
    {nombre:'CARLOS ALBER GONZALEZ SANCHEZ',empresa:'PAQUETEXPRESS',lugar:'2do Lugar'},
    {nombre:'FELIPE CORTEZ PEREZ',empresa:'PAQUETEXPRESS',lugar:'2do Lugar'},
    {nombre:'CHRISTIAN YOVANI SOTELO ACOSTA',empresa:'PAQUETEXPRESS',lugar:'2do Lugar'},
    {nombre:'AMADO SOBERANES QUINTERO',empresa:'PAQUETEXPRESS',lugar:'2do Lugar'},
    {nombre:'ANTENOR LUNA CASTILLO',empresa:'PAQUETEXPRESS',lugar:'2do Lugar'},
    {nombre:'VÍCTOR MANUEL CARMONA GONZÁLEZ',empresa:'PAQUETEXPRESS',lugar:'2do Lugar'},
    {nombre:'JAIRO HERIBERTO VALENZUELA MARTINEZ',empresa:'PAQUETEXPRESS',lugar:'2do Lugar'},
    {nombre:'MARTIN GUZMAN MARTINEZ',empresa:'PAQUETEXPRESS',lugar:'3er Lugar'},
    {nombre:'OSCAR RODRIGUEZ SANTANA',empresa:'PAQUETEXPRESS',lugar:'3er Lugar'},
    {nombre:'FELICIANO CORVERA ALVAREZ',empresa:'Linde Bulk',lugar:'1er Lugar'},
    {nombre:'ARMANDO EFRAIN DE ANDA CEJA',empresa:'Linde Bulk',lugar:'1er Lugar'},
    {nombre:'ARMANDO PASCUAL SANTILLAN PEREZ',empresa:'Linde Bulk',lugar:'1er Lugar'},
    {nombre:'ROBERTO ADOLFO YAHUACA HERNANDEZ',empresa:'Linde Bulk',lugar:'1er Lugar'},
    {nombre:'EDUARDO GONZALEZ HERNANDEZ',empresa:'Linde Bulk',lugar:'1er Lugar'},
    {nombre:'GERONIMO GAMEZ FLORES',empresa:'Linde Bulk',lugar:'1er Lugar'},
    {nombre:'JOSE HILARIO CARDOSO CASTILLO',empresa:'Linde Bulk',lugar:'1er Lugar'},
    {nombre:'ALDO ROOSEVELT CHAVERO GARZA',empresa:'Linde Bulk',lugar:'1er Lugar'},
    {nombre:'FRANCISCO JAVIER VAZQUEZ MARTINEZ',empresa:'Linde Bulk',lugar:'1er Lugar'},
    {nombre:'GABRIEL CERDA LOPEZ',empresa:'Linde Bulk',lugar:'1er Lugar'},
    {nombre:'JUAN ARMANDO HERNANDEZ CABALLERO',empresa:'Linde Bulk',lugar:'1er Lugar'},
    {nombre:'ARTURO RUDENCIO MORENO',empresa:'Linde Bulk',lugar:'2do Lugar'},
    {nombre:'FERNANDO RUELAS MENDOZA',empresa:'Linde Bulk',lugar:'2do Lugar'},
    {nombre:'JORGE ALBERTO VALADEZ CRUZ',empresa:'Linde Bulk',lugar:'2do Lugar'},
    {nombre:'JOSE MIGUEL RIVERA GRANADOS',empresa:'Linde Bulk',lugar:'2do Lugar'},
    {nombre:'LUIS FERNANDO GARCIA GARCIA',empresa:'Linde Bulk',lugar:'2do Lugar'},
    {nombre:'MATIAS RAFAEL PEREZ PIMENTEL',empresa:'Linde Bulk',lugar:'2do Lugar'},
    {nombre:'JAYRO MARTINEZ NILA',empresa:'Linde Bulk',lugar:'2do Lugar'},
    {nombre:'MARIO ALBERTO GARCIA REVLES',empresa:'Linde Bulk',lugar:'2do Lugar'},
    {nombre:'SHADOW JESE ARELLANO RIVAS',empresa:'Linde Bulk',lugar:'2do Lugar'},
    {nombre:'HERIBERTO MARTINEZ MARTINEZ',empresa:'Linde Bulk',lugar:'2do Lugar'},
    {nombre:'JOSE ANGEL VALLES ECHARTEA',empresa:'Linde Bulk',lugar:'2do Lugar'},
    {nombre:'JULIAN JAVIER SEVERIANO CANTU JEAN',empresa:'Linde Bulk',lugar:'2do Lugar'},
    {nombre:'LUIS LOPEZ CONTRERAS',empresa:'Linde Bulk',lugar:'2do Lugar'},
    {nombre:'MOISES ALEXANDER CANTU JEAN',empresa:'Linde Bulk',lugar:'2do Lugar'},
    {nombre:'GABINO BARRIENTOS POXTAN',empresa:'Linde Bulk',lugar:'2do Lugar'},
    {nombre:'JORE GIOVANNI YERA MARISCAL',empresa:'Linde Bulk',lugar:'2do Lugar'},
    {nombre:'JOSE GUILLERMO FLORES AGUIRRE',empresa:'Linde Bulk',lugar:'2do Lugar'},
    {nombre:'JUAN CARLOS GARCIA SALAZAR',empresa:'Linde Bulk',lugar:'2do Lugar'},
    {nombre:'MANUEL ORTIZ MAYO',empresa:'Linde Bulk',lugar:'2do Lugar'},
    {nombre:'RICARDO HERNANDEZ RUEDA',empresa:'Linde Bulk',lugar:'2do Lugar'},
    {nombre:'ESTEBAN PRIETO GARCIA',empresa:'Linde Bulk',lugar:'2do Lugar'},
    {nombre:'J. SANTOS TORRES RAMIREZ',empresa:'Linde Bulk',lugar:'2do Lugar'},
    {nombre:'NORBERTO VALLE CRISTOBAL',empresa:'Linde Bulk',lugar:'2do Lugar'},
    {nombre:'OSCAR CRUZ MOZO',empresa:'Linde Bulk',lugar:'2do Lugar'},
    {nombre:'NEFTALI GOMEZ JIMENEZ',empresa:'Linde Bulk',lugar:'3er Lugar'},
    {nombre:'JOSE ELADIO CANIZALEZ SALAZAR',empresa:'Linde Bulk',lugar:'3er Lugar'},
    {nombre:'GUSTAVO AGUILAR ACOSTA',empresa:'Linde Bulk',lugar:'3er Lugar'},
    {nombre:'ALEJANDRO GUZMAN TORRES',empresa:'Linde PGP',lugar:'1er Lugar'},
    {nombre:'DANY NATANAEL LOPEZ PEREZ',empresa:'Linde PGP',lugar:'1er Lugar'},
    {nombre:'LUIS GERARDO ALVAREZ ULATE',empresa:'Linde PGP',lugar:'1er Lugar'},
    {nombre:'SILVESTRE CARRASCO GONZALEZ',empresa:'Linde PGP',lugar:'1er Lugar'},
    {nombre:'ALBERTO RODRIGUEZ COLORADO',empresa:'Linde PGP',lugar:'1er Lugar'},
    {nombre:'EDGAR CASTRO BUSTAMANTE',empresa:'Linde PGP',lugar:'1er Lugar'},
    {nombre:'FRANCISCO JAVIER MUÑOZ VALADEZ',empresa:'Linde PGP',lugar:'1er Lugar'},
    {nombre:'CARLOS ISIDRO OSORIO VENTURA',empresa:'Linde PGP',lugar:'2do Lugar'},
    {nombre:'GUILLERMO ALVAREZ RIVERA',empresa:'Linde PGP',lugar:'2do Lugar'},
    {nombre:'JULIO CESAR RUIZ GUZMAN',empresa:'Linde PGP',lugar:'2do Lugar'},
    {nombre:'DANIEL MENDIATE DIAZ',empresa:'Linde PGP',lugar:'2do Lugar'},
    {nombre:'ARMANDO RODRIGUEZ AGUIRRE',empresa:'Linde PGP',lugar:'2do Lugar'},
    {nombre:'DANIEL LEON SANCHEZ',empresa:'Linde PGP',lugar:'2do Lugar'},
    {nombre:'JOB RODRIGUEZ GOMEZ',empresa:'Linde PGP',lugar:'2do Lugar'},
    {nombre:'JOSE DE JESUS ZAVALA NUÑEZ',empresa:'Linde PGP',lugar:'3er Lugar'},
    {nombre:'JOSE LUIS QUIÑONES ALFEREZ',empresa:'Linde PGP',lugar:'3er Lugar'},
    {nombre:'BENJAMIN TRIGUEROS MARTINEZ',empresa:'Linde PGP',lugar:'3er Lugar'},
    {nombre:'MIGUEL ALEJANDRO AVILA PORTILLO',empresa:'CADENA COMERCIAL OXXO',lugar:'1er Lugar'},
    {nombre:'JAIR ARMANDO ESPINOZA GANDARA',empresa:'CADENA COMERCIAL OXXO',lugar:'1er Lugar'},
    {nombre:'CIRO ARCOS LAZARO',empresa:'CADENA COMERCIAL OXXO',lugar:'1er Lugar'},
    {nombre:'EDWIN MIZRAIN PUC ARANA',empresa:'CADENA COMERCIAL OXXO',lugar:'1er Lugar'},
    {nombre:'MANUEL ISMAEL POOT MAY',empresa:'CADENA COMERCIAL OXXO',lugar:'1er Lugar'},
    {nombre:'CESAR MANUEL MONTERO GAMBOA',empresa:'CADENA COMERCIAL OXXO',lugar:'1er Lugar'},
    {nombre:'MIGUEL ANGEL TAMAYO OLIVA',empresa:'CADENA COMERCIAL OXXO',lugar:'1er Lugar'},
    {nombre:'ERNESTO VALDEZ GONZALEZ',empresa:'CADENA COMERCIAL OXXO',lugar:'1er Lugar'},
    {nombre:'OMAR ALEJANDRO SALAS SANCHEZ',empresa:'CADENA COMERCIAL OXXO',lugar:'1er Lugar'},
    {nombre:'JOSE JUVENAL LOPEZ GUTIERREZ',empresa:'CADENA COMERCIAL OXXO',lugar:'2do Lugar'},
    {nombre:'SERGIO PEREZ LEAL',empresa:'CADENA COMERCIAL OXXO',lugar:'2do Lugar'},
    {nombre:'AXEL EMMANUEL SALAZAR FERNANDEZ',empresa:'CADENA COMERCIAL OXXO',lugar:'2do Lugar'},
    {nombre:'RAUL RAMIREZ GARCIA',empresa:'CADENA COMERCIAL OXXO',lugar:'2do Lugar'},
    {nombre:'CARLOS EDUARDO DIAZ JUAREZ',empresa:'CADENA COMERCIAL OXXO',lugar:'2do Lugar'},
    {nombre:'ALAN EFRAIN REYNOSO AGUILAR',empresa:'CADENA COMERCIAL OXXO',lugar:'2do Lugar'},
    {nombre:'JUAN JOSE VILLASEÑOR APARICIO',empresa:'CADENA COMERCIAL OXXO',lugar:'2do Lugar'},
    {nombre:'FERNANDO CALIXTO CORDERO',empresa:'CADENA COMERCIAL OXXO',lugar:'2do Lugar'},
    {nombre:'JUAN CARLOS FLORES MOCTEZUMA',empresa:'CADENA COMERCIAL OXXO',lugar:'2do Lugar'},
    {nombre:'ALEJANDRO MARTINEZ CHAVEZ',empresa:'CADENA COMERCIAL OXXO',lugar:'2do Lugar'},
    {nombre:'ISMAEL RANGEL SAENZ',empresa:'CADENA COMERCIAL OXXO',lugar:'3er Lugar'},
    {nombre:'JOAQUIN PEDRAZA MACIEL',empresa:'CADENA COMERCIAL OXXO',lugar:'3er Lugar'},
    {nombre:'CRISTIAN RODRIGUEZ DIAZ',empresa:'CADENA COMERCIAL OXXO',lugar:'3er Lugar'},
    {nombre:'FRANCISCO DANIEL GONZALEZ MATIAS',empresa:'CADENA COMERCIAL OXXO',lugar:'3er Lugar'},
    {nombre:'JOSE RAFAEL ESCALANTE GASTELUM',empresa:'CADENA COMERCIAL OXXO',lugar:'3er Lugar'},
    {nombre:'JAIME MONTERO PINEDA',empresa:'CADENA COMERCIAL OXXO',lugar:'3er Lugar'},
    {nombre:'JOSE ANTONIO CASAS VIRAMONTES',empresa:'CADENA COMERCIAL OXXO',lugar:'3er Lugar'},
    {nombre:'MIGUEL ANGEL FLORES PEREZ',empresa:'CADENA COMERCIAL OXXO',lugar:'3er Lugar'},
    {nombre:'ARMANDO SOLIS GALICIA',empresa:'CADENA COMERCIAL OXXO',lugar:'3er Lugar'},
    {nombre:'JUAN CARLOS ALEMAN MAYBOCA',empresa:'CADENA COMERCIAL OXXO',lugar:'3er Lugar'},
    {nombre:'MIGUEL ANGEL GATICA SANCHEZ',empresa:'CADENA COMERCIAL OXXO',lugar:'3er Lugar'},
    {nombre:'CESAR MONTIEL REYES',empresa:'CADENA COMERCIAL OXXO',lugar:'3er Lugar'},
    {nombre:'GERARDO GUZMAN ROSAS',empresa:'CADENA COMERCIAL OXXO',lugar:'3er Lugar'},
    {nombre:'DANIEL GARCIA CAMACHO',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'JOSE ANGEL BARRETO CUANDON',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'LUIS MANUEL RIVERA GARCIA',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'JOSE REFUGIO BLANCO TORRES',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'CESAR ALEJANDRO SOTO ALMARAZ',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'JOSE JAIME CABRAL PAREDES',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'MIGUEL ANGEL VALDEZ MEJIA',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'MIGUEL JUAREZ LOVERA',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'PEDRO LEAL RODRIGUEZ',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'LUIS EFREN HERRERA JARAMILLO',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'JONATHAN LOPEZ AGUILAR',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'ALFONSO COPADO ESPARZA',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'FAUSTINO DELGADILLO BERNAL',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'JESUS MENDEZ PIMENTEL',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'CARLOS ALBERTO DOMINGUEZ CASTRO',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'JOSE SANTOS MORENO TOBAR',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'ROGELIO GONZALEZ LOPEZ',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'ERICK ALEJANDRO RAMIREZ MARTINEZ',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'FORTINO MOGOLLAN DIAZ',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'ANGEL FLORES ALVAREZ',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'ISRAEL SANCHEZ ALTAMIRANO',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'LEONARDO GARZA POSADA',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'FRANCISCO BRIONES IBARRA',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'RAYMUNDO MERINOS OLIVARES',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'REYNALDO SALINAS DELGADO',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'ARNULFO GUADALUPE VERDUGO',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'JOEL TRUJILLO PERALTA',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'DIEGO EDUARDO AGUILAR VAZQUEZ',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'FRANCISCO BENJAMIN ALVARADO SALCEDO',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'JOSE MENDEZ CRUZ',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'RUBEN TREVIÑO CALDERON',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'JOSE DE JESUS TRUJILLO PERALTA',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'CESAR VARGAS RICO',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'CAYETANO CONTRERAS SARMIENTO',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'SERGIO GUTIERREZ GONZALEZ',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'DANIEL SANCHEZ SAHAGUN',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'HUGO ENRIQUE ANGUIANO ZUVIETA',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'JUAN JESUS INIESTRA MENESES',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'CARLOS ROSAS FRANCISCO',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'FELIPE MIRELES CORONADO',empresa:'SIGMA ALIMENTOS',lugar:'1er Lugar'},
    {nombre:'ALVARO PEREZ DIAZ',empresa:'SIGMA ALIMENTOS',lugar:'2do Lugar'},
    {nombre:'JOEL VALENTIN RIVERA',empresa:'SIGMA ALIMENTOS',lugar:'2do Lugar'},
    {nombre:'ERNESTO DE AQUINO SERRANO',empresa:'SIGMA ALIMENTOS',lugar:'2do Lugar'},
    {nombre:'RICARDO BAÑUELOS ALVAREZ',empresa:'SIGMA ALIMENTOS',lugar:'2do Lugar'},
    {nombre:'MARTIN RODRIGUEZ HERNANDEZ',empresa:'SIGMA ALIMENTOS',lugar:'2do Lugar'},
    {nombre:'ISRAEL CAPULIN MEJIA',empresa:'SIGMA ALIMENTOS',lugar:'2do Lugar'},
    {nombre:'ANDRES AGUIRRE VERA',empresa:'SIGMA ALIMENTOS',lugar:'2do Lugar'},
    {nombre:'LUCIANO ENRIQUE GUZMAN GUERRERO',empresa:'SIGMA ALIMENTOS',lugar:'2do Lugar'},
    {nombre:'CRHISTIAN JONATHAN YAÑEZ GONZALEZ',empresa:'SIGMA ALIMENTOS',lugar:'2do Lugar'},
    {nombre:'MANUEL ANTONIO VISOSO NAVARRO',empresa:'SIGMA ALIMENTOS',lugar:'2do Lugar'},
    {nombre:'VICTOR MANUEL PARRA',empresa:'SIGMA ALIMENTOS',lugar:'2do Lugar'},
    {nombre:'HUGO JAVIER RAMIREZ LOPEZ',empresa:'SIGMA ALIMENTOS',lugar:'2do Lugar'},
    {nombre:'RAYMUNDO DANIEL LOPEZ SALDAÑA',empresa:'SIGMA ALIMENTOS',lugar:'2do Lugar'},
    {nombre:'RENE BUENDIA MARTINEZ',empresa:'SIGMA ALIMENTOS',lugar:'2do Lugar'},
    {nombre:'NESTOR OMAR MARTINEZ GOMEZ',empresa:'SIGMA ALIMENTOS',lugar:'2do Lugar'},
    {nombre:'ULISES VARGAS BENITO',empresa:'SIGMA ALIMENTOS',lugar:'2do Lugar'},
    {nombre:'ROBERTO IVAN MARTINEZ BRAVO',empresa:'SIGMA ALIMENTOS',lugar:'2do Lugar'},
    {nombre:'JUAN JOSE BAUTISTA CASTILLO',empresa:'SIGMA ALIMENTOS',lugar:'2do Lugar'},
    {nombre:'JOSE ANTONIO HERRERA HERNANDEZ',empresa:'SIGMA ALIMENTOS',lugar:'2do Lugar'},
    {nombre:'ERIK TAIRI FLORES MEZA',empresa:'SIGMA ALIMENTOS',lugar:'2do Lugar'},
    {nombre:'MIGUEL ANGEL JAIMES HERNANDEZ',empresa:'SIGMA ALIMENTOS',lugar:'2do Lugar'},
    {nombre:'ALFONSO BAUTISTA MORALES',empresa:'SIGMA ALIMENTOS',lugar:'2do Lugar'},
    {nombre:'ANDRES ALBERTO RAMIREZ JUAREZ',empresa:'SIGMA ALIMENTOS',lugar:'2do Lugar'},
    {nombre:'VICTOR SALGADO RODRIGUEZ',empresa:'SIGMA ALIMENTOS',lugar:'2do Lugar'},
    {nombre:'RODOLFO GOMEZ CONTRERAS',empresa:'SIGMA ALIMENTOS',lugar:'2do Lugar'},
    {nombre:'MISAEL MIRANDA MANZANO',empresa:'SIGMA ALIMENTOS',lugar:'2do Lugar'},
    {nombre:'JORGE ANTONIO ARAIZA TRUJILLO',empresa:'SIGMA ALIMENTOS',lugar:'3er Lugar'},
    {nombre:'JOSE ADAN SANCHEZ LOPEZ',empresa:'SIGMA ALIMENTOS',lugar:'3er Lugar'},
    {nombre:'JOSE ARMANDO GUTIERREZ RAMIREZ',empresa:'SIGMA ALIMENTOS',lugar:'3er Lugar'},
    {nombre:'ARMANDO HERNANDEZ ORTIZ',empresa:'SIGMA ALIMENTOS',lugar:'3er Lugar'},
    {nombre:'RAYMUNDO RODRIGUEZ TORRES',empresa:'SIGMA ALIMENTOS',lugar:'3er Lugar'},
    {nombre:'GERARDO TALAVERA AVILA',empresa:'SIGMA ALIMENTOS',lugar:'3er Lugar'},
    {nombre:'HERIBERTO RAMIREZ SANCHEZ',empresa:'SIGMA ALIMENTOS',lugar:'3er Lugar'},
    {nombre:'MANUEL ALCAZAR ARAMBULA',empresa:'SIGMA ALIMENTOS',lugar:'3er Lugar'},
    {nombre:'JAVIER HARO ORTIZ',empresa:'SIGMA ALIMENTOS',lugar:'3er Lugar'},
    {nombre:'BRAULIO DANIEL MANCILLA CALDERON',empresa:'SIGMA ALIMENTOS',lugar:'3er Lugar'},
    {nombre:'JOEL ESPINO MORALES',empresa:'SIGMA ALIMENTOS',lugar:'3er Lugar'},
    {nombre:'CARLOS TORRES SALDAÑA',empresa:'SIGMA ALIMENTOS',lugar:'3er Lugar'},
    {nombre:'EDGAR CARCAÑO MORALES',empresa:'SIGMA ALIMENTOS',lugar:'3er Lugar'},
    {nombre:'RAUL SANCHEZ GARCIA',empresa:'SIGMA ALIMENTOS',lugar:'3er Lugar'},
    {nombre:'OMAR GONZALEZ BARRUETA',empresa:'SIGMA ALIMENTOS',lugar:'3er Lugar'},
    {nombre:'LUIS FERNANDO OCHOA JAQUEZ',empresa:'SIGMA ALIMENTOS',lugar:'Salón de la fama'},
    {nombre:'RICARDO MARTINEZ FRON',empresa:'EL PALACIO DE HIERRO',lugar:'1er Lugar'},
    {nombre:'SAUL INFANTE ROJAS',empresa:'EL PALACIO DE HIERRO',lugar:'1er Lugar'},
    {nombre:'JOSE GUADALUPE CASTRO OSORNIO',empresa:'EL PALACIO DE HIERRO',lugar:'1er Lugar'},
    {nombre:'DAVID VILLAFUERTE ANDRADE',empresa:'EL PALACIO DE HIERRO',lugar:'1er Lugar'},
    {nombre:'JOSE JAVIER RAMIREZ MARTINEZ',empresa:'EL PALACIO DE HIERRO',lugar:'1er Lugar'},
    {nombre:'ADRIAN JESUS PEREZ MARTINEZ',empresa:'EL PALACIO DE HIERRO',lugar:'2do Lugar'},
    {nombre:'ADRIAN MONROY VARELA',empresa:'EL PALACIO DE HIERRO',lugar:'3er Lugar'},
    {nombre:'ISAAC BECERRIL MONTOYA',empresa:'TRANSCOOLER S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'ANTONI GABRIEL ALEGRIA TRINIDAD',empresa:'TRANSCOOLER S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'BULMARO MORA FRANCO',empresa:'TRANSCOOLER S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JUAN CARLOS TORRES ARELLANO',empresa:'TRANSCOOLER S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'RAUL REYES MIRANDA',empresa:'TRANSCOOLER S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'DAVID TERÁN ALMAZÁN',empresa:'TRANSCOOLER S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'LUIS CORTES JUAREZ',empresa:'TRANSCOOLER S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'EFREN MANZANO MARTINEZ',empresa:'TRANSCOOLER S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'GABRIEL HERNANDEZ SOBERANIS',empresa:'TRANSCOOLER S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'CARLOS FLORES ZAPATA',empresa:'TRANSCOOLER S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'JORGE SANTIAGO PADILLA MARTINEZ',empresa:'Transportes MARVA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'CARLOS GARCIA BUSTOS',empresa:'Transportes MARVA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JORGE ALBERTO ZACARIAS SANCHEZ',empresa:'Transportes MARVA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'OSWALDO BALDERAS VALLEJO',empresa:'Transportes MARVA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JESUS VENTURA RODRIGUEZ',empresa:'Transportes MARVA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'ANDRES GERARDO PATIÑO TORRES',empresa:'Transportes MARVA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'MAURICIO HERNANDEZ AGUILAR',empresa:'Transportes MARVA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE LUIS GOMEZ HIJUITL',empresa:'Transportes MARVA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE LUIS PULIDO RODRIGUEZ',empresa:'Transportes MARVA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JUANA ACOSTA DAVILA',empresa:'Transportes MARVA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'ADALBERTO CABRERA HERNANDEZ',empresa:'Transportes MARVA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'ALEJANDRO MORENO LOBATO',empresa:'Transportes MARVA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'RAUL CARLOS MARTINEZ URIBE',empresa:'Transportes MARVA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'FRANCISCO JAVIER GONZALEZ HERMOSILLO',empresa:'Transportes MARVA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'OSCAR ROBERTO GONZALEZ VAZQUEZ',empresa:'Transportes MARVA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'FRANCISCO JOAQUIN TRIANA',empresa:'Transportes MARVA S.A. de C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE SIXTO ARVIZU GARFIAS',empresa:'Transportes MARVA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'YASEL DAVID ROJAS ZAMORA',empresa:'Transportes MARVA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'JOSE LEONARDO MARTINEZ MARTINEZ',empresa:'Transportes MARVA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'FERNANDO BARRIENTOS MORENO',empresa:'Transportes MARVA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'IGNACIO MONGE ESPIRITU',empresa:'Transportes MARVA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'DAVID SUAREZ ALAMILLA',empresa:'Transportes MARVA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'ROGELIO ARTURO TAMBURRINO TORRES',empresa:'Transportes MARVA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'FAUSTINO FELIPE VARGAS GARCIA',empresa:'Transportes MARVA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'ALFREDO JIMENEZ RODRIGUEZ',empresa:'Transportes MARVA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'SAMUEL SANCHEZ VILLEGAS',empresa:'Transportes MARVA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'RAMON NAVA FLORES',empresa:'Transportes MARVA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'SANTIAGO MENDEZ LOPEZ',empresa:'Transportes MARVA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'MARINTIA ISABEL DE LIRA TINAJERO',empresa:'Transportes MARVA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'GERARDO ROMERO CORIA',empresa:'Transportes MARVA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'JOSE DE JESUS DE LOS SANTOS GARCIA',empresa:'Transportes MARVA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'JOEL HERNANDEZ CARCAMO',empresa:'Transportes MARVA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'OSWUALDO FUENTES RODRIGUEZ',empresa:'Transportes MARVA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'FRANCISCO EDUARDO VALENTE MORALES',empresa:'Transportes MARVA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'GERARDO VAZQUEZ MORALES',empresa:'Transportes MARVA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'TOMAS MOSQUEDA DIAZ',empresa:'Transportes MARVA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'JUAN CRISOFORO HERNANDEZ LEOCADIO',empresa:'Transportes MARVA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'JUAN JOSE PICHARDO DEL ANGEL',empresa:'Transportes MARVA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'ANDRES BADILLO MIRANDA',empresa:'Transportes MARVA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'FABIAN FERNANDEZ LOPEZ',empresa:'Transportes MARVA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'JUAN RICARDO PEDRO REYES',empresa:'Transportes MARVA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'MIGUEL LEOS ARANDA',empresa:'Transportes MARVA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'PEDRO SANCHEZ LOPEZ',empresa:'Transportes MARVA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'BENITO MANUEL SOTO TAGANO',empresa:'Transportes MARVA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'DAVID MENDEZ BAROJAS',empresa:'Transportes MARVA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'VICTOR MANUEL JIMENEZ GOMEZ',empresa:'Transportes MARVA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'GABRIEL GARCIA CANO',empresa:'Transportes MARVA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'JOSUE ROMERO BARRALES',empresa:'Transportes MARVA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'GABRIEL MONROY CASTILLO',empresa:'Transportes MARVA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'ANDRES LOPEZ BARRERA',empresa:'Transportes MARVA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'CARLOS OSIRIS GOMEZ GONZALEZ',empresa:'Transportes MARVA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'JUAN CARLOS CISNEROS CRUZ',empresa:'Transportes MARVA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'SOTERO CRUZ HERNANDEZ',empresa:'Transportes MARVA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'RENE OSCAR GARCIA ESPINOSA',empresa:'Transportes MARVA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'ABELARDO CUITLAHUAC HUIZAR DOMINGUEZ',empresa:'Transportes MARVA S.A. de C.V.',lugar:'2do Lugar'},
    {nombre:'JUAN CARLOS BONILLA MUNGUIA',empresa:'Transportes MARVA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'ROBERTO BARRALES LOPEZ',empresa:'Transportes MARVA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'CESAR PEREZ ALPIZAR',empresa:'Transportes MARVA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'FABIAN JUAREZ DUEÑAS',empresa:'Transportes MARVA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'CINTHYA BRAVO CORTES',empresa:'Transportes MARVA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'JAVIER BORBOLLA LOZADA',empresa:'Transportes MARVA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'SALVADOR VIRGEN HINOJOSA',empresa:'Transportes MARVA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'EDUARDO NOLASCO SERRANO',empresa:'Transportes MARVA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'MARIO ALBERTO CERVANTES JUAREZ',empresa:'Transportes MARVA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'AGUSTIN ROMERO CARREÑO',empresa:'Transportes MARVA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'ERNESTO GUTIERREZ TLACUAHUAC',empresa:'Transportes MARVA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'HUMBERTO NOE SANCHEZ CERVANTES',empresa:'Transportes MARVA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'CRISTIAN ALAN ARRIETA HERNANDEZ',empresa:'Transportes MARVA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'HERMINIO MORA CRUZ',empresa:'Transportes MARVA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'HECTOR ISRAEL TORRES ROMERO',empresa:'Transportes MARVA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'ARMANDO RUBEN RAMOS RAMIREZ',empresa:'Transportes MARVA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'MARCO ANTONIO GODINEZ SAAVEDRA',empresa:'Transportes MARVA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'ELIUD TRUJILLO PEREZ',empresa:'Transportes MARVA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'JOEL CALIHUA FRUCTUOSO',empresa:'Transportes MARVA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'GREGORIO GOMEZ HERNANDEZ',empresa:'Transportes MARVA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'ANTONIO ORDOÑEZ GONZALEZ',empresa:'Transportes MARVA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'ANDRES MUÑOZ TECUA',empresa:'Transportes MARVA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'IVAN MONROY PEREZ',empresa:'Transportes MARVA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'HECTOR MANUEL RODRIGUEZ RODRIGUEZ',empresa:'Transportes MARVA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'MARCO ANTONIO SALCEDO MOSQUEDA',empresa:'Transportes MARVA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'JESUS GUTIERREZ SOLIS',empresa:'Transportes MARVA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'JUAN MANUEL RIVERA HERNANDEZ',empresa:'Transportes MARVA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'GUILLERMO GONZALEZ GONZALEZ',empresa:'Transportes MARVA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'CARLOS LUIS RAMOS HERNANDEZ',empresa:'Transportes MARVA S.A. de C.V.',lugar:'3er Lugar'},
    {nombre:'JUAN JOSE LOZANO GOMEZ',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'FEDERICO MODESTO SIXTOS',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'CARLOS ALBERTO MARTINEZ GUTIERREZ',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'ARMANDO GALVAN MUÑOZ',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JUAN RAMON HERNANDEZ ALDANA',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'HECTOR RAFAEL DOMINGUEZ OLIVA',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE DE JESUS NUÑO VIRUETE',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'EZEQUIEL MARTINEZ LOPEZ',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'ALBERTO GARZA AGUIRRE',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JORGE ALBERTO HERNANDEZ RUIZ',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'ROBERTO CARLOS TREVIÑO CORTEZ',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JAIME OROZCO DE LA CRUZ',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'SANTIAGO CASTELLANOS VALENCIA',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'SALVADOR BRAMBILA CORTES',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'VICTOR RAMIREZ VALADEZ',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'EDUARDO CARRANZA BAUTISTA',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'HECTOR GALVAN PEREZ',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'ANDRES DURON SANDOVAL',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'MARINO BALDERAS FLORES',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'GREGORIO GONZALEZ LOPEZ',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JOSE DE JESUS SEVILLA MORENO',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'ESTEBAN VILLA REYNOSO',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'NEHEMIAS RUIZ JIMENEZ',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'LUIS MANUEL MONTOYA RODRIGUEZ',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'LUIS ARMANDO RODRÍGUEZ MARTÍN',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'RAUL GUADALUPE JIMENEZ RAMOS',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'EFRAIN VARGAS DAVILA',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'FERNANDO ALVARADO DOMINGUEZ',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JULIO CESAR TAFOYA HERNADEZ',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'ROGELIO RODRIGUEZ RAYAS',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'LUIS ANGEL GUERRERO GOMEZ',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'WILLIAMS VELAZQUEZ SOLORZANO',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'1er Lugar'},
    {nombre:'JOEL VICENTE ESCALONA GODEN',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'LUIS ENRIQUE CHAVEZ BRAVO',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'ENRIQUE CRUZ RICO',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'RIGOBERTO LANDEROS ROBLES',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'IVAN MEJIA LOPEZ',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'MICHAEL PALMA CRUZ',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'CESAR GUTIERREZ RAFAEL',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'JOSE ISRAEL CASTILLO LUNA',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'ALBINO ZERON PACHECO',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'JOSE CERDA AGUIRRE',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'2do Lugar'},
    {nombre:'LUIS ALBERTO MATIAS MARTINEZ',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'JUAN CARLOS FLORES RODRIGUEZ',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'3er Lugar'},
    {nombre:'SERGIO MELENDEZ CRUZ',empresa:'TRATE DE OCCIDENTE S.A. DE C.V.',lugar:'3er Lugar'},
  ];


const Ganadores = () => {

    const [searchTerm, setSearchTerm] = useState('');

    // Filtrar personas de acuerdo al término de búsqueda
    const filteredPersonas = personas.filter((persona) => 
        persona.nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
        persona.empresa.toLowerCase().includes(searchTerm.toLowerCase()) ||
        persona.lugar.toLowerCase().includes(searchTerm.toLowerCase())
    );


    const visualizar_conferencias = filteredPersonas.map((persona, index) => (
        <tr className="tr_select_operador buscador_operador" key={index}>
            <td className="td_operador nombre_operador" style={{ width: '40%' }}>{persona.nombre}</td>
            <td className="td_operador nombre_operador" style={{ width: '40%' }}>{persona.empresa}</td>
            <td className="td_operador datos_operador" style={{ width: '20%' }}>{persona.lugar}</td>
        </tr>
    ));


    return(
      <>
      
      <Row align="middle" className="contenedor_back">
        <Col span={20} offset={2}>
          <br /><br />
          <p style={{fontSize:'40px'}} className='color_azul_2'><strong>GANADORES</strong></p>
          </Col>
          <Col span={12} offset={6}>
          <input className="input_buscar_premio" 
                        placeholder="Buscar por nombre, empresa o lugar" 
                        onChange={(e) => setSearchTerm(e.target.value)} 
                        style={{ marginBottom: '20px' }}
                    />
                    <span className='search_icon'><SearchOutlined /></span>
                    </Col>
                    <Col span={16} offset={4}>
          <table style={{ width: '100%' }}>
                        <thead>
                            <tr className="secciones_operados_azul">
                                <th className="td_operador">Nombre</th>
                                <th className="td_operador">Empresa</th>
                                <th className="td_operador">Lugar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {visualizar_conferencias}
                        </tbody>
                    </table>
        </Col>
        
      </Row>
      
        </>
    );
}

export default Ganadores;