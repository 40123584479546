import React, { useParams, useContext, useRef, useState, useEffect } from 'react';
import { } from 'react-router-dom';
import { Row, Col, Form, Input, Button, notification, Modal, Tabs } from 'antd';

import { Auth } from "../../utils/AuthContext";
import Firebase from '../../utils/firebaseConfig';

import '../../assets/css/sala.css';
import logoS from '../../assets/images/sitio/inicio/logo_pnsv_zonachat.png';
import imagen_proximamente from '../../assets/images/sitio/inicio/bienvenida.jpg';

import PopUpPregunta from './PopUpPregunta';

import rede_facebook from '../../assets/images/temp/50x50.png';
import rede_ln from '../../assets/images/temp/50x50.png';
import rede_youtube from '../../assets/images/temp/50x50.png';


const { TextArea } = Input;

const app = new Firebase();

const mensaje = (tipo, titulo, texto) => {

    notification[tipo]({
        message: titulo,
        description: texto,
    });

}
const layout = {
    wrapperCol: {
        span: 24,
    },
};

const LiveSinComentarios = (props) => {

    const [mis_preguntas, misPreguntas] = useState(false);
    const [en_vivo, enVivo] = useState(true);
    const [preguntas_contestadas, preguntasContestadas] = useState(false);
    
    const [modal_cuestionario, setModalCuestionario] = useState(false);
    const [num_pregunta, setNumPregunta] = useState(0);
    const { url, comentarios, webinar, urlfb, urltw, urlyt, urlln } = props;
    const [preguntas_popup_usuario, setPreguntasPopUpUsuario] = useState({});
    const [autoplay, setAutoPlay] = useState(url); 

    const { usuario, datos } = useContext(Auth);

    const ref_form_pregunta = useRef();
    const ref_iframe = useRef();

    useEffect(() => {
        if (usuario) {
            app.obtener_objeto(webinar + '/respuestas-usuarios-en-vivo', 'telefono', usuario.phoneNumber, (data) => {
                console.log('me trajo');
                console.log(data);
                setPreguntasPopUpUsuario(data);
            });
        }
    }, [usuario]);

    useEffect(() => {

        if (Object.keys(preguntas_popup_usuario).length !== 0) {

            app.obtener_objetos(webinar + '/configuracion', true, (data) => {

                setNumPregunta(data[0].pregunta_popup);

                if (data[0].pregunta_popup === 0) {
                    setModalCuestionario(false);
                } else {
                    if (preguntas_popup_usuario['respuesta' + data[0].pregunta_popup] === undefined || preguntas_popup_usuario['respuesta' + data[0].pregunta_popup] === null) {
                        setModalCuestionario(true);
                    } else {
                        setModalCuestionario(false);
                    }

                }

            });

        }

    }, [preguntas_popup_usuario]);

    const onResponseCuestionario = (valor_respuesta) => {
        setModalCuestionario(false);

        preguntas_popup_usuario['respuesta' + num_pregunta] = valor_respuesta;

        app.escribir(webinar + '/respuestas-usuarios-en-vivo', preguntas_popup_usuario.key, preguntas_popup_usuario, (item) => {
            mensaje('success', 'Gracias por tu participación', '');
        });

    }

    useEffect(()=>{

        //setAutoPlay(url+'autoplay=1;');
    },[]);


    const onFinish = values => {

        let pregunta = {
            organizacion: datos.organizacion,
            nombre: datos.nombre + ' ' + datos.ap_paterno + ' ' + datos.ap_materno,
            pregunta: values.pregunta,
            correo: datos.correo,
            status: 'pendiente'
        }

        app.escribir(webinar + '/preguntas-publico', undefined, pregunta, () => {
            mensaje('success', 'Tu comentario ha sido enviado');
            ref_form_pregunta.current.resetFields();
        });
    };


    return (
        <Row align="top" className="" >

            <Col xs={{ span: 22, offset: 1 }} lg={{ span: 18, offset: 3 }} style={{ textAlign: 'center' }}>

                {url !== null ?
                    (
                        <>
                            <div className="contenedor_video">
                                <iframe ref={ref_iframe} className="video" src={url} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; allowfullscreen;" allowfullscreen="1"></iframe>
                            </div>
                        </>
                    ) :
                    (
                        <>
                            <img className="imagen_logo" src={imagen_proximamente} style={{ width: '100%' }} />
                        </>
                    )


                }


            </Col>
            { url !== null ?
                (<>
                    <Col xs={{ span: 20, offset: 2 }} lg={0} style={{display:'none'}}>
                        <p className="clic_ver color_azul"><strong>Si no puedes visualizar la conferencia da clic en
            <a href={urlyt} target="_blank" className="color_azul"> Youtube</a>
                            {urlfb !== null ?
                                (<>
                                    <a href={urlfb} target="_blank"><img src={rede_facebook} style={{ width: '20px' }} /></a>
                                </>)
                                :
                                (
                                    <>
                                    </>
                                )
                            }
                            {urlln !== null ?
                                (<>
                                    <a href={urlln} target="_blank"><img src={rede_ln} style={{ width: '20px' }} /></a>
                                </>)
                                :
                                (
                                    <>
                                    </>
                                )
                            }
                        </strong>
                        </p>
                    </Col>
                </>)
                :
                (
                    <>
                    </>
                )
            }

            <Col xs={24} lg={0}>
                <br />
            </Col>
            
            { url !== null ?
                (<>
                    <Col xs={0} lg={{ span: 20, offset: 2 }} style={{display:'none'}}>
                        <p className="clic_ver color_azul"><strong>Si no puedes visualizar la conferencia da clic en
            <a href={urlyt} target="_blank" className="color_azul"> Youtube</a>
                            {urlfb !== null ?
                                (<>
                                    <a href={urlfb} target="_blank"><img src={rede_facebook} style={{ width: '20px' }} /></a>
                                </>)
                                :
                                (
                                    <>
                                    </>
                                )
                            }
                            {urlln !== null ?
                                (<>
                                    <a href={urlln} target="_blank"><img src={rede_ln} style={{ width: '20px' }} /></a>
                                </>)
                                :
                                (
                                    <>
                                    </>
                                )
                            }
                        </strong>
                        </p>
                    </Col>
                </>)
                :
                (
                    <>
                    </>
                )
            }
            <Col xs={24} lg={0}>
                <br /><br />
            </Col>
            <Modal
                title={<span style={{ textAlign: 'center', fontWeight: 'bold' }} className="color_azul">PARTICIPA EN NUESTRA ENCUESTA DE SATISFACCIÓN</span>}
                visible={modal_cuestionario}
                footer={null}
                onCancel={() => setModalCuestionario(false)}
            >

                <PopUpPregunta num_pregunta={num_pregunta} onResponse={onResponseCuestionario} />


            </Modal>
        </Row>
    );
}

export default LiveSinComentarios;