import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { scroller } from 'react-scroll';

import banner from '../../assets/images/temp/1200X100.png';
import banner_movil from '../../assets/images/temp/1200X100.png';

//import usuario_img from 'https://via.placeholder.com/150';
//import apellido_img from 'https://via.placeholder.com/150';
//import img_entrar_recepcion from 'https://via.placeholder.com/150';

import icono_whtas from '../../assets/images/registro/icono_WhastAppNegro.png';
import icono_messenger from '../../assets/images/registro/icono_MessengerNegro.png';
import transmision from '../../assets/images/registro/antp_BotonAccederALaTransmision.png';

import icono_usuario from '../../assets/images/registro/icono_Correodeacceso.png';
import icono_clave from '../../assets/images/registro/Icono_Codigodeacceso.png';

const RegistroCompleto = (props) => {

    const {correo, password} = props;

    useEffect(() => {
        scroller.scrollTo('header');
    },[]);

    return(
        <Row align="middle">

            <Col xs={{span:24}} md={{span:0}}>
            <img src={banner_movil} style={{ width:'100%' }} />
            </Col>

            <Col xs={{span:0}} md={{span:24}}>
            <img src={banner} style={{ width:'100%' }} />
            </Col>


            <Col xs={{span:22, offset:1}} md={{span:12, offset:6}}><br /><br />
                <p style={{ textAlign:'left' }}>
                <span className="texto-breve color_gris_oscuro">En breve un representante de ANTP se pondrá en contacto para definir la forma
                    de pago y llevar a cabo el proceso. </span><br /><br />
            </p>

           <Row align="middle" className="contenedor_contacto_registro_exitoso">
               <Col span={11} offset={1}>
                   <p className="necesitas_ayuda_registro_exitoso">
                    ¿Necesitas ayuda?
                   </p>
               </Col>
               <Col span={9} offset={2}>
                   <p className="contacto_registro_exitoso">
                       <img src={icono_whtas} className="img_contacto_registro_exitoso" /> +52 (55) 5534 3598
                        <br />
                        <img src={icono_messenger} className="img_contacto_registro_exitoso" /> contacto@antp.org.mx
                   </p>
               </Col>
           </Row>
            <Row >
                <Col span={24}>
                    <br />
                    <p className="intrucciones_registro_exitoso">Instrucciones de acceso:</p>
                </Col>
                <Col xs={{span:2, offset:0}} md={{span:1, offset:0}} lg={{span:1, offset:0}}>
                <p style={{ textAlign:'left', paddingLeft:'0%', paddingRight:'0%' }}>
                <span className="datos-azul color_amarillo "><strong>1. </strong></span> </p>
                </Col>
                <Col xs={{span:22, offset:0}} md={{span:22, offset:0}} lg={{span:22, offset:0}} >
                <p style={{ textAlign:'justify', paddingLeft:'1%', paddingRight:'0%' }}>
                <span className="datos-azul ">Ingresa a </span>
                <span className="datos-azul"><strong>www.antp.org.mx/congreso.</strong></span>
                    
                </p>
                </Col>
                
            </Row>
            <Row>
            <Col xs={{span:2, offset:0}} md={{span:1, offset:0}} lg={{span:1, offset:0}}>
                <p style={{ textAlign:'left', paddingLeft:'0%', paddingRight:'0%' }}>
                <span className="datos-azul color_amarillo "><strong>2. </strong></span></p>
                </Col>
                <Col xs={{span:22, offset:0}} md={{span:22, offset:0}} lg={{span:22, offset:0}}>
                <p style={{ textAlign:'justify', paddingLeft:'1%', paddingRight:'0%' }}>
                <span className="datos-azul ">Copia tu <span className="datos-azul"><strong> usuario (correo electrónico)</strong></span> y pégalo en la casilla correspondiente.</span>
                </p>
                </Col>
            </Row>
            <Row>
            <Col xs={{span:2, offset:0}} md={{span:1, offset:0}} lg={{span:1, offset:0}}>
                <p style={{ textAlign:'left', paddingLeft:'0%', paddingRight:'0%' }}>
                <span className="datos-azul color_amarillo "><strong>3. </strong></span> </p>
                </Col>
                <Col xs={{span:22, offset:0}} md={{span:22, offset:0}} lg={{span:22, offset:0}}>
                <p style={{ textAlign:'justify', paddingLeft:'1%', paddingRight:'0%' }}>
                <span className="datos-azul ">Repite el paso anterior con tu <span className="datos-azul"> <strong>código de acceso.</strong></span></span></p>
                </Col>
            </Row>
            <Row>
            <Col xs={{span:2, offset:0}} md={{span:1, offset:0}} lg={{span:1, offset:0}}>
                <p style={{ textAlign:'left', paddingLeft:'0%', paddingRight:'0%' }}>
                <span className="datos-azul color_amarillo "><strong>4. </strong></span> </p>
                </Col>
                <Col xs={{span:22, offset:0}} md={{span:22, offset:0}} lg={{span:22, offset:0}}>
                <p style={{ textAlign:'justify', paddingLeft:'1%', paddingRight:'0%' }}>
                <span className="datos-azul ">Da clic en <span className="datos-azul"><strong>ingresar </strong></span> y disfruta del contenido preparado para ti.</span></p>
                </Col>
            </Row>
            <br />
<p className="fondo color_amarillo texto_titulo_instruccions">
<span >Tus datos de acceso:</span>
</p>

            <Row align="middle">
            <Col xs={{span:8, offset:0}} md={{span:3, offset:0}} >
                <p className="contenedor_datos_info_registro_exitoso" >
                    <img src={icono_usuario} style={{width:'50px'}} />
                </p>
                </Col>
            <Col xs={{span:16, offset:0}} md={{span:15, offset:0}}>
                <p className="datos-azul contenedor_datos_info_registro_exitoso" style={{textAlign:'left'}}>
                    Tu usuario (correo electrónico):
                    <br />
    <span><strong>{correo}</strong></span>
                </p>
                </Col>
            </Row>

            <Row align="middle">
            <Col xs={{span:8, offset:0}} md={{span:3, offset:0}}>
                <p className="contenedor_datos_info_registro_exitoso" >
                    <img src={icono_clave} style={{width:'50px'}} />
                </p>
                </Col>
            <Col xs={{span:16, offset:0}} md={{span:15, offset:0}}>
                <p className="datos-azul contenedor_datos_info_registro_exitoso" style={{textAlign:'left'}} >
                Código de acceso:
                    <br />
    <span><strong>Código proporcionado al procesar el pago</strong></span>
                </p>
                </Col>
            </Row>
            <br />
            <br />
            </Col>

            <Col span={24}>
                <p className="acceder_transmision_registroso_exitoso">
                    <Link to="/">
                        <img src={transmision} className="img_acceder_transmision_registroso_exitoso" />
                    </Link>
                </p>
            </Col>

            <Col span={24} className="back_amarillo">
                <p className="nota" style={{color:'black'}} >
                    <br />Nota: La transmisión se habilitará 30 minutos antes del evento.</p>
            </Col>
            
            
            </Row>
    );
}

export default RegistroCompleto;