import React, { useEffect, useState, useContext } from 'react';
import { Row, Col } from 'antd';
import { Auth } from '../../utils/AuthContext';
import Menu from '../master/Menu';
import Firebase from '../../utils/firebaseConfig';
import Expo from '../expo/Expo';
import '../../assets/css/conferencia_empresa.css';
import lobby from '../../assets/images/premio_2021/lobby_14oct.jpg';

import audio_premiocacion from '../../assets/images/premio_2022/conin_audios/5_Conin_entrega_de_reconocimientos.mp3';
import audio_instrucciones from '../../assets/images/premio_2022/conin_audios/6_Conin_entrega_de_reconocimientos.mp3';
import audio_leonardo from '../../assets/images/premio_2022/conin_audios/3_Direccionar_a_mensaje_en_vivo_de_Leonardo.mp3';

import almex from '../../assets/images/premio_2023/empresa_diplomas/alemex.png';
import barcel from '../../assets/images/premio_2023/empresa_diplomas/barcel.png';
import bepensa from '../../assets/images/premio_2023/empresa_diplomas/bepensa.png';
import bimbo from '../../assets/images/premio_2023/empresa_diplomas/bimbo.png';
import cemex from '../../assets/images/premio_2023/empresa_diplomas/cemex.png';
import concretos_cemex from '../../assets/images/premio_2023/empresa_diplomas/concretos_cemex.png';
import fabricadejaboneslacorona from '../../assets/images/premio_2023/empresa_diplomas/fabricadejaboneslacorona.png';
import hesa from '../../assets/images/premio_2023/empresa_diplomas/hesa.png';
import jumex from '../../assets/images/premio_2023/empresa_diplomas/jumex.png';
import lala_1 from '../../assets/images/premio_2023/empresa_diplomas/lala_d_primaria.png';
import lala_2 from '../../assets/images/premio_2023/empresa_diplomas/lala_d_secundaria.png';
import linde from '../../assets/images/premio_2023/empresa_diplomas/linde.png';
import marva from '../../assets/images/premio_2023/empresa_diplomas/marva.png';
import palaciodehierro from '../../assets/images/premio_2023/empresa_diplomas/palaciodehierro.png';
import paquetexpress from '../../assets/images/premio_2023/empresa_diplomas/paquetexpress.png';
import pilot from '../../assets/images/premio_2023/empresa_diplomas/pilot.png';
import sigma from '../../assets/images/premio_2023/empresa_diplomas/sigma.png';
import solistica from '../../assets/images/premio_2023/empresa_diplomas/solistica.png';
import tdr from '../../assets/images/premio_2023/empresa_diplomas/tdr.png';
import ttt from '../../assets/images/premio_2023/empresa_diplomas/torres-transportes-terrestres.png';
import transcooler from '../../assets/images/premio_2023/empresa_diplomas/transcooler.png';
import trate from '../../assets/images/premio_2023/empresa_diplomas/trate.png';
import zinergia from '../../assets/images/premio_2023/empresa_diplomas/zinergia.png';
import zonte from '../../assets/images/premio_2023/empresa_diplomas/zonte.png';

import conin_inicial from '../../assets/images/premio_2021/Estado05.gif';
import conin_hablar from '../../assets/images/premio_2021/Estado03.gif';
import conin_festejar from '../../assets/images/premio_2021/Estado04.gif';
import DemoEmpresa from './DemoEmpresa';
import { Redirect } from 'react-router';


const nombre_evento = 'premio-2024';
const app = new Firebase();

let audio;

const ConferenciaEmpresas = () => {

    const { usuario, datos } = useContext(Auth);
    const [estados_conin, setEstadosConin] = useState(0);
    const [play, setPlay] = useState(false);
    const [audios, setAudio] = useState('');
    const [empresa, setEmpresa] = useState('');
    const [etapa, setEtapa] = useState(2);
    const [etapa_final_conin, setEtapaFinalConin] = useState(-1);

    useEffect(() => {

        //if (!usuario) {
          //  window.location.href = "/registro";
        //} else {
            app.obtener_objetos(nombre_evento + '/configuracion_flujo', true, (data) => {
                setEtapa(data[0].etapa);
            });
        //}

    }, [usuario]);

    const empresas = [

        { url: '/felicitacion-conductor/almex', img_url: almex, alt: 'almex' },
        { url: '/felicitacion-conductor/barcel', img_url: barcel, alt: 'barcel' },
        { url: '/felicitacion-conductor/bepensa', img_url: bepensa, alt: 'bepensa' },
        { url: '/felicitacion-conductor/bimbo', img_url: bimbo, alt: 'bimbo' },
        { url: '/felicitacion-conductor/cemex', img_url: cemex, alt: 'cemex' },
        { url: '/felicitacion-conductor/cemex-concretos', img_url: concretos_cemex, alt: 'cemex-concretos' },
        { url: '/felicitacion-conductor/fabrica-de-jabon', img_url: fabricadejaboneslacorona, alt: 'fabrica-de-jabon' },
        { url: '/felicitacion-conductor/hesa', img_url: hesa, alt: 'hesa' },
        { url: '/felicitacion-conductor/jumex', img_url: jumex, alt: 'jumex' },
        { url: '/felicitacion-conductor/lala-distribuidora-primaria', img_url: lala_1, alt: 'lala-distribuidora-primaria' },
        { url: '/felicitacion-conductor/lala-distribuidora-secundaria', img_url: lala_2, alt: 'lala-distribuidora-secundaria' },
        { url: '/felicitacion-conductor/linde', img_url: linde, alt: 'linde' },
        { url: '/felicitacion-conductor/marva', img_url: marva, alt: 'marva' },
        { url: '/felicitacion-conductor/palacio-de-hierro', img_url: palaciodehierro, alt: 'palacio-de-hierro' },
        { url: '/felicitacion-conductor/paquetexpress', img_url: paquetexpress, alt: 'paquetexpress' },
        { url: '/felicitacion-conductor/pilot', img_url: pilot, alt: 'pilot' },
        { url: '/felicitacion-conductor/sigma', img_url: sigma, alt: 'sigma' },
        { url: '/felicitacion-conductor/solistica', img_url: solistica, alt: 'solistica' },
        { url: '/felicitacion-conductor/tdr', img_url: tdr, alt: 'tdr' },
        { url: '/felicitacion-conductor/ttt', img_url: ttt, alt: 'ttt' },
        { url: '/felicitacion-conductor/transcooler', img_url: transcooler, alt: 'transcooler' },
        { url: '/felicitacion-conductor/trate', img_url: trate, alt: 'trate' },
        { url: '/felicitacion-conductor/zinergia', img_url: zinergia, alt: 'zinergia' },
        { url: '/felicitacion-conductor/zonte', img_url: zonte, alt: 'zonte' },
    ];

    let pintar_empresas = empresas.map((empresa, index) => {

        switch (index) {
            case 0: case 5: case 10: case 15: case 20: case 25:
                return (
                    <Col xs={{ span: 6, offset: 0 }} md={{ span: 4, offset: 2 }} style={{ position: 'relative' }}>

                        <div className="hover08 pointer_ver" onClick={() => { setEmpresa(empresa.alt); }}><figure><img src={empresa.img_url} className="img_empresa_conferencia " alt={empresa.alt} /></figure></div>

                    </Col>
                );
                break;
            default:
                return (
                    <Col xs={{ span: 6, offset: 0 }} md={{ span: 4, offset: 0 }} style={{ position: 'relative' }}>

                        <div className="hover08 pointer_ver" onClick={() => { setEmpresa(empresa.alt); }}><figure><img src={empresa.img_url} className="img_empresa_conferencia " alt={empresa.alt} /></figure></div>

                    </Col>
                );
                break;
        }

    });

    /*useEffect(()=>{

        if(!usuario){
            window.location.href="/registro";
        }else{
            console.log(localStorage);
            if(localStorage.empresa_url !== undefined){
                if(localStorage.empresa_url !== ''){
                    if(localStorage.empresa_ir === "true"){
                        localStorage.setItem("empresa_ir","false");
                        //alert('se va');
                        console.log(localStorage);
                        window.location.href = '/felicitacion-conductor/'+localStorage.empresa_url;
                    }
                }else{
                    console.log('no aparece');
                }
            }
        }
        
    },[]);*/

    useEffect(() => {
        //if (!usuario) {
          //  window.location.href = "/registro";
        //} else {
            app.obtener_objetos(nombre_evento + '/configuracion_conin_final', true, (dat) => {
                setEtapaFinalConin(dat[0].etapa);
            });
        //}
    }, [usuario]);

    useEffect(() => {

        switch (estados_conin) {
            case 0: setAudio(audio_instrucciones); break;
            //case 2: setAudio(audio_instrucciones); break;
            //case 4: setAudio('https://conin.antp.org.mx/audios/premiacion/audio/diploma'); break;
            default: setAudio(''); break;
        }


    }, [estados_conin]);

    useEffect(() => {
        console.log('etapa_final_conin', etapa_final_conin);
        switch (etapa_final_conin) {
            case 1: setAudio(audio_leonardo); break;
            //case 4: setAudio('https://conin.antp.org.mx/audios/despedida/audio/despedida'); break;
            default: break;
        }
    }, [etapa_final_conin]);

    const Hablar = () => {
        setPlay(true);
        audio = new Audio(audios);
        audio.addEventListener('ended', () => {
            setPlay(false);
        });
        audio.play();
    }
    console.log('estados_conin', estados_conin);
    console.log('etapa', etapa);
    useEffect(() => {
        if (play) {
            switch (estados_conin) {
                case 0: setEstadosConin(1); break;
                //case 2: setEstadosConin(3); break;
                //case 4: setEstadosConin(5); break;
            }
        } else {
            switch (estados_conin) {
                case 1: setEstadosConin(2); break;
                //case 3: setEstadosConin(4); break;
                //case 5: setEstadosConin(6); break;
            }
        }
    }, [play]);


    return (
        <>

{
                            empresa === '' ?
                                <Row align="middle" className="contenedor_back">
                                    <Col span={24} style={{ position: 'relative' }}>
                                        <br />
                                        <p className="texto_elige">SELECCIONA TU EMPRESA</p>
                                    </Col>
                                    {pintar_empresas}
                                </Row>
                                :
                                <DemoEmpresa datos={datos} empresa_elegida={empresa} onResponse={() => { setEmpresa('') }} ocultarConin={setEstadosConin} />
                        }
        </>
    );

}

export default ConferenciaEmpresas;