import React, { useEffect, useContext, useState } from "react";
import { Row, Col, Modal, Spin, message, Button, notification } from "antd";
import { Auth } from "../../utils/AuthContext";
import { Redirect, withRouter, useLocation } from "react-router";
import Firebase from "../../utils/firebaseConfig";
import Live from "../conferencias/Live";
import { Preguntas } from "../conferencias/Preguntas";
import { Estadisticas } from "../conferencias/Estadisticas";
import Menu from "../master/Menu";
import "../../assets/css/bienvenida.css";
import presenta from "../../assets/images/temp/1200X100.png";
import LiveSinComentarios from "../conferencias/LiveSinComentarios";
import camion_izq from "../../assets/images/premio_2021/camion_izquierdo.png";
import camion_der from "../../assets/images/premio_2021/camion_derecho.png";
import CarouselPatrocinadores from "../evento/CarouselPatrocinadores";
import conin_inicial from '../../assets/images/premio_2021/Estado05.gif';
import conin_hablar from '../../assets/images/premio_2021/Estado03.gif';
import audio_premiacion from '../../assets/images/premio_2022/conin_audios/9_Despedida_final.mp3';
import audio_despedida from '../../assets/images/premio_2022/conin_audios/8_Premiacion_empresas.mp3';
import CarouselEmpresas from "../evento/CarouselEmpresas";

const nombre_evento = "premio-2024";

const app = new Firebase();



let key = "";
const success = () => {
  mensaje("success", "Bienvenido", "");
};

const mensaje = (tipo, titulo, texto) => {
  notification[tipo]({
    message: titulo,
    description: texto,
  });
};
let audio;
const Demo = ({ history, youtube, comentarios, nombre_video }) => {
  const { usuario, datos } = useContext(Auth);
  const [preguntas_ponente, setPreguntasPonente] = useState([]);
  const [etapa, setEtapa] = useState(0);
  const [redireccionar, setRedireccionar] = useState(false);
  const [estados_conin, setEstadosConin] = useState(0);
  const [play, setPlay] = useState(false);
  const [audios, setAudio] = useState('');
  const [etapa_final_conin, setEtapaFinalConin] = useState(-1);

  const location = useLocation();


  useEffect(() => {
    //if (!usuario) {
      //window.location.href = "/registro";
    //} else {
      app.obtener_objetos(
        nombre_evento + "/configuracion_flujo",
        true,
        (data) => {
          setEtapa(data[0].etapa);
        }
      );
      app.obtener_objetos(
        nombre_evento + "/preguntas-publico/chat",
        true,
        (data) => {
          setPreguntasPonente(data);
        }
      );
    //}
  }, [history, usuario]);

  useEffect(() => {
    if (
      etapa !== 0 &&
      etapa !== 1 &&
      etapa !== 2 &&
      etapa !== 4 &&
      etapa !== 5 &&
      etapa !== 6
    ) {
      setRedireccionar(true);
    }
  }, [etapa]);


  useEffect(() => {
    //if (!usuario) {
      //window.location.href = "/registro";
    //} else {
      app.obtener_objetos(nombre_evento + '/configuracion_conin_final', true, (dat) => {
        setEtapaFinalConin(dat[0].etapa);
      });
    //}
  }, [usuario]);

  useEffect(() => {

    switch (estados_conin) {

    }


  }, [estados_conin]);

  useEffect(() => {
    console.log('etapa_final_conin', etapa_final_conin);
    switch (etapa_final_conin) {
      case 2: setAudio(audio_premiacion); break;
      case 3: setAudio(audio_despedida); break;
      default: break;
    }
  }, [etapa_final_conin]);

  const Hablar = () => {
    setPlay(true);
    audio = new Audio(audios);
    audio.addEventListener('ended', () => {
      setPlay(false);
    });
    audio.play();
  }
  useEffect(() => {
    if (play) {
      switch (estados_conin) {
        case 0: setEstadosConin(1); break;
        case 2: setEstadosConin(3); break;
        case 4: setEstadosConin(5); break;
      }
    } else {
      switch (estados_conin) {
        case 1: setEstadosConin(2); break;
        case 3: setEstadosConin(4); break;
        case 5: setEstadosConin(6); break;
      }
    }
  }, [play]);


  return (
    <div className="cuerpo">
      {redireccionar ? (
        <Redirect to="/" />
      ) : (
        <Row align="">
          {comentarios ? (
            <>
              <Col span={24} offset={0}>
                <Live
                  url={youtube}
                  urlyt={null}
                  urlfb={null}
                  urlln={null}
                  webinar={nombre_evento}
                  comentarios={preguntas_ponente}
                  comentario_empresa={location.pathname}
                  nombre_video={nombre_video}
                  datos={datos}
                />
              </Col>
              
              <Col span={20} offset={2}>
                <CarouselEmpresas />
              </Col>
              <Col span={20} offset={2}>
                <CarouselPatrocinadores />
                <br /><br />
              </Col>
              {(etapa_final_conin === 2 || etapa_final_conin === 3) &&
                <>
                  {(estados_conin === 0 || estados_conin === 2) &&
                    <img src={conin_inicial} className="conin" alt="" onClick={Hablar} />
                  }
                  {(estados_conin === 1 || estados_conin === 3 || estados_conin === 5) &&
                    <img src={conin_hablar} className="conin" alt="" />
                  }
                  {play &&
                    <p className="saltar_conin" alt="" onClick={() => { setPlay(false); audio.pause(); }}  >SALTAR</p>
                  }

        </>
      }
            </>
          ) : (
            <Col span={24} offset={0} className="contenedor_bienvenida">
              <Row>
                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 20, offset: 2 }}
                  md={{ span: 18, offset: 3 }}
                  lg={{ span: 16, offset: 4 }}
                >
                  <LiveSinComentarios
                    url={youtube}
                    urlyt={null}
                    urlfb={null}
                    urlln={null}
                    webinar={nombre_evento}
                    comentarios={preguntas_ponente}
                    datos={datos}
                  />
                </Col>
              </Row>
              <img src={camion_izq} className="camion_izq" alt="" />
              <img src={camion_der} className="camion_der" alt="" />
              <br />
              <br />
            </Col>
          )}
        </Row>
      )}
      
    </div>
  );
};

export default Demo;
