import React, { useContext, useEffect, useState, useRef } from "react";
import { Row, Col, notification, Carousel } from 'antd';
import { Redirect } from 'react-router-dom';
import { Auth } from "../../utils/AuthContext";
import Firebase from '../../utils/firebaseConfig';
import '../../assets/css/lobby.css';
import lobby from '../../assets/images/premio_2021/lobby_14oct.jpg';
import Demo from '../en-vivo/Demo';
import spoken from '../../../node_modules/spoken/build/spoken';
import Reproducir from './Reproducir';
import Slider from "react-slick";
import { Element, scroller } from 'react-scroll';
import audio_operador from '../../assets/images/premio_2022/conin_audios/1_Recepcion_Operadores.mp3';
import audio_invitado from '../../assets/images/premio_2022/conin_audios/2_Recepcion_invitado.mp3';
import audio_leonardo from '../../assets/images/premio_2022/conin_audios/8_Premiacion_empresas.mp3';

import cerrar_conin from '../../assets/images/premio_2021/BTN entrar_OV.png';


import conin_inicial from '../../assets/images/premio_2021/Estado05.gif';
import conin_hablar from '../../assets/images/premio_2021/Estado03.gif';
import conin_festejar from '../../assets/images/premio_2021/Estado04.gif';
import conin_final from '../../assets/images/premio_2021/Estado01.gif';
import conin_pensando from '../../assets/images/premio_2021/Estado02.gif';

import src_etapa_1 from '../../assets/images/premio_2021/btn_reunion zoom.png';
import src_etapa_2 from '../../assets/images/premio_2021/btn_reunion zoom.png';
import src_etapa_3 from '../../assets/images/premio_2021/btn_reunion zoom.png';
import src_etapa_4 from '../../assets/images/premio_2021/btn_reunion zoom.png';

import cerrar from '../../assets/images/premio_2021/btn_cerrar.png';


import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import { LeftSquareTwoTone } from "@ant-design/icons";
import Expo from "../expo/Expo";

const nombre_evento = 'premio-2024';
const app = new Firebase();

const mensaje = (tipo, titulo, texto) => {
    notification[tipo]({
        message: titulo,
        description: texto,
    });
}

var settings = {
    className: "",
    arrows: false,
    fade: true,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true
};


const mensajes_conin = [
    { etapa: 0, mensaje: <p className="contenedor_texto_conin">Tenemos un mensaje de bienvenida</p>, mensaje_inferior: <p></p>, url: '/bienvenida', img: src_etapa_1, youtube: 'https://www.youtube.com/embed/mtO0R8TbwVQ', comentarios: true, nombre_video: 'bienvenida' },
    { etapa: 1, mensaje: <p className="contenedor_texto_conin">Tenemos un mensaje de bienvenida</p>, mensaje_inferior: <p></p>, url: '/auditorio', img: src_etapa_2, youtube: 'https://www.youtube.com/embed/mtO0R8TbwVQ', comentarios: true, nombre_video: 'conferencia-motivacional' },
    { etapa: 2, mensaje: <p className="contenedor_texto_conin">Tenemos un mensaje de bienvenida</p>, mensaje_inferior: <p></p>, url: '/premiacion', img: src_etapa_3, youtube: 'https://www.youtube.com/embed/mtO0R8TbwVQ', comentarios: true, nombre_video: 'premiacion' },
    {
        etapa: 4, mensaje: <p className="contenedor_texto_conin">
            <span className="texto_conin" setUltimaEtapaConin>{mensaje.mensaje}</span>

            <img src={mensaje.img} className="img_url" alt="" />
            <br />
            <span className="texto_inferior">{mensaje.mensaje_inferior}</span>
        </p>, mensaje_inferior: <p>Zoom</p>, url: '/despedida', img: src_etapa_4, youtube: 'https://www.youtube.com/embed/mtO0R8TbwVQ?start=9649', comentarios: true, nombre_video: 'despedida'
    },
    { etapa: 5, mensaje: <p className="contenedor_texto_conin">Tenemos un mensaje de bienvenida</p>, mensaje_inferior: <p></p>, url: '/auditorio', img: src_etapa_2, youtube: 'https://www.youtube.com/embed/mtO0R8TbwVQ', comentarios: true, nombre_video: 'conferencia-motivacional' },
    { etapa: 6, mensaje: <p className="contenedor_texto_conin">Tenemos un mensaje de bienvenida</p>, mensaje_inferior: <p></p>, url: '/auditorio', img: src_etapa_2, youtube: 'https://www.youtube.com/embed/mtO0R8TbwVQ', comentarios: true, nombre_video: 'conferencia-motivacional' },

    { etapa: 7, mensaje: <p className="contenedor_texto_conin">Tenemos un mensaje de bienvenida</p>, mensaje_inferior: <p></p>, url: '/auditorio', img: src_etapa_2, youtube: 'https://www.youtube.com/embed/mtO0R8TbwVQ', comentarios: false, nombre_video: 'conferencia-motivacional' },
];

let audio;

const Lobby = ({ youtube, comentarios, nombre_video }) => {

    const { usuario, datos } = useContext(Auth);
    const [etapa, setEtapa] = useState(0);
    const [mostrar_conin, setMostrarConin] = useState(true);
    const ref_carousel = useRef(null);
    const [final, setFinal] = useState(false);
    const [audios, setAudio] = useState('');
    const [estados_conin, setEstadosConin] = useState(0);
    const [play, setPlay] = useState(false);
    const [ultima_etapa_conin, setUltimaEtapaConin] = useState(false);
    const [etapa_final_conin, setEtapaFinalConin] = useState(-1);

    useEffect(() => {
        window.location.href = "#inicio";
        console.log(datos);
    }, [etapa, estados_conin]);


    useEffect(() => {
        //if (!usuario) {
          //  window.location.href = "/registro";
        //} else {
            app.obtener_objetos(nombre_evento + '/configuracion_flujo', true, (data) => {
                setEtapa(data[0].etapa);
                if (data[0].etapa === 4) {
                    app.obtener_objetos(nombre_evento + '/configuracion_conin_final', true, (dat) => {
                        setEtapaFinalConin(dat[0].etapa);
                    });
                }
            });
        //}
    }, [usuario]);

    /*useEffect(() => {
        if (etapa !== 5 || etapa !== 6) {
            setMostrarConin(true);
            if (datos) {
                switch (etapa) {
                    case 0: setAudio(datos.perfil != 'Operador' ? audio_invitado : audio_operador); break;
                    //case 1: setAudio('https://conin.antp.org.mx/audios/conferencia/audio/audio'); break;
                    //case 2: setAudio('https://conin.antp.org.mx/audios/premiacion/normal'); break;
                    //case 3: setAudio('https://conin.antp.org.mx/audios/expo/normal'); break;
                    //case 6: setAudio(audio_leonardo); break;
                    default: break;
                }
            }
            setEstadosConin(0);
        } else {

            setEstadosConin(0);
            setMostrarConin(false);
            setFinal(false);

        }

        console.log('etapa loby', etapa);

        console.log('conin loby', estados_conin);
    }, [datos, etapa]);*/

    useEffect(() => {
        switch (etapa_final_conin) {
            case 0: setAudio('https://conin.antp.org.mx/audios/despedida/audio/audio'); break;
            case 1: setAudio('https://conin.antp.org.mx/audios/despedida/audio/concierto'); break;
            case 4: setAudio('https://conin.antp.org.mx/audios/despedida/audio/despedida'); break;
            default: break;
        }
    }, [etapa_final_conin]);

    useEffect(() => {
        if (etapa === 4) { }
    }, [audios]);

    const Hablar = () => {
        setPlay(true);
        audio = new Audio(audios);
        audio.addEventListener('ended', () => { setEstadosConin(0); setPlay(false); setMostrarConin(false); if (etapa === 4) setUltimaEtapaConin(true); });
        audio.play();
    }

    const hablarFinal = () => {
        setPlay(true);
        audio = new Audio(audios);
        audio.addEventListener('ended', () => { setPlay(false); });
        audio.play();
    }

    useEffect(() => {
        if (etapa_final_conin <= 0) {
            if (play) {
                setEstadosConin(1);
            }
        } else {
            setEtapaFinalConin(etapa_final_conin + 1);
        }

    }, [play]);

    useEffect(() => {
        console.log('mostrar conin: ', mostrar_conin);
        if (mostrar_conin) {
            if (ref_carousel && ref_carousel.current) {
                ref_carousel.current.slickGoTo(0);
            }
        } else {
            if (ref_carousel && ref_carousel.current) {
                ref_carousel.current.slickGoTo(1);
            }
        }
    }, [mostrar_conin]);

    return (
        etapa === 3 ?
            <Redirect to="/expo" /> :
            etapa === 5 ?
                <>
                    {mensajes_conin.filter((mensaje) => mensaje.etapa === etapa).map((mensaje, index) => {
                        return (
                            <>
                                <Demo youtube={mensaje.youtube} comentarios={mensaje.comentarios} nombre_video={mensaje.nombre_video} datos={datos} />


                            </>
                        );
                    })}
                </>
                : etapa === 6 ?
                    <>
                        {mensajes_conin.filter((mensaje) => mensaje.etapa === etapa).map((mensaje, index) => {
                            return (
                                <>
                                    <Demo youtube={mensaje.youtube} comentarios={mensaje.comentarios} nombre_video={mensaje.nombre_video} datos={datos} />


                                </>
                            );
                        })}
                    </>
                    :
                    <Row id="inicio" >
                        <Element name="inicio" ></Element>
                        <Col span={24} style={{ position: 'relative' }}>

                            {final ?
                                <>

                                    {/*<Expo ocultar={true} />*/}

                                </>
                                :
                                <Slider {...settings} ref={ref_carousel}>

                                    <div>

                                        {/*<Expo ocultar={true} />*/}

                                        {estados_conin === 0 &&
                                            <img src={conin_inicial} className="conin" alt="" onClick={Hablar} />
                                        }
                                        {estados_conin === 1 &&
                                            <img src={conin_hablar} className="conin" alt="" />
                                        }
                                        {estados_conin === 2 &&
                                            <img src={conin_festejar} className="conin" alt="" />
                                        }
                                        {estados_conin === 3 &&
                                            <img src={conin_final} className="conin" alt="" />
                                        }
                                        {mostrar_conin &&
                                            <p className="saltar_conin" alt="" onClick={() => { setEstadosConin(0); setPlay(false); setMostrarConin(false); if (etapa === 4) setUltimaEtapaConin(true); audio.pause(); }}>SALTAR</p>
                                        }


                                    </div>
                                    <div>
                                        <div className={`${mostrar_conin ? 'ocultar_iframe' : 'mostrar_iframe'}`}>

                                            {mensajes_conin.filter((mensaje) => mensaje.etapa === etapa).map((mensaje, index) => {

                                                if (mensaje.etapa === 2) {
                                                    return (
                                                        <Redirect to="/felicitacion-conductor" />
                                                    );
                                                }

                                                return (
                                                    <>
                                                        <Demo youtube={mensaje.youtube} comentarios={mensaje.comentarios} nombre_video={mensaje.nombre_video} datos={datos} />


                                                    </>
                                                );
                                            })}

                                        </div>
                                    </div>
                                </Slider>
                            }
                        </Col>
                    </Row>
    );
}

export default Lobby;
