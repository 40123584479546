import * as firebase from "firebase";
import "firebase/auth";

// Your web app's Firebase configuration

var firebaseConfig = {
  apiKey: "AIzaSyCxw5AtAOktc5PWlFHUCAbmNZx3dtDUk2U",
  authDomain: "reformas-generales.firebaseapp.com",
  databaseURL: "https://reformas-generales.firebaseio.com",
  projectId: "reformas-generales",
  storageBucket: "reformas-generales.appspot.com",
  messagingSenderId: "84227974876",
  appId: "1:84227974876:web:7a5251402d09bff6853619",
  measurementId: "G-039JGR3T3M",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

class Firebase {
  app;
  constructor() {
    this.app = firebase;
    this.conexion = firebase.database();
  }

  obtener_webinars_usuario = (url, campo, valor, funct) => {
    console.log("ojal exista");
    this.conexion.ref(url).once("value", (objetos) => {
      console.log("existio");

      if (this.collectionToArr(objetos).length > 0) {
        console.log("esta");

        let objeto_buscado = this.collectionToArr(objetos).filter((objeto) => {
          console.log(objeto[campo]);
          if (objeto[campo] === valor) {
            return true;
          } else {
            return false;
          }
        });

        console.log(objeto_buscado);

        if (objeto_buscado.length > 0) {
          return funct(objeto_buscado[0]);
        } else {
          console.log("no enconbtro nada");
          return funct(null);
        }
      }
      console.log("no esta");
      return funct(null);
    });
  };

  obtener_objeto = (url, campo, valor, funct) => {
    this.conexion.ref(url).once("value", (objetos) => {
      if (this.collectionToArr(objetos).length > 0) {
        let objeto_buscado = this.collectionToArr(objetos).filter((objeto) => {
          if (objeto[campo] === valor) {
            return true;
          } else {
            return false;
          }
        });
        if (objeto_buscado.length === 1) {
          return funct(objeto_buscado[0]);
        } else {
          const datos_objeto = {};
          datos_objeto[campo] = valor;
          this.escribir(url, undefined, datos_objeto, funct);
        }
      } else {
        const datos_objeto = {};
        datos_objeto[campo] = valor;
        this.escribir(url, undefined, datos_objeto, funct);
      }
    });
  };

  verificar_objeto = (url, campo, valor, funct) => {
    this.conexion.ref(url).once("value", (objetos) => {
      if (this.collectionToArr(objetos).length > 0) {
        let objeto_buscado = this.collectionToArr(objetos).filter((objeto) => {
          if (objeto[campo] === valor) {
            return true;
          } else {
            return false;
          }
        });

        if (objeto_buscado.length > 0) {
          funct(true);
        } else {
          funct(false);
        }
      } else {
        funct(false);
      }
    });
  };

  escribir = (url, id, objeto, funct) => {
    //sendquestion || updateQuestion
    let key = "";
    if (id === undefined) {
      key = this.conexion.ref(url).push().key;
    } else {
      key = id;
    }

    console.log(objeto);

    this.conexion
      .ref(url)
      .child(key)
      .set(objeto, () => {
        objeto["key"] = key;
        return funct(objeto);
      });
  };

  obtener_objetos = (url, escuchar, funct) => {
    //questions

    if (escuchar) {
      this.conexion
        .ref(url)
        .orderByChild("num_pregunta")
        .on("value", (objetos) => {
          return funct(this.collectionToArr(objetos));
        });
    } else {
      this.conexion
        .ref(url)
        .orderByChild("num_pregunta")
        .once("value", (objetos) => {
          return funct(this.collectionToArr(objetos));
        });
    }
  };

  obtener_parametro = (url, escuchar, funct) => {
    if (escuchar) {
      this.conexion.ref(url).on("value", (parametro) => {
        return funct(parametro);
      });
    } else {
      this.conexion.ref(url).once("value", (parametro) => {
        return funct(parametro);
      });
    }
  };

  collectionToArr = (collection) => {
    var rows = [];
    if (collection && collection.val()) {
      collection.forEach((obj) => {
        let item = obj.val();
        item.key = obj.key;
        rows.push(item);
      });
    }
    return rows;
  };
}

export default Firebase;
