import React from "react";
import "../../assets/css/admin.css";
import Firebase from "../../utils/firebaseConfig";
import { Button, Row, Col, Select, notification, Input } from "antd";
import logo_thethree from "../../assets/images/admin/logo-thethree.png";
import logo_impac from "../../assets/images/admin/logo-impactotal.png";

const nombre_webinar = "premio-2024";

const mensaje = (tipo, titulo, texto) => {
  notification[tipo]({
    message: titulo,
    description: texto,
    duration: 10,
  });
};

const firebase = new Firebase();

const { Option } = Select;

class Admin extends React.Component {
  state = {
    questions: [],
    personas: [],
    preguntas: [],
    etapa: 0,
    activar_descarga: 0,
  };

  constructor(props) {
    super(props);
    this.ref_input = React.createRef(null);
    this.ref_titulo = React.createRef(null);
  }

  componentDidMount() {
    firebase.obtener_objetos(
      nombre_webinar + "/preguntas-publico/chat",
      true,
      (data) => {
        this.setState({
          questions: data,
        });
      }
    );
    firebase.obtener_objetos(
      nombre_webinar + "/usuarios",
      true,
      (data) => {
        this.setState({
          personas: data,
        });
      }
    );

    firebase.obtener_objetos(
      nombre_webinar + "/configuracion_flujo",
      false,
      (data) => {
        console.log("la etapa: ", data);
        this.setState({
          etapa: data[0].etapa,
        });
      }
    );
    

    /*firebase.obtener_objetos(nombre_webinar+'/configuracion_flujo',false,(data)=>{
      console.log('la etapa: ',data);
      this.setState({
        etapa: data[0].etapa,
      })
  });*/

    firebase.obtener_objetos(
      nombre_webinar + "/activar_descarga",
      false,
      (data) => {
        this.setState({
          activar_descarga: data[0].activar,
        });
      }
    );

    firebase.obtener_objetos(
      nombre_webinar + "/preguntas-vivo",
      false,
      (data) => {
        this.setState({
          preguntas: data,
        });
      }
    );
  }

  aceptQuestion = (item) => {
    item["status"] = "aceptada";

    firebase.escribir(
      nombre_webinar + "/preguntas-publico/chat",
      item.key,
      item,
      () => {}
    );
  };

  rejectQuestion = (item) => {
    item["status"] = "rechazada";

    firebase.escribir(
      nombre_webinar + "/preguntas-publico/chat",
      item.key,
      item,
      () => {}
    );
  };

  answerQuestion = (item) => {
    item["status"] = "contestada";

    firebase.escribir(
      nombre_webinar + "/preguntas-publico/chat",
      item.key,
      item,
      () => {}
    );
  };

  retrieveQuestion = (item) => {
    item["status"] = "pendiente";
    firebase.escribir(
      nombre_webinar + "/preguntas-publico/chat",
      item.key,
      item,
      () => {}
    );
  };

  cambiarPreguntaPopUp = (e) => {
    firebase.escribir(
      nombre_webinar + "/configuracion",
      "1234567890",
      { pregunta_popup: e },
      () => {
        mensaje("success", "Se actualizo la pregunta", "");
      }
    );
  };

  cambiarEtapa = (e) => {
    firebase.escribir(
      nombre_webinar + "/configuracion_flujo",
      "1234567890",
      { etapa: e },
      () => {
        mensaje("success", "Se actualizo la etapa", "");
      }
    );
  };

  cambiarEtapaConin = (e) => {
    firebase.escribir(
      nombre_webinar + "/configuracion_conin_final",
      "1234567890",
      { etapa: e },
      () => {
        mensaje("success", "Se actualizo la etapa conin", "");
      }
    );
  };

  activarDiplomas = (e) => {
    firebase.escribir(
      nombre_webinar + "/activar_descarga",
      "1234567890",
      { activar: e },
      () => {
        mensaje("success", "Se actualizo la etapa", "");
      }
    );
  };

  enviarMensaje = (e) => {
    console.log(e);
    console.log(this.ref_input.current.state.value);

    firebase.escribir(
      nombre_webinar + "/configuracion_mensajes",
      "1234567890",
      {
        titulo: this.ref_titulo.current.state.value,
        mensaje: this.ref_input.current.state.value,
        mensaje_activar: e,
      },
      () => {
        mensaje("success", "Se actualizo el mensaje", "");
      }
    );
  };

  render() {
    let preguntas = this.state.preguntas.map((pregunta) => {
      return <Option value={pregunta.num_pregunta}>{pregunta.pregunta}</Option>;
    });

    let { questions } = this.state;
    let { personas } = this.state;

    return (
      <>
        <Row>
          <Col span={20} offset={2}>
            <br />
            <p className="admin_titulo">Flujo</p>
            <Select
              defaultValue={this.state.etapa}
              style={{ width: "100%" }}
              onChange={this.cambiarEtapa}
            >
              <Option value={0}>Lobby - Iniciar</Option>
              <Option value={3}>0 Expo</Option>
              <Option value={1}>1 Inicio</Option>
              <Option value={2}>2 Premiación</Option>
              <Option value={6}>3 auditorio</Option>
              <Option value={4}>4 Felicitacion</Option>
              <Option value={7}>Testimonios</Option>
              <Option value={5}>Terminar</Option>
            </Select>

            <br />
            <p className="admin_titulo">Conin</p>
            <Select
              defaultValue={0}
              style={{ width: "100%" }}
              onChange={this.cambiarEtapaConin}
            >
              <Option value={0}>Desactivar</Option>
              
              <Option value={2}>Premiacion</Option>
              <Option value={3}>Despedida</Option>
            </Select>

            <br />
            <p className="admin_titulo">Diplomas</p>
            <Select
              defaultValue={this.state.activar_descarga}
              style={{ width: "100%" }}
              onChange={this.activarDiplomas}
            >
              <Option value={0}>Desactivar</Option>
              <Option value={1}>Activar</Option>
            </Select>

            <br />
            <p className="admin_titulo">Mensajes</p>
            <span>Titulo</span>
            <br />
            <Input ref={this.ref_titulo} width="100%" />
            <br />
            <span>Mensaje</span>
            <br />
            <Input ref={this.ref_input} width="100%" />
            <Select
              defaultValue={0}
              style={{ width: "100%" }}
              onChange={this.enviarMensaje}
            >
              <Option value={0}>Desactivar</Option>
              <Option value={1}>Activar</Option>
            </Select>
          </Col>
        </Row>

        <Row style={{ display: "none" }}>
          <Col span={20} offset={2}>
            <br />
            <p className="admin_titulo">ENCUESTA SATISFACCIÓN</p>
            <Select
              defaultValue={0}
              style={{ width: "100%" }}
              onChange={this.cambiarPreguntaPopUp}
            >
              <Option value={0}>Desactivar</Option>
              {preguntas}
            </Select>
          </Col>
        </Row>
        <Row>
          <Col span={7} offset={1}>
            <p className="admin_titulo">PREGUNTAS</p>
          </Col>
          <Col span={24}>
          </Col>
          <Col span={7} offset={1}>
            <p className="admin_titulo">TOTAL {(personas.length)/2}</p>
          </Col>
          <Col span={2} offset={11}>
            <img src={logo_thethree} style={{ width: "100px" }} />
          </Col>
          <Col span={2} offset={0}>
            <img src={logo_impac} style={{ width: "100px" }} />
          </Col>
          <Col span={6} className="admin_preguntas_contendor">
            <Row>
              <Col span={24} className="admin_encabezado">
                <p className="admin_texto_encabezado">PENDIENTES</p>
              </Col>
              <Col span={24}>
                {questions.map((item, idx) => {
                  return item.status === "pendiente" ? (
                    <div className="questionContainer" key={item.key}>
                      <p className="question">
                        <span className="nombre_pregunta">{item.nombre}</span>
                        <br /> {item.organizacion}
                      </p>
                      <p className="question">
                        <strong>{item.pregunta}</strong>
                      </p>
                      <p className="botones">
                        <button
                          className="acceptButton"
                          onClick={() => {
                            this.aceptQuestion(item);
                          }}
                        >
                          ACEPTAR
                        </button>
                        <button
                          className="rejectButton"
                          onClick={() => {
                            this.rejectQuestion(item);
                          }}
                        >
                          RECHAZAR
                        </button>
                      </p>
                    </div>
                  ) : null;
                })}
              </Col>
            </Row>
          </Col>
          <Col span={6} className="admin_preguntas_contendor">
            <Row>
              <Col span={24} className="admin_encabezado">
                <p className="admin_texto_encabezado">ACEPTADAS</p>
              </Col>
              <Col span={24}>
                {questions.map((item, idx) => {
                  return item.status === "aceptada" ? (
                    <div className="questionContainer" key={item.key}>
                      <p className="question">
                        <span className="nombre_pregunta">{item.nombre}</span>
                        <br /> {item.organizacion}
                      </p>
                      <p className="question">
                        <strong>{item.pregunta}</strong>
                      </p>
                      <p className="botones">
                        {/* <button
                          className="answerButton"
                          onClick={() => {
                            this.answerQuestion(item);
                          }}
                        >
                          CONTESTADA
                        </button> */}
                        <button
                          className="rejectButton"
                          onClick={() => {
                            this.rejectQuestion(item);
                          }}
                        >
                          RECHAZAR
                        </button>
                      </p>
                    </div>
                  ) : null;
                })}
              </Col>
            </Row>
          </Col>
          <Col span={6} className="admin_preguntas_contendor">
            <Row>
              <Col span={24} className="admin_encabezado">
                <p className="admin_texto_encabezado">RECHAZADAS</p>
              </Col>
              <Col span={24}>
                {questions.map((item, idx) => {
                  return item.status === "rechazada" ? (
                    <div className="questionContainer" key={item.key}>
                      <p className="question">
                        <span className="nombre_pregunta">{item.nombre}</span>{" "}
                        <br /> {item.organizacion}
                      </p>
                      <p className="question">
                        <strong>{item.pregunta}</strong>
                      </p>
                      <p className="botones">
                        <button
                          className="retrieveButton"
                          onClick={() => {
                            this.retrieveQuestion(item);
                          }}
                        >
                          RECUPERAR
                        </button>
                      </p>
                    </div>
                  ) : null;
                })}
              </Col>
            </Row>
          </Col>
          <Col span={6} className="admin_preguntas_contendor">
            <Row>
              <Col span={24} className="admin_encabezado">
                <p className="admin_texto_encabezado">CONTESTADAS</p>
              </Col>
              <Col span={24}>
                {questions.map((item, idx) => {
                  return item.status === "contestada" ? (
                    <div className="questionContainer" key={item.key}>
                      <p className="question">
                        <span className="nombre_pregunta">{item.nombre}</span>{" "}
                        <br /> {item.organizacion}
                      </p>
                      <p className="question">
                        <strong>{item.pregunta}</strong>
                      </p>
                    </div>
                  ) : null;
                })}
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

export default Admin;
